import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../../app.service';
import { DialogChargeTypeLabels, IDialogChargeTypeLabels } from './dialog-charge-type-labels';
import { DialogStandardFocus } from './../../../enums/dialog-stantdard-focus';

const CHARGE_TYPE = 'typeOfCharge';
const DEFAULT_SELECTOR_VALUE = 'unit';
const VALID_DICKA_TENANTS = ['5f2c3bcefb19db512bc2663f', '5f4553fe465e0535da27b45c'];

@Component({
  selector: 'app-dialog-standard',
  templateUrl: './dialog-charge-type.component.html',
  styleUrls: ['./dialog-charge-type.component.scss', '../../../app.component.scss']
})
export class DialogChargeTypeComponent {
  public chargeTypeForm: UntypedFormGroup;
  public isDicka: boolean;
  public isSaveDisabled: boolean;
  public labels: IDialogChargeTypeLabels;
  public selected: string;
  public tenantId: string;

  constructor(public dialogRef: MatDialogRef<DialogChargeTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly builder: UntypedFormBuilder,
    private appService: AppService) {
      this.tenantId = this.appService.getShipperOid();
      this.isDicka = VALID_DICKA_TENANTS.includes(this.tenantId);
      this.labels = DialogChargeTypeLabels;
      if (data.shipment.typeOfCharge) {
        this.selected = data.shipment.typeOfCharge;
      } else {
        this.selected = this.isDicka ? null : DEFAULT_SELECTOR_VALUE;
      }
      this.initChargeTypeForm();
      this.onChangeDetected();
    }

  /**
   * @description Action for click on 'Cancel' button
   */
  public onClickCancel(): void {
    this.dialogRef.close(DialogStandardFocus.CANCEL);
  }

    /**
   * @description Action for click on 'Save' button
   */
  public onClickSave(): void {
    const res = {
      selectResult: this.chargeTypeForm.get(CHARGE_TYPE).value,
      dialogResult: DialogStandardFocus.CONFIRM
    };
    this.dialogRef.close(res);
  }

    /**
   * @description Action for click on 'Close' button
   */
  public onClickClose(): void {
    this.dialogRef.close(DialogStandardFocus.CLOSED);
  }

    /**
   * @description Initializes form
   */
  public initChargeTypeForm (): void {
    this.chargeTypeForm = this.builder.group({
      typeOfCharge: new UntypedFormControl(this.selected, [Validators.required])
    });
  }

  /**
   * @description Detects if there is changes comparing db data with mat-select option data, then enables/disables the 'Save' button
   */
  public onChangeDetected () {
    this.isSaveDisabled = this.chargeTypeForm.get(CHARGE_TYPE).value === this.data.shipment.typeOfCharge || this.chargeTypeForm.invalid;
  }
}
