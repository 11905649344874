import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppConstants } from '../../../constants/app-constants.constants';
import { AppService } from '../../../app.service';
import { Carrier } from '../../../interfaces/carrier';
import { CarrierProvider } from '../../../providers/carrier/carrier.provider.service';
import { ConfigurationProvider } from '../../../providers/configuration/configuration.provider.service';
import { DragAndDropFile, IShipmentSpecialRequirement, ShipperConfiguration, Transport, TransportByShipper, TransportMainShipperData } from '../../../interfaces';
import { Driver } from '../../../interfaces/driver';
import { DriverProvider } from '../../../providers/driver/driver.provider.service';
import { environment } from '../../../../environments/environment';
import { FormsService } from '../../../services/utils/forms.service';
import { IDialogTransportAssignmentLabels } from '../../../interfaces/labels/dialog-labels.interface';
import { ILanguageLabels } from '../../../interfaces/labels/language-labels.interface';
import { FileConversorService } from '../../../../app/services/file-conversor';
import { LanguageChangeEventService } from '../../../services/translate/language-change-event.service';
import { LanguageConstants } from '../../../constants/language.constants';
import { LanguageTranslateService } from '../../../services/translate/language-translate.service';
import { Profiles } from '../../../enums/profile';
import { SHIPMENT_SPECIAL_REQUIREMENTS } from '../../../constants/shipment.constants';
import { ShipmentProvider } from '../../../providers/shipments/shipment-provider.service';
import { Supplier } from '../../../interfaces/supplier';
import { SupplierService } from '../../../providers/supplier/supplier.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';
import { TrailerModel } from '../../../interfaces/trailers';
import { TrailerProvider } from '../../../providers/trailers/trailers.provider';
import { VariableTypes } from '../../../enums/variable-types';
import { VehiculeTypeProvider } from '../../../providers/vehiculeType/vehiculeType.provider.service';

import { some as _some, find as _find, isEmpty as _isEmpty } from 'lodash';
import { Subscription } from 'rxjs';

const BLOB_STORAGE_URL = environment.baseStorageUrl + environment.mainContainer;
const CARRIER_PARAM = 'carrier';
const CLOSED = 'Closed';
const DRIVER_PARAM = 'driver';
const EMPTY_STRING = '';
const HELPER_PARAM = 'helper';
const KEY_ADMISSIONCEDIS_DATE = 'admissionCedisDate';
const KEY_ADMISSIONCEDIS_HOUR = 'admissionCedisHour';
const KEY_HELPER_BY_SHIPPER = 'isHelperAssignedByShipper';
const KEY_LOADEND_DATE = 'loadEndDate';
const KEY_LOADEND_HOUR = 'loadEndHour';
const KEY_LOADSTART_DATE = 'loadStartDate';
const KEY_LOADSTART_HOUR = 'loadStartHour';
const KEY_TRAILER_BY_SHIPPER = 'isTrailerAssignedByShipper';
const KEY_TRANSPORT_BY_SHIPPER = 'isTransportAssignedByShipper';
const KEY_TRANSPORT_DATE = 'onRouteDateTime';
const KEY_TRANSPORT_HOUR = 'onRouteDateHour';
const LEASEHOLDER_PARAM = 'leaseholder';
const MIN_LENGTH = 3;
const NAME_ATTRIBUTE = 'nombre';
const OTHER_REQUIREMENTS_PARAM = 'otherRequirements';
const PLATES_ATTRIBUTE = 'placas';
const PLATES_PARAM = 'plates';
const SPACE_STRING = ' ';
const TRAILER_PARAM = 'trailer';
const TWO = 2;
const TYPEUNDEFINED = 'SIN TIPO';
const VEHICLE_PARAM = 'vehicle';

@Component({
  selector: 'app-dialog-transport-assignment',
  templateUrl: './dialog-transport-assignment.component.html',
  styleUrls: ['./dialog-transport-assignment.component.scss', '../../../app.component.scss']
})
export class DialogTransportAssignmentComponent implements OnInit {
  public additionalsSuppliers: Array<Supplier>;
  public accessDateForm: UntypedFormGroup;
  public admissionCedisDateAndTime: string;
  public assignByShipper: boolean;
  public carrierLeaseholderList: Array<Carrier>;
  public carrierList: Array<Carrier>;
  public carrierListBody: any;
  public currentDay: Date;
  public dialogTransportAssignmentLabels: IDialogTransportAssignmentLabels;
  public driverList: any;
  public extendedVehicleList: any;
  public filesAddedByButton: FileList;
  public filesFormatsAllowed: Array<string>;
  public helper: string;
  public identificationFilesConverted: Array<DragAndDropFile>;
  public imageFilesAllowed: Array<string>;
  public isAccessDateFormSectionDisplayed: boolean;
  public isAssignShipperCheckBoxDisplayed: boolean;
  public isCarrierProfile: boolean;
  public isCarrierSelectDisabled: boolean;
  public isConsignmentNoteConfigActive: boolean;
  public isDateAndTimeSectionDisplayed: boolean;
  public isLeaseholder: boolean;
  public isLeaseholderCheckBoxDisplayed: boolean;
  public isLimitFilesReached: boolean;
  public isShipperProfile: boolean;
  public isSpecialRequirementsSectionDisplayed: boolean;
  public isTrailerInputDisplayed: boolean;
  public isVehicleSelectDisabled: boolean;
  public languageLabels: ILanguageLabels;
  public languageSuscription: Subscription;
  public licenseFormData: Array<string>;
  public loadEndDateAndTime: string;
  public loadStartDateAndTime: string;
  public onRouteDateAndTime: string;
  public operationTypes: Array<string>;
  public platesList: Array<any>;
  public requirements: Array<string>;
  public selectedTrailer: Array<TrailerModel>;
  public shipmentTransport: Transport;
  public shipperOId: string;
  public shipperConfiguration: ShipperConfiguration;
  public shouldShowSuppliersNotFoundMessage: boolean;
  public specialRequirements: Array<IShipmentSpecialRequirement>;
  public suppliersSelected: Array<Supplier>;
  public trailerList: Array<TrailerModel>;
  public transportAssignmentForm: UntypedFormGroup;
  public userProfile: string;
  public vehicleList: Array<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public injectData: any,
    private appService: AppService,
    private configurationProvider: ConfigurationProvider,
    private _languageChangeEventService: LanguageChangeEventService,
    private _languageTranslateService: LanguageTranslateService,
    private fileConversorService: FileConversorService,
    private formBuilder: UntypedFormBuilder,
    private formsService: FormsService,
    public carrierProvider: CarrierProvider,
    public dialogRef: MatDialogRef<DialogTransportAssignmentComponent>,
    public driverProvider: DriverProvider,
    public shipmentProvider: ShipmentProvider,
    private supplierProvider: SupplierService,
    public toast: ToastrAlertsService,
    public trailerProvider: TrailerProvider,
    public vehicleProvider: VehiculeTypeProvider
  ) {
    this.setLanguage();
    this.operationTypes = [];
    this.filesFormatsAllowed = AppConstants.IMAGES_FILES_FORMAT_ALLOWED;
    this.shouldShowSuppliersNotFoundMessage = false;
  }

  /**
   * @description Event fires when init component.
   */
  public async ngOnInit(): Promise<void> {
    this.specialRequirements = SHIPMENT_SPECIAL_REQUIREMENTS;
    this.initTransportAssignmentForm();
    this.suppliersSelected = this.injectData?.additionalsSuppliers ?? [];
    this.currentDay = new Date();
    this.trailerList = [];
    this.selectedTrailer = [];
    this.shipperConfiguration = await this.getShipperSettings();
    this.isConsignmentNoteConfigActive = this.shipperConfiguration?.billingConfig?.consignmentNoteConfig?.isActive ?? false;
    this.userProfile = await this.appService.getProfile();
    this.displaySectionsByProfile(this.userProfile);
    this.handleAssignByShipperFlag();
    if (this.injectData.isCustomerPickup) {
      await this.checkFilesSavedInShipment();
      const pickupEvent = { checked: true, source: null };
      this.assignByShipperValidateCheck(pickupEvent);
    }
    this.subscribeLanguageChangeEvent();
    await this.getLabels();
    this.initCarriers();
    if (this.injectData.leaseholder) { this.assignLeaseholder(true); }
    this.setFieldsSettings();
    await this.getShipperSuppliers();
  }

  /**
   * @description Retrieves active suppliers from shipper.
   */
  private async getShipperSuppliers(): Promise<void> {
    try {
      this.shipperOId = this.appService.getShipperOid();
      const userOId = this.appService.getUserOid();
      const response = await this.supplierProvider.getShipperSuppliers(this.shipperOId, userOId, true);
      this.additionalsSuppliers = response.item;
    } catch (error) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.errorRetrievingSuppliersMsg);
    }
  }

  /**
   * @description Reacts to the SCF language change event setting the configuration in the interface.
   * @return {void}
   */
  public setLanguage(languageKey?: string): void {
    this._languageTranslateService.setLanguage(languageKey);
  }

  /**
   * @description Reacts to the event created when the language is changed by the SCF,
   * setting the configuration in the interface.
   * @return {void}
   */
  public subscribeLanguageChangeEvent(): void {
    this.languageSuscription = this._languageChangeEventService._languageEmitter.subscribe(
      async (key: string) => {
        this.setLanguage(key);
        await this.getLabels();
      },
      () => {
        this.toast.errorAlert(this.languageLabels.errorChangingLanguage);
      });
  }

  /**
   * @description Gets the necessary tags from the JSON files to use throughout the component
   * @return {void}
   */
  public async getLabels(): Promise<void> {
    await this.getLanguageLabels();
    await this.getDialogTransportAssignmentLabels ();
  }

  /**
   * @description Checks if identification files selected for shipment are saved in Blob storage to retrieve the urls of files
   * or if are just selected the files to display it in drag and drop
   * @param {Array<DragAndDropFile | string>} identificationFiles Array of url file's (for files saved) to retrieve or files objects
   * (for files selected but no saved in shipment yet)
   */
  public async convertAndSetImages(identificationFiles: Array<DragAndDropFile | string>): Promise<void> {
    const filesToShow = [];
    for (const file of identificationFiles) {
      filesToShow.push(BLOB_STORAGE_URL + file);
    }
    const response = await this.fileConversorService.fileConversor(filesToShow, false);
    this.identificationFilesConverted = response;
  }

  /**
   * @description Gets Dialog Transport Assignment Labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getDialogTransportAssignmentLabels (): Promise<void> {
    this.dialogTransportAssignmentLabels = await this._languageTranslateService
      .getLanguageLabels(LanguageConstants.DIALOG_TRANSPORT_ASSIGNMENT_LABELS)
      .catch(() => {
        this.toast.errorAlert(this.languageLabels.errorGettingLabels);
      });
  }

  /**
   * @description Gets Language labels from translate JSON files.
   * @return {Promise<void>}
   */
  public async getLanguageLabels(): Promise<void> {
    this.languageLabels = await this._languageTranslateService
    .getLanguageLabels(LanguageConstants.LANGUAGE_LABELS)
    .catch(() => {
      this.toast.errorAlert(this.languageLabels.errorGettingLabels);
    });
  }

  /**
   * @description Get translate value for an specific shipment special requirement.
   *
   * @param {string} value Shipment special requirement to be translated.
   * @return {string} Shipment special requirement translated.
   */
  public getTranslatedShipmentSpecialRequirementLabel(value: string): string {
    let labelTranslated: string;
    if (value && this.dialogTransportAssignmentLabels) {
      this.specialRequirements.forEach(element => {
        if (element.value === value) {
          if (this.dialogTransportAssignmentLabels.specialRequirements[element.label]) {
            labelTranslated = this.dialogTransportAssignmentLabels.specialRequirements[element.label];
          }
        }
      });
    }

    return labelTranslated ?? value;
  }

  get requirementsFormArray() {
    return this.transportAssignmentForm.controls.specialRequirementsModal as UntypedFormArray;
  }

  /**
   * @description Compare carriers for select field
   * @param {Carrier} carrier carrier from list of carriers
   * @param {Carrier} incomingCarrier carrier from edit object
   * @returns {boolean} Whether the carrier is in the list or not
   */
  public compareCarriers(carrier: Carrier, incomingCarrier: Carrier): boolean {
    if (incomingCarrier) {
      return carrier.nombre === incomingCarrier.nombre;
    }
  }

  /**
   * @description Compare trailers for select field
   * @param {TrailerModel} trailer trailer from list of trailers
   * @param {TrailerModel} incomingTrailer trailer from edit object
   * @return {boolean} Whether the trailer is in the list or not
   */
  public compareTrailers(trailer: TrailerModel, incomingTrailer: TrailerModel): boolean {
    if (incomingTrailer) {
      return trailer.plates === incomingTrailer.plates;
    }
  }

  /**
   * @description Compares supliers from already selected versus available suppliers.
   * @param {Supplier} supplier - Supplier from list of available suppliers.
   * @param {Supplier} supplierAssigned - Supplier assigned to shipment.
   * @return {boolean} True if supplier OId matches witn any from available suppliers. Otherwise false.
   */
  public compareSuppliers(supplier: Supplier, supplierAssigned: Supplier): boolean {
    if (supplierAssigned) {
      return supplier._id === supplierAssigned._id;
    }
  }

  /**
   * @description Compare vehicles for select field
   * @param {string} vehicle vehicle from list of vehicles
   * @param {string} incomingVehicle vehicle from edit object
   * @returns {boolean} Whether the vehicle is in the list or not
   */
  public compareVehicles(vehicle: string, incomingVehicle: string): boolean {
    return vehicle === incomingVehicle;
  }

  /**
   * @description Compare drivers for select field
   * @param {Driver} driver driver from list of drivers
   * @param {Driver} incomingDriver driver from edit object
   * @returns {boolean} Whether the driver is in the list or not
   */
  public compareDrivers(driver: Driver, incomingDriver: Driver): boolean {
    if (incomingDriver) {
      return (driver.nombre + SPACE_STRING + driver.appaterno + SPACE_STRING + driver.apmaterno)
        === (incomingDriver.nombre + SPACE_STRING + incomingDriver.appaterno + SPACE_STRING + incomingDriver.apmaterno);
    }
  }

  /**
   * @description Compare plates for select field
   * @param {*} plate plate from list of plates
   * @param {*} incomingPlate plate from edit object
   * @returns {boolean} Whether the plates is in the list or not
   */
  public comparePlates(plate: any, incomingPlate: any): boolean {
    if (incomingPlate) {
      return plate.placas === incomingPlate.placas;
    }
  }

  /**
   * @description Evaluate change in checkboxes
   * @param {string} reqName requirement name
   * @param {MatCheckboxChange} $event event from check the checkobox
   */
  public checkboxChange(reqName: string, $event: MatCheckboxChange): void {
    if ($event.checked) {
      this.requirements.push(reqName);
    } else {
      const filtered = this.requirements.filter(req => {
        return req !== reqName;
      });
      this.requirements = filtered;
    }
  }

  /**
   * @description Handles cancel event of dialog
   */
  public onCancel(): void {
    this.dialogRef.close(CLOSED);
  }

  /**
   * @description Handles save event of dialog
   */
  public async onSave(): Promise<void> {
    try {
      if ((this.accessDateForm.value.admissionCedisDate || this.accessDateForm.value.loadStartDate ||
        this.accessDateForm.value.loadEndDate) && !this.isCarrierProfile) {
        this.checkDate();
      }
      const filesToLoad = this.identificationFilesConverted;
      let driver = this.transportAssignmentForm.get(DRIVER_PARAM).value ? this.transportAssignmentForm.get(DRIVER_PARAM).value : null;
      let plates = this.transportAssignmentForm.get(PLATES_PARAM).value;
      const transportCarrier = this.injectData.isCustomerPickup ? this.transportAssignmentForm.get(CARRIER_PARAM).value.trim() :
        this.transportAssignmentForm.get(CARRIER_PARAM).value.nombre;
      const carrierOid = this.transportAssignmentForm.get(CARRIER_PARAM).value._id;
      const isLeaseholder = this.transportAssignmentForm.get(LEASEHOLDER_PARAM).value;
      const leaseholder = isLeaseholder ? isLeaseholder.nombre : null;
      const additionalSuppliers = this.checkAndSetAdditionalSuppliers();

      if (driver && !this.injectData.isCourierShipment && !this.injectData.isCustomerPickup) {
        driver.fullName = driver.nombre + SPACE_STRING + driver.appaterno + SPACE_STRING + driver.apmaterno;
      } else if (driver && this.injectData.isCourierShipment || (driver && this.injectData.isCustomerPickup)) {
        driver = { fullName: driver };
        this.assignByShipper = true;
      }

      if (plates && !this.injectData.isCourierShipment && !this.injectData.isCustomerPickup) {
        plates = this.transportAssignmentForm.get(PLATES_PARAM).value.placas;
      }
      const objectToUpdate = {
        specialRequirements: {
          requirements: this.requirements,
          otherRequirements: this.transportAssignmentForm.get(OTHER_REQUIREMENTS_PARAM).value
            ? this.transportAssignmentForm.get(OTHER_REQUIREMENTS_PARAM).value : EMPTY_STRING
        },
        transport: {
          trailer: this.getTrailerInfo(),
          carrierOid: carrierOid,
          carrierIdentifier:  this.transportAssignmentForm.get(CARRIER_PARAM).value.identifier,
          transportCarrier: transportCarrier ? transportCarrier : EMPTY_STRING,
          leaseholder: leaseholder ? leaseholder : null,
          identificationFiles: (this.injectData.isCustomerPickup && filesToLoad) ? filesToLoad : null,
          vehicle: this.transportAssignmentForm.get(VEHICLE_PARAM).value.trim(),
          plates: this.assignByShipper && plates ? plates.trim() : EMPTY_STRING,
          operationTypes: this.operationTypes,
          driver: this.assignByShipper && driver ? driver.fullName.trim() : EMPTY_STRING,
          helper: this.assignByShipper ? this.transportAssignmentForm.get(HELPER_PARAM).value : EMPTY_STRING,
          mobileDriver: this.assignByShipper && this.transportAssignmentForm.get(DRIVER_PARAM).value
            ? this.transportAssignmentForm.get(DRIVER_PARAM).value.movil : EMPTY_STRING,
          assignByCarrier: !this.assignByShipper,
          admissionCedisDate: this.accessDateForm.value.admissionCedisDate,
          loadStartDate: this.accessDateForm.value.loadStartDate,
          loadEndDate: this.accessDateForm.value.loadEndDate,
          onRouteDateTime: this.accessDateForm.value.onRouteDateTime,
          lastTransportUpdate: null,
          ...(additionalSuppliers && { additionalsSuppliers: additionalSuppliers })
        }
      };
      const auxTransportData: TransportMainShipperData = {
        driver: objectToUpdate.transport.driver,
        helper: objectToUpdate.transport.helper,
        plates: objectToUpdate.transport.plates,
        trailer: objectToUpdate.transport.trailer
      };
      const transportByShipper = await this.buildTransportByShipper(auxTransportData);
      objectToUpdate.transport['driverId'] = driver ? (driver?._id || AppConstants.EMPTY_STRING) : AppConstants.EMPTY_STRING;
      objectToUpdate.transport[KEY_HELPER_BY_SHIPPER] = transportByShipper.isHelperAssignedByShipper;
      objectToUpdate.transport[KEY_TRAILER_BY_SHIPPER] = transportByShipper.isTrailerAssignedByShipper;
      objectToUpdate.transport[KEY_TRANSPORT_BY_SHIPPER] = transportByShipper.isTransportAssignedByShipper;
      if (this.isCarrierProfile) {
        if (await this.isAssignedTransport(auxTransportData)) {
          this.toast.warningAlert(this.dialogTransportAssignmentLabels.transportAlreadyAssigned);
        } else {
          this.dialogRef.close(objectToUpdate);
        }
      } else {
        this.dialogRef.close(objectToUpdate);
      }
    } catch (error) {
    }
  }

  /**
   * @description Checks if exists suppliers selecteds to map only neccesary info from them.
   * @returns {Array<Supplier>} An array with only properties to save from suppliers in shipment info.
   */
  public checkAndSetAdditionalSuppliers(): Array<Supplier> {
    const suppliers = this.transportAssignmentForm.get('additionalsSuppliers').getRawValue();

    if (suppliers) {
      const suppliersSelected = suppliers.map((supplier: Supplier) => {
        return {
          _id: supplier._id,
          identifier: supplier.identifier,
          name: supplier.name,
        }
      })

      return suppliersSelected
    }

    return null
  }

  /**
   * @description returns trailer Info depending if shipment trip type is Customer pickup to create trailer object with available info.
   * OtherWise returns all info of trailer from selects
   * @returns {Array<TrailerModel>} Object with trailer info of shipment
   */
  public getTrailerInfo(): Array<TrailerModel> {
    let trailerInfo = null;
    if (!this.injectData.isCustomerPickup) {
      trailerInfo = this.transportAssignmentForm.get(TRAILER_PARAM).value;
    } else {
      trailerInfo = {
        trailerType: {
          code: AppConstants.EMPTY_STRING,
          description: AppConstants.EMPTY_STRING
        },
        carrier: {
          nombre: this.transportAssignmentForm.get(CARRIER_PARAM).value,
        },
        availability: AppConstants.EMPTY_STRING,
        creationDate: AppConstants.EMPTY_STRING,
        isActive: true,
        plates: this.transportAssignmentForm.get(TRAILER_PARAM).value,
        trailerSpecifics: null,
        unitNumber: null,
      };
    }

    return trailerInfo;
  }

  /**
   * @description Handles close event of dialog
   */
  public onClose(): void {
    this.dialogRef.close(CLOSED);
  }

  /**
   * @description Open file's explorer to select the files to upload
   */
  public openFilesBrowser(): void {
    document.getElementById('filesInput').click();
  }

  /**
   * @description Set files to send to drag and drop component
   */
  public addFiles(): void {
    this.filesAddedByButton = undefined;
    const filesByButton = document.getElementById('filesInput');
    this.filesAddedByButton = filesByButton['files'];
    this.checkFilesQuantitySelected();
  }

  /**
   * @description Set the dropped files in Drag-and-Drop area to the file field in identificationFiles input form
   * @param {Array<DragAndDropFile>} filesInDropZone Array with all the files data emitted from Drag-and-Drop component
   */
  public filesDropped(filesInDropZone: Array<DragAndDropFile>): void {
    const licenseFiles = this.transportAssignmentForm.value.identificationFiles;
    this.transportAssignmentForm.patchValue({ identificationFiles : filesInDropZone });
    this.checkFilesQuantitySelected();
  }

  /**
   * @description Checks the quantity of files selected to change the flag if the limit of files has been reached
   */
  private checkFilesQuantitySelected(): void {
    const identificationFiles = this.transportAssignmentForm.controls['identificationFiles'].value;
    const maxFilesQuantity = 3;
    this.isLimitFilesReached = identificationFiles && identificationFiles.length >= maxFilesQuantity;
  }

  /**
   * @description Handles the event of selection of carrier
   */
  public async onCarrierSelected(): Promise<void> {
    if (!this.isLeaseholder) {
      this.driverList = [];
      this.platesList = [];
      this.trailerList = [];
      this.vehicleList = [];
      const leaseholderId = this.transportAssignmentForm.get(LEASEHOLDER_PARAM).value;
      const carrierId = leaseholderId ?
        leaseholderId._id : this.transportAssignmentForm.get(CARRIER_PARAM).value._id;
      this.trailerList = await this.trailerProvider.getCarrierTrailers(carrierId);
      this.extendedVehicleList = await this.vehicleProvider.getVehiculeTypesActive(carrierId);
      this.getVehicleTypes();
      this.getOperationTypes(carrierId);
      if (this.transportAssignmentForm.get(VEHICLE_PARAM).value) {
        this.transportAssignmentForm.controls[VEHICLE_PARAM].setValue(null);
      }
      await this.getDriversList(carrierId);
      if (this.vehicleList.length === 0 && this.driverList.length === 0
        && this.trailerList.length === 0) {
        this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastVehicleNorOperator);
      } else if (this.vehicleList.length === 0) {
        this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastVehicleType);
      } else if (this.driverList.length === 0) {
        this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastOperator);
      } else if (this.trailerList.length === 0) {
        this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastTrailers);
      }
    }
  }

  /**
    * @description Handles the event of selection of leaseholder
    * @param onLoad Flag in case the function is being called to load data from the ngOnInit
    */
  public async onLeaseholderSelected(onLoad?: boolean): Promise<void> {
    this.driverList = [];
    this.platesList = [];
    this.trailerList = [];
    this.vehicleList = [];
    let leaseholderId = this.transportAssignmentForm.get(LEASEHOLDER_PARAM).value;
    if (onLoad) {
      const CARRIER_LIST = (await this.carrierProvider.getShipperCarriers()).lineasTransporte;
      leaseholderId = _find(CARRIER_LIST, { 'nombre': this.injectData.leaseholder });
    }
    const carrierId = leaseholderId ?
      leaseholderId._id : this.transportAssignmentForm.get(CARRIER_PARAM).value._id;
    this.trailerList = await this.trailerProvider.getCarrierTrailers(carrierId);
    this.extendedVehicleList = await this.vehicleProvider.getVehiculeTypesActive(carrierId);
    this.getVehicleTypes();
    this.getOperationTypes(carrierId);
    if (this.transportAssignmentForm.get(VEHICLE_PARAM).value) {
      this.onVehicleSelected();
    }
    await this.getDriversList(carrierId);
    if (this.vehicleList.length === 0 && this.driverList.length === 0
      && this.trailerList.length === 0) {
      this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastVehicleNorOperator);
    } else if (this.vehicleList.length === 0) {
      this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastVehicleType);
    } else if (this.driverList.length === 0) {
      this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastOperator);
    } else if (this.trailerList.length === 0) {
      this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastTrailers);
    }
  }

  /**
   * @description Handles the event of selection of vehicle
   */
  public onVehicleSelected() {
    this.platesList = [];
    this.platesList = this.extendedVehicleList.filter(elem => {
      if (elem.tipo) {
        if (elem.tipo.nombre === this.transportAssignmentForm.get(VEHICLE_PARAM).value) {
          return elem;
        }
      }
    });
    this.sortAlphabetically(this.platesList, PLATES_ATTRIBUTE);
    if (this.platesList.length === 0) {
      this.toast.warningAlert(this.dialogTransportAssignmentLabels.toastPlates);
    }
    if (this.injectData && this.injectData.plates) {
      const platesPrevSelected = this.platesList.filter(plates => plates.placas === this.injectData.plates);
      if (platesPrevSelected.length > 0) {
        this.transportAssignmentForm.patchValue({ plates: platesPrevSelected[0] });
      }
    }
  }

  /**
   * @description Set default vehicle driver when driver is not selected
   */
  public setDefaultDriver(): void {
    if (!this.transportAssignmentForm.get(DRIVER_PARAM).value && this.transportAssignmentForm.get(PLATES_PARAM).value.driver) {
      const driver = this.driverList.find(driverElement => driverElement._id
        === this.transportAssignmentForm.get(PLATES_PARAM).value.driver._id);
      if (driver) {
        this.transportAssignmentForm.patchValue({ driver });
      }
    }
  }

  /**
   * @description Set default vehicle and plates when select driver and vehicle and plates are not selected
   */
  public setDefaultVehicle(): void {
    if (!this.transportAssignmentForm.get(PLATES_PARAM).value && this.transportAssignmentForm.get(DRIVER_PARAM).value.vehicle) {
      let plates;
      if (!this.transportAssignmentForm.get(VEHICLE_PARAM).value) {
        plates = this.extendedVehicleList.find(vehicle => vehicle._id === this.transportAssignmentForm.get(DRIVER_PARAM).value.vehicle._id);
        if (plates) {
          const vehicle = this.vehicleList.find(vehicleElement => vehicleElement === plates.tipo.nombre);
          this.transportAssignmentForm.patchValue({ vehicle });
          this.onVehicleSelected();
        }
      }

      plates = this.platesList.find(element => element._id === this.transportAssignmentForm.get(DRIVER_PARAM).value.vehicle._id);
      if (plates) {
        this.transportAssignmentForm.patchValue({ plates });
      }
    }
  }

  /**
   * @description Assign event checked to assign by shipper value
   * @param {MatCheckboxChange} $event event of the checkbox
   */
  public assignByShipperValidateCheck($event: MatCheckboxChange): void {
    this.assignByShipper = $event.checked;
    this.transportAssignmentForm.controls['assignByShipper'].setValue(this.assignByShipper);
    this.handleAssignByShipperFlag();
  }

  /**
   * @description Assign event checked to assign by shipper value
   * @param {MatCheckboxChange} $event event of the checkbox
   */
  public isLeaserCarrierValidateCheck($event: MatCheckboxChange): void {
    this.isLeaseholder = $event.checked;
    this.handleLeaseholderFlag();
    this.resetTransportAssignmentForm();
  }

  /**
   * @description Resets the transport assignment form, excluding the conditional checkboxes
   * @param keepCarrier Optional parameter in case the carrier must be kept
   */
  public resetTransportAssignmentForm(keepCarrier?: boolean): void {
    if (!keepCarrier) { this.transportAssignmentForm.controls[CARRIER_PARAM].reset(); }
    this.transportAssignmentForm.controls[LEASEHOLDER_PARAM].reset();
    this.transportAssignmentForm.controls[VEHICLE_PARAM].reset();
    this.transportAssignmentForm.controls[PLATES_PARAM].reset();
    this.transportAssignmentForm.controls[DRIVER_PARAM].reset();
    this.transportAssignmentForm.controls[HELPER_PARAM].reset();
    this.transportAssignmentForm.controls[TRAILER_PARAM].reset();
    this.transportAssignmentForm.controls[OTHER_REQUIREMENTS_PARAM].reset();
    this.resetTransportAssignmentFormValues();
  }

  /**
   * @description Simple function that clears the values of the transport assignment form
   */
  private resetTransportAssignmentFormValues(): void {
    this.driverList = [];
    this.platesList = [];
    this.trailerList = [];
    this.vehicleList = [];
  }

  /**
   * @description Validates if trailers are not more than 2
   */
  public selectTrailers() {
    if (this.transportAssignmentForm.get(TRAILER_PARAM).value.length <= TWO) {
      this.selectedTrailer = this.transportAssignmentForm.get(TRAILER_PARAM).value;
      this.transportAssignmentForm.get(TRAILER_PARAM).setValue(this.selectedTrailer);
    } else {
      this.transportAssignmentForm.get(TRAILER_PARAM).setValue(this.selectedTrailer);
    }
  }

  /**
   * @description Checks if exists suppliers registered from shipper and if already been displayed a warning
   * message to user when doesn't exists records registered.
   */
  public checkSuppliers(): void {
    if (!this.additionalsSuppliers.length && !this.shouldShowSuppliersNotFoundMessage) {
      this.toast.warningAlert(this.dialogTransportAssignmentLabels.noSuppliersRegisteredMsg);
      this.shouldShowSuppliersNotFoundMessage = true;
    }
  }

  /**
   * @description Order array elements alphabetically
   * @param arrayToSort Array to be sorted
   * @param attributeToSortBy Attribute to sort by
   */
  private sortAlphabetically(arrayToSort: Array<any>, attributeToSortBy: string): void {
    arrayToSort.sort(function (a, b) {
      const elementA = a[attributeToSortBy].toUpperCase().trim();
      const elementB = b[attributeToSortBy].toUpperCase().trim();
      return (elementA < elementB) ? -1 : (elementA > elementB) ? 1 : 0;
    });
  }

  /**
   * @description Add checkboxes control to form
   */
  private addCheckboxes() {
    this.specialRequirements.forEach((specialRequirement) => {
      const isContained = _some(this.injectData.specialRequirements.requirements, (requirement) => {
        return requirement === specialRequirement.value;
      });
      if (isContained) {
        this.requirementsFormArray.push(new UntypedFormControl(true));
      } else {
        this.requirementsFormArray.push(new UntypedFormControl(false));
      }
    });
  }

  /**
   * @description fill in the necessary data  for function get Carriers if is there courier selected
   */
  private initCarriers() {
    if (this.injectData.isCourierShipment) {
      this.carrierList = [{
        activa: true,
        anio_creacion: EMPTY_STRING,
        assignedTrip: false,
        carrierType: EMPTY_STRING,
        celular: EMPTY_STRING,
        contacto: EMPTY_STRING,
        direccion: EMPTY_STRING,
        driversNumber: 1,
        email: EMPTY_STRING,
        extensionTelefono: EMPTY_STRING,
        hombreCamion: false,
        identifier: EMPTY_STRING,
        imss: EMPTY_STRING,
        kmRecorridos: 1,
        nombre: this.injectData.courierSelected,
        permiso_sct: EMPTY_STRING,
        porcentajeRetencion: 0,
        publica: true,
        rating: 1,
        rfc: EMPTY_STRING,
        shortName: EMPTY_STRING,
        sinSpot: false,
        taxRegime: EMPTY_STRING,
        telefono: EMPTY_STRING,
        totalViajes: 0,
        vehicleNumber: 1
      }];
    } else {
      this.getCarriers();
    }
  }

  /**
   * @description Get carriers list and evaluates if carrier is previously selected
   */
  private async getCarriers(): Promise<void> {
    this.carrierList = (await this.carrierProvider.getShipperCarriers()).lineasTransporte;
    this.sortAlphabetically(this.carrierList, NAME_ATTRIBUTE);
    if (this.injectData && this.injectData.transportCarrier) {
      const carrierPrevSelected = this.carrierList.filter(item => item.nombre === this.injectData.transportCarrier);
      if (carrierPrevSelected.length > 0) {
        this.transportAssignmentForm.patchValue({ carrier: carrierPrevSelected[0] });
        if (this.injectData.leaseholder) {
          this.onLeaseholderSelected(true);
        } else {
          this.onLeaseholderSelected();
        }
      }
    }
  }

  /**
   * @description Initializes form and params from edit
   */
  private initTransportAssignmentForm(): void {
    const carrier = this.getCarrier();
    const admissionCedis = this.injectData.admissionCedisDate ? new Date(Date.parse(this.injectData.admissionCedisDate)) : null;
    const admissionCedisTime = this.injectData && admissionCedis ? this.formattingTime(admissionCedis.getHours(), admissionCedis.getMinutes()) : null;
    const loadStart = this.injectData.loadStartDate ? new Date(Date.parse(this.injectData.loadStartDate)) : null;
    const loadStartTime = this.injectData && loadStart ? this.formattingTime(loadStart.getHours(), loadStart.getMinutes()) : null;
    const loadEnd = this.injectData.loadEndDate ? new Date(Date.parse(this.injectData.loadEndDate)) : null;
    const loadEndTime = this.injectData && loadEnd ? this.formattingTime(loadEnd.getHours(), loadEnd.getMinutes()) : null;
    const onRouteDateTime = this.injectData.onRouteDateTime ? new Date(Date.parse(this.injectData.onRouteDateTime)) : null;
    const onRouteDateHour = this.injectData && onRouteDateTime ? this.formattingTime(onRouteDateTime.getHours(), onRouteDateTime.getMinutes()) : null;
    this.transportAssignmentForm = this.formBuilder.group({
      carrier: new UntypedFormControl(carrier, Validators.required),
      leaseholder: new UntypedFormControl((this.injectData ? this.injectData.leaseholder : null)),
      vehicle: new UntypedFormControl((this.injectData ? this.injectData.vehicle : null), Validators.required),
      plates: [(this.injectData ? this.injectData.plates : null), Validators.required],
      driver: [(this.injectData ? this.injectData.driver : null), Validators.required],
      helper: new UntypedFormControl(this.injectData ? this.injectData.helper : null),
      trailer: new UntypedFormControl(this.getTrailerData() ?? []),
      isLeaseholder: new UntypedFormControl((this.injectData.leaseholder ? true : false)),
      assignByShipper: new UntypedFormControl(this.assignByShipper),
      specialRequirementsModal: this.formBuilder.array([]),
      identificationFiles: new UntypedFormControl(this.licenseFormData),
      otherRequirements: new UntypedFormControl(this.injectData ? this.injectData.specialRequirements.otherRequirements : EMPTY_STRING),
      additionalsSuppliers: new UntypedFormControl(this.injectData?.additionalsSuppliers ?? null)
    });
    if (this.injectData.isCourierShipment) {
      this.transportAssignmentForm.get(VEHICLE_PARAM).setValidators([Validators.required, Validators.minLength(MIN_LENGTH),
        this.formsService.symbolsValidator, this.formsService.beginSpaceValidator]);
      this.transportAssignmentForm.get(PLATES_PARAM).setValidators([Validators.required, Validators.minLength(MIN_LENGTH),
        this.formsService.symbolsValidator, this.formsService.inbetweenSpaceValidator]);
      this.transportAssignmentForm.get(DRIVER_PARAM).setValidators([Validators.required, Validators.minLength(MIN_LENGTH),
        this.formsService.symbolsValidator, this.formsService.beginSpaceValidator]);
      this.transportAssignmentForm.updateValueAndValidity();
    }
    this.accessDateForm = this.formBuilder.group({
      admissionCedisDate: new UntypedFormControl((this.injectData.admissionCedisDate ? admissionCedis : null)),
      admissionCedisHour: new UntypedFormControl((admissionCedisTime)),
      loadStartDate: new UntypedFormControl((this.injectData.loadStartDate ? loadStart : null)),
      loadStartHour: new UntypedFormControl((loadStartTime)),
      loadEndDate: new UntypedFormControl((this.injectData.loadEndDate ? loadEnd : null)),
      loadEndHour: new UntypedFormControl((loadEndTime)),
      onRouteDateTime: new UntypedFormControl((this.injectData.onRouteDateTime ? onRouteDateTime : null)),
      onRouteDateHour: new UntypedFormControl((onRouteDateHour))
    });
    this.requirements = this.injectData.specialRequirements.requirements ? this.injectData.specialRequirements.requirements : [];
    this.isLeaseholder = (this.injectData.leaseholder ? true : false);
    this.addCheckboxes();
  }

  /**
   * @description Gets shipper settings and makes required RFC location field
   */
  public async getShipperSettings(): Promise<ShipperConfiguration> {
    try {
      const profile = this.appService.getProfile();
      if (profile === Profiles.Shipper) {
        const shipperOid = this.appService.getShipperOid();
        return await this.configurationProvider.getShipperConfig(shipperOid);
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  /**
   * @description Set the configurations to date fields with fieldsSettings of shipper configuration
   * and determinate if required or not
   */
  private setFieldsSettings(): void {
    if (this.shipperConfiguration?.fieldsSettings?.transportDatesRequired) {
      this.accessDateForm.get(KEY_ADMISSIONCEDIS_DATE).setValidators([Validators.required]);
      this.accessDateForm.get(KEY_ADMISSIONCEDIS_HOUR).setValidators([Validators.required]);
      this.accessDateForm.get(KEY_LOADSTART_DATE).setValidators([Validators.required]);
      this.accessDateForm.get(KEY_LOADSTART_HOUR).setValidators([Validators.required]);
      this.accessDateForm.get(KEY_LOADEND_DATE).setValidators([Validators.required]);
      this.accessDateForm.get(KEY_LOADEND_HOUR).setValidators([Validators.required]);
      this.accessDateForm.get(KEY_TRANSPORT_DATE).setValidators([Validators.required]);
      this.accessDateForm.get(KEY_TRANSPORT_HOUR).setValidators([Validators.required]);
    } else {
      this.accessDateForm.get(KEY_ADMISSIONCEDIS_DATE).clearValidators();
      this.accessDateForm.get(KEY_ADMISSIONCEDIS_HOUR).clearValidators();
      this.accessDateForm.get(KEY_LOADSTART_DATE).clearValidators();
      this.accessDateForm.get(KEY_LOADSTART_HOUR).clearValidators();
      this.accessDateForm.get(KEY_LOADEND_DATE).clearValidators();
      this.accessDateForm.get(KEY_LOADEND_HOUR).clearValidators();
      this.accessDateForm.get(KEY_TRANSPORT_DATE).clearValidators();
      this.accessDateForm.get(KEY_TRANSPORT_HOUR).clearValidators();
    }
    this.accessDateForm.get(KEY_ADMISSIONCEDIS_DATE).updateValueAndValidity();
    this.accessDateForm.get(KEY_ADMISSIONCEDIS_HOUR).updateValueAndValidity();
    this.accessDateForm.get(KEY_LOADSTART_DATE).updateValueAndValidity();
    this.accessDateForm.get(KEY_LOADSTART_HOUR).updateValueAndValidity();
    this.accessDateForm.get(KEY_LOADEND_DATE).updateValueAndValidity();
    this.accessDateForm.get(KEY_LOADEND_HOUR).updateValueAndValidity();
    this.accessDateForm.get(KEY_TRANSPORT_DATE).updateValueAndValidity();
    this.accessDateForm.get(KEY_TRANSPORT_HOUR).updateValueAndValidity();
  }

  /**
   * @description Check if element was clicked for validated if input time is required or not
   * @param {object} element change when selected some value over input
   */
  public changeDate(element: object) {
    const KEY_HTML = 'id';
    const KEY_TARGET_ELEMENT = 'targetElement';
    if (this.shipperConfiguration?.fieldsSettings?.transportDatesRequired) {
        switch(element[KEY_TARGET_ELEMENT][KEY_HTML]) {
          case KEY_ADMISSIONCEDIS_DATE: {
          this.setRequiredAndShowError(KEY_ADMISSIONCEDIS_HOUR);
          break;
        }
        case KEY_LOADSTART_DATE: {
          this.setRequiredAndShowError(KEY_LOADSTART_HOUR);
          break;
        }
        case KEY_LOADEND_DATE: {
          this.setRequiredAndShowError(KEY_LOADEND_HOUR);
          break;
        }
        case KEY_TRANSPORT_DATE: {
          this.setRequiredAndShowError(KEY_TRANSPORT_HOUR);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  /**
   * @description Set the input if required and display the message in-line
   * @param {string} id for apply the rules to field
   */
  private setRequiredAndShowError(id: string): void {
    this.accessDateForm.get(id)?.markAsTouched();
    this.accessDateForm.get(id)?.setErrors({ 'required': true });
  }

  /**
   * @description checks if shipment trip type is customer pickup to set the value of plates in input.
   *  Otherwise return al object data of trailers to display it in select form
   * @returns {string | TrailerModel} Value of plates if shipment trip type is customer pickup or all object of trailer
   * if is another trip Type
   */
  public getTrailerData(): string | TrailerModel {
    if (this.injectData && !this.injectData.isCustomerPickup && this.injectData.trailer) {

      return this.injectData.trailer;
    } else if (this.injectData && this.injectData.isCustomerPickup && this.injectData.trailer.length) {

      return this.injectData.trailer[0].plates;
    }
  }

  /**
   * @description checks and validates identification files selected of shipment to display it or retrieve the image from
   * Blob storage
   */
  private async checkFilesSavedInShipment(): Promise<void> {
    const filesLoaded = this.injectData.identificationFiles ? this.injectData.identificationFiles : null;
    if (filesLoaded && filesLoaded.every(element => typeof element === VariableTypes.string)) {
      await this.convertAndSetImages(filesLoaded);
    } else {
      this.identificationFilesConverted = this.injectData.identificationFiles;
    }
    this.checkFilesQuantitySelected();
  }

  /**
   * @description Adds correct time format
   * @param {number} hours
   * @param {minutes} minutes
   * @returns {string} Correct time format
   */
  public formattingTime(hours: number, minutes: number): string {
    const ten = 10;
    const colon = ':';
    const hoursFormatted = hours < ten ? (0 + hours.toString()) : hours;
    const minutesFormatted = minutes < ten ? (0 + minutes.toString()) : minutes;
    const time = hoursFormatted + colon + minutesFormatted;
    return time;
  }

  /**
  * @description Get carrier if is there and is the mode courier shipment selected
  */
  private getCarrier(): Object {
    let carrier: Object;
    if (this.injectData.transportCarrier) {
      if (this.injectData.isCourierShipment) {
        carrier = { nombre: this.injectData.transportCarrier };
      } else {
        carrier = this.injectData.transportCarrier;
      }
    } else if (this.injectData.isCourierShipment && !_isEmpty(this.injectData.courierSelected)) {
      carrier = { nombre: this.injectData.courierSelected };
    } else {
      carrier = null;
    }
    return carrier;
  }
  /**
   * @description Get list of vehicles
   */
  private getVehicleTypes(): void {
    let vehicleList: Array<string>;
    vehicleList = this.extendedVehicleList.map(vehicle => {
      return vehicle.tipo ? vehicle.tipo.nombre : TYPEUNDEFINED;
    });
    const newReducedArray = Array.from(new Set(vehicleList));
    this.vehicleList = [...newReducedArray];
    this.vehicleList.sort((a, b) => a !== b ? a < b ? -1 : 1 : 0);
  }

  /**
   * @description Get list of drivers
   * @param {string} carrierId carrier identifier
   */
  private async getDriversList(carrierId: string): Promise<void> {
    this.driverList = await this.driverProvider.getDriversByCarrierId(carrierId);
    this.sortAlphabetically(this.driverList, NAME_ATTRIBUTE);
    if (this.injectData && this.injectData.driver) {
      const driverPrevSelected = this.driverList.filter(driver =>
        (driver.nombre + SPACE_STRING + driver.appaterno + SPACE_STRING + driver.apmaterno) === this.injectData.driver);
      if (driverPrevSelected.length > 0) {
        this.transportAssignmentForm.patchValue({ driver: driverPrevSelected[0] });
      }
    }
  }

  /**
   * @description Handles assign by shipper flag
   */
  public handleAssignByShipperFlag() {
    if (this.assignByShipper || this.injectData.isCourierShipment || this.userProfile === Profiles.Carrier) {
      this.transportAssignmentForm.controls[PLATES_PARAM].enable();
      this.transportAssignmentForm.controls[DRIVER_PARAM].enable();
    } else {
      this.transportAssignmentForm.controls[PLATES_PARAM].disable();
      this.transportAssignmentForm.controls[DRIVER_PARAM].disable();
      this.transportAssignmentForm.controls[PLATES_PARAM].markAsUntouched();
      this.transportAssignmentForm.controls[DRIVER_PARAM].markAsUntouched();
    }
  }

  /**
   * @description Handles leaseholder flag
   */
  public handleLeaseholderFlag() {
    if (this.isLeaseholder || this.injectData.isCourierShipment) {
      this.transportAssignmentForm.controls[LEASEHOLDER_PARAM].enable();
    } else {
      this.transportAssignmentForm.controls[LEASEHOLDER_PARAM].disable();
      this.transportAssignmentForm.controls[LEASEHOLDER_PARAM].markAsUntouched();
    }
  }

  /**
   * @description Fill the array of leaseholders using the selected carrier
   * @param onLoad Flag in case the function is being called to load data from the ngOnInit
   */
  public async assignLeaseholder(onLoad?: boolean): Promise<void> {
    this.transportAssignmentForm.controls[LEASEHOLDER_PARAM].reset();
    this.carrierLeaseholderList = [];
    this.resetTransportAssignmentFormValues();
    const CARRIER_LIST = (await this.carrierProvider.getShipperCarriers()).lineasTransporte;
    const permitCarriersList = [];
    if (this.isLeaseholder) {
      const selectedCarrier = !onLoad ?
        this.transportAssignmentForm.controls[CARRIER_PARAM].value : _find(CARRIER_LIST, { 'nombre': this.injectData.transportCarrier });
      if (this.isLeaseholder && selectedCarrier.permitHoldedCarriers.length) {
        for (const AUX_PERMIT_HOLER of selectedCarrier.permitHoldedCarriers) {
          const AUX_CARRIER = await this.carrierProvider.getCarrierByOid(AUX_PERMIT_HOLER._id);
          permitCarriersList.push(AUX_CARRIER);
        }
        if (permitCarriersList.length) { this.carrierLeaseholderList = permitCarriersList; }
        if (this.injectData.leaseholder && onLoad) {
          this.transportAssignmentForm.get(LEASEHOLDER_PARAM).setValue(_find(CARRIER_LIST, { 'nombre': this.injectData.leaseholder }));
        }
      } else {
        this.toast.errorAlert(this.dialogTransportAssignmentLabels.leaseholderNotFound);
        this.carrierLeaseholderList = [];
        this.resetTransportAssignmentForm(true);
      }
    }
  }

  /**
   * @description Use for date picker
   * @param {Event} event The event emitted when typing
   */
  public handleKeyEnter(event: Event): void {
    event.preventDefault();
  }

  /**
   * @description Builds an object with flags for the data assigned by the shipper.
   * @param {TransportMainShipperData} TransportMainShipperData New assigned transport data.
   * @returns {TransportByShipper} - Flags of the transport assigned by the shipper.
   */
  private async buildTransportByShipper(TransportMainShipperData: TransportMainShipperData): Promise<TransportByShipper> {
    const auxSearch = {
      ids: [this.injectData.shipmentId]
    };
    const currentShipment = await this.shipmentProvider.shipmentSearch(this.injectData.tenantId, auxSearch);
    if (currentShipment.length) {
      this.shipmentTransport = currentShipment[0].transport;
    }
    if (this.isShipperProfile) {
      const auxTransportByShipper = {
        isHelperAssignedByShipper: TransportMainShipperData?.helper ? true : false,
        isTrailerAssignedByShipper: TransportMainShipperData?.trailer?.length ? true : false,
        isTransportAssignedByShipper: TransportMainShipperData?.driver || TransportMainShipperData?.plates ? true : false
      };

      return auxTransportByShipper;
    } else {
      const auxTransportByShipper = {
        isHelperAssignedByShipper: this.shipmentTransport.isHelperAssignedByShipper ?? false,
        isTrailerAssignedByShipper: this.shipmentTransport.isTrailerAssignedByShipper ?? false,
        isTransportAssignedByShipper: this.shipmentTransport.isTransportAssignedByShipper ?? false
      };

      return auxTransportByShipper;
    }
  }

  /**
   * @description Sets the value of operationTypes based on the selected carrier.
   * @param {string} carrierId Carrier identifier
   */
  private getOperationTypes(carrierId: string): void {
    const selectedCarrier = _find(this.carrierList, { '_id': carrierId });
    if (selectedCarrier.operationTypes) {
      this.operationTypes = selectedCarrier.operationTypes;
    } else {
      this.operationTypes = [];
    }
  }

  /**
   * @description Checks existence date type
   */
  private checkDate(): void {
    let admissionCedisDate = this.accessDateForm.value.admissionCedisDate;
    let loadStartDate = this.accessDateForm.value.loadStartDate;
    let loadEndDate = this.accessDateForm.value.loadEndDate;
    let onRouteDateTime = this.accessDateForm.value.onRouteDateTime;
    admissionCedisDate = admissionCedisDate ? this.setNewTime(admissionCedisDate, this.accessDateForm.value.admissionCedisHour) : null;
    loadStartDate = loadStartDate ? this.setNewTime(loadStartDate, this.accessDateForm.value.loadStartHour) : null;
    loadEndDate = loadEndDate ? this.setNewTime(loadEndDate, this.accessDateForm.value.loadEndHour) : null;
    onRouteDateTime = onRouteDateTime ? this.setNewTime(onRouteDateTime, this.accessDateForm.value.onRouteDateHour) : null;
    this.validationsForDates(admissionCedisDate, loadStartDate, loadEndDate, onRouteDateTime);
  }

  /**
   * @description Display the carrier profile sections.
   */
  private displayCarrierProfileSections(): void {
    this.isDateAndTimeSectionDisplayed = true;
    this.isSpecialRequirementsSectionDisplayed = false;
    this.isAccessDateFormSectionDisplayed = false;
    this.isLeaseholderCheckBoxDisplayed = false;
    this.isAssignShipperCheckBoxDisplayed = false;
    this.assignByShipper = true;
    this.isCarrierSelectDisabled = true;
    this.isVehicleSelectDisabled = true;
    this.isTrailerInputDisplayed = this.injectData.isCourierShipment ? true : false;
    this.isCarrierProfile = true;
    this.isShipperProfile = false;
  }

  /**
   * @description Display the default profile sections.
   */
  private displayDefaultProfileSections(): void {
    this.isDateAndTimeSectionDisplayed = false;
    this.isSpecialRequirementsSectionDisplayed = true;
    this.isAccessDateFormSectionDisplayed = true;
    this.isLeaseholderCheckBoxDisplayed = this.injectData.isCourierShipment || this.injectData.isCustomerPickup ?
      false : this.isConsignmentNoteConfigActive;
    this.isAssignShipperCheckBoxDisplayed = this.injectData.isCourierShipment ? false : true;
    this.assignByShipper = this.injectData ? !this.injectData.assignByCarrier : false;
    this.isCarrierSelectDisabled = this.injectData.isCourierShipment ? true : false;
    this.isVehicleSelectDisabled = this.injectData.isCourierShipment ? true : false;
    this.isTrailerInputDisplayed = this.injectData.isCustomerPickup && !this.injectData.isCourierShipment ? true : false;
    this.isCarrierProfile = false;
    this.isShipperProfile = true;
  }

  /**
   * @description Determines the sections must be displayed according to profile type
   * @param {string} userProfile - The current user profile type name
   */
  private displaySectionsByProfile(userProfile: string): void {
    switch (userProfile) {
      case Profiles.SysAdmin:
        this.displaySysAdminSections();
        break;
      case Profiles.Shipper:
        this.displayShipperProfileSections();
        break;
      case Profiles.Carrier:
        this.displayCarrierProfileSections();
        break;
      default:
        this.displayDefaultProfileSections();
        break;
    }
  }

  /**
   * @description Display the shipper profile sections.
   */
  private displayShipperProfileSections(): void {
    this.isDateAndTimeSectionDisplayed = false;
    this.isSpecialRequirementsSectionDisplayed = true;
    this.isAccessDateFormSectionDisplayed = true;
    this.isLeaseholderCheckBoxDisplayed = this.injectData.isCourierShipment || this.injectData.isCustomerPickup ?
      false : this.isConsignmentNoteConfigActive;
    this.isAssignShipperCheckBoxDisplayed = this.injectData.isCourierShipment ? false : true;
    this.assignByShipper = this.injectData ? !this.injectData.assignByCarrier : false;
    this.isCarrierSelectDisabled = this.injectData.isCourierShipment ? true : false;
    this.isVehicleSelectDisabled = this.injectData.isCourierShipment ? true : false;
    this.isTrailerInputDisplayed = this.injectData.isCustomerPickup && !this.injectData.isCourierShipment ? true : false;
    this.isCarrierProfile = false;
    this.isShipperProfile = true;
  }

  /**
   * @description Display the system admin profile sections.
   */
  private displaySysAdminSections(): void {
    this.isDateAndTimeSectionDisplayed = false;
    this.isSpecialRequirementsSectionDisplayed = true;
    this.isAccessDateFormSectionDisplayed = true;
    this.isLeaseholderCheckBoxDisplayed = this.injectData.isCourierShipment || this.injectData.isCustomerPickup ?
      false : this.isConsignmentNoteConfigActive;
    this.isAssignShipperCheckBoxDisplayed = this.injectData.isCourierShipment ? false : true;
    this.assignByShipper = this.injectData ? !this.injectData.assignByCarrier : false;
    this.isCarrierSelectDisabled = this.injectData.isCourierShipment ? true : false;
    this.isVehicleSelectDisabled = this.injectData.isCourierShipment ? true : false;
    this.isTrailerInputDisplayed = this.injectData.isCustomerPickup && !this.injectData.isCourierShipment ? true : false;
    this.isCarrierProfile = false;
    this.isShipperProfile = true;
  }

  /**
   * @description Look up the current shipment transport log to validate if the shipper has updated transport
   *  during the current transport assignment process.
   * @param {TransportMainShipperData} TransportMainShipperData Main transport data.
   * @returns {Promise<boolean>} - Whether the shipper has updated the transport data or not.
   */
  public async isAssignedTransport(TransportMainShipperData: TransportMainShipperData): Promise<boolean> {
    const transporByShipper = this.shipmentTransport.isTransportAssignedByShipper;
    const shipperHelper = this.shipmentTransport.isHelperAssignedByShipper;
    const shipperTrailer = this.shipmentTransport.isTrailerAssignedByShipper;
    let shipmentTrailers = [];
    let currentTrailers = [];
    for (const trailer of this.shipmentTransport.trailer) {
      shipmentTrailers.push(trailer._id);
    }
    for (const trailer of TransportMainShipperData.trailer) {
      currentTrailers.push(trailer._id);
    }
    const isEqualTrailers = shipmentTrailers.length === currentTrailers.length ?
      shipmentTrailers.every(trailerId => currentTrailers.some(id => trailerId === id)) : false;
    if ((transporByShipper && (this.shipmentTransport.driver !== TransportMainShipperData.driver ||
      this.shipmentTransport.plates !== TransportMainShipperData.plates)) ||
      (shipperHelper && this.shipmentTransport.helper !== TransportMainShipperData.helper) ||
      (shipperTrailer && !isEqualTrailers)) {

      return true;
    }

    return false;
  }

  /**
   * @description Validate if a valid date
   * @param {Date} admissionCedisDate Date from 'accessDateForm' Form Group
   * @param {Date} loadStartDate Date from 'accessDateForm' Form Group
   * @param {Date} loadEndDate Date from 'accessDateForm' Form Group
   */
  private validationsForDates(admissionCedisDate: Date, loadStartDate: Date, loadEndDate: Date, onRouteDateTime?: Date): void {
    if ((admissionCedisDate && loadStartDate) && (admissionCedisDate.getTime() > loadStartDate.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.biggerCedisDateLoadStart);
      throw Error;
    } else if ((admissionCedisDate && loadEndDate) && (admissionCedisDate.getTime() > loadEndDate.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.biggerCedisDateLoadEnd);
      throw Error;
    } else if (admissionCedisDate && (admissionCedisDate.getTime() < this.currentDay.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.biggerCedisDateCurrent);
      throw Error;
    } else if (loadStartDate && (loadStartDate.getTime() < this.currentDay.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.biggerLoadStartCurrent);
      throw Error;
    } else if (loadEndDate && (loadEndDate.getTime() < this.currentDay.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.invalidLoadEnd);
      throw Error;
    } else if ((loadStartDate && loadEndDate) && (loadStartDate > loadEndDate)) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.invalidLoadStartDate);
      throw Error;
    } else if (onRouteDateTime && (onRouteDateTime.getTime() < this.currentDay.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.invalidTransportCurrent);
      throw Error;
    } else if ((admissionCedisDate && onRouteDateTime) && (onRouteDateTime.getTime() < admissionCedisDate.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.invalidTransportCedis);
      throw Error;
    } else if ((loadStartDate && onRouteDateTime) && (onRouteDateTime.getTime() < loadStartDate.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.invalidTransportLoadDate);
      throw Error;
    } else if ((loadEndDate && onRouteDateTime) && (onRouteDateTime.getTime() < loadEndDate.getTime())) {
      this.toast.errorAlert(this.dialogTransportAssignmentLabels.invalidTransportLoadEndDate);
      throw Error;
    } else {
      this.accessDateForm.value.admissionCedisDate = admissionCedisDate;
      this.accessDateForm.value.loadStartDate = loadStartDate;
      this.accessDateForm.value.loadEndDate = loadEndDate;
      this.accessDateForm.value.onRouteDateTime = onRouteDateTime;
    }
  }

  /**
   * @description Set time from the form
   * @param {Date} dateToConvert Date that will be set a new time
   * @param {string} timeToSet Time to set
   * @return {Date} Date with new time
   */
  private setNewTime(dateToConvert: Date, timeToSet: string): Date {
    const dateWithNewTime = dateToConvert;
    const colon = ':';
    const radixParam = 10;
    const endDayHour = AppConstants.END_DAY_HOUR;

    if (timeToSet) {
      const hours = timeToSet.split(colon)[0];
      const minutes = timeToSet.split(colon)[1];
      dateWithNewTime.setHours(parseInt(hours, radixParam));
      dateWithNewTime.setMinutes(parseInt(minutes, radixParam));
    } else {
      dateWithNewTime.setHours(endDayHour.hours, endDayHour.minutes, endDayHour.seconds);
    }

    return dateWithNewTime;
  }

  /**
   * @description Method called when user selects an additional supplier to avoid select to user more than 2 options.
   */
  public onSelectSupplier() {
    if (this.transportAssignmentForm.get('additionalsSuppliers').value.length <= TWO) {
      this.suppliersSelected = this.transportAssignmentForm.get('additionalsSuppliers').value;
    } else {
      this.transportAssignmentForm.get('additionalsSuppliers').setValue(this.suppliersSelected);
    }
  }
}
