import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';
import { AppService } from '../../app.service';
import { AuxShipment, LoadPlanData, ShipmentDataBody, StopsBody, TransportBody } from '../../interfaces/shipment';
import { CarrierProvider } from '../../providers/carrier/carrier.provider.service';
import { DateToolsService } from '../utils/date-tools.service';
import { DATEFORMAT_CONSTANTS } from '../../constants/dateformat.constants';
import { DownloadFilesService } from '../utils/download-files.service';
import { GenericRegexp } from '../../regexp/generic.regexp';
import { OrderForShipment, Shipments, ShipmentRowExtended, ShipperConfiguration, VehicleList } from '../../interfaces';
import { OrderProvider } from '../../providers/orders/order-provider.service';
import {
  OrderGroupByStopSears,
  OrderSears,
  SearsRequestBody,
  SearsRequest,
  SearsResponse,
  StopSears,
  ExceptionResponse,
  ExceptionGeneral,
} from '../../interfaces/sears';
import { SearsProvider } from '../../providers/sears/sears.provider.service';
import { SEARS_CONSTANT } from '../../constants/sears-constants';
import { ShipmentProvider } from '../../providers/shipments/shipment-provider.service';
import { ShipmentRequestStatusTEP } from '../../enums/shipment_request_status';
import { ShipmentType } from '../../enums/shipmentType';
import { ShipmentLogs } from '../../interfaces/shipment-logs';
import {
  SHIPPERS_SHARED_LOAD_PLAN_SEARS
} from '../../constants/shippers-ids';
import { ToastrAlertsService } from '../utils/toastr-alerts.service';
import { VehiculeTypeProvider } from '../../providers/vehiculeType/vehiculeType.provider.service';
import { WarehouseProvider } from '../../providers/warehouse/warehouse-provider.service';

import * as moment from  'moment';
import { orderBy as _orderBy, chain as _chain } from 'lodash';

const ERROR_CODE_DUPLICATE = 409;

@Injectable()
export class ShareLoadPlanService {
  public invalidShipmentsForShareLoadShipments: Array<string>;
  public loadPlanInfo: object;
  public stopsData: Array<StopsBody>;
  constructor(
    private _appService: AppService,
    private carrierProvider: CarrierProvider,
    public dateToolsService: DateToolsService,
    private downloadService: DownloadFilesService,
    private searsProvider: SearsProvider,
    private shipmentProvider: ShipmentProvider,
    private toast: ToastrAlertsService,
    private wareHouseProvider: WarehouseProvider,
    public orderProvider: OrderProvider,
    public vehicleProvider: VehiculeTypeProvider
  ) {
    this.stopsData = [];
    this.invalidShipmentsForShareLoadShipments = [ShipmentType.Collection, ShipmentType.Courier];
  }

  /**
   * @description Checks if the shipper needs confirmation by wms to can confirm the shipments and if Exists the configuration
   * to send the load plan Data to WMS
   * @param {string} shipperId from shipper to validate
   * @param {ShipperConfiguration} shipperConfig Configuration from shipper
   * @returns {boolean} Validation result true/false
   */
  public shipperNeedsConfirmationByWms(shipperId: string, shipperConfig: ShipperConfiguration ): boolean {
    return (shipperConfig && shipperConfig.enableWmsInterface &&
      (shipperConfig.shareLoadPlanWms?.url && shipperConfig.shareLoadPlanWms?.url !== AppConstants.EMPTY_STRING &&
        shipperConfig.shareLoadPlanWms?.wmsName && shipperConfig.shareLoadPlanWms?.wmsName !== AppConstants.EMPTY_STRING));
  }

  /**
   * @description Checks if shipment and shipper config to share load plan data
   * @param {string} tenantId Id from shipper
   * @param {ShipperConfiguration} shipperConfig Data about shipper config
   * @param {ShipmentDataBody} shipment Data about shipment to create/edit
   * @param {string} userName Username on shipper
   * @param {string} successMsg Success message to display if the operation is successfully
   * @param {string} errorMsg Error message to display if the operation fails
   */
  public async checkShipmentAndShareLoadPlan(tenantId: string, shipperConfig: ShipperConfiguration, shipment: ShipmentDataBody,
    userName: string, successMsg: string, errorMsg: string): Promise<void> {
    if (this.validateConfigToShareLoadPlan(tenantId, shipperConfig, shipment)) {
      await this.shareLoadPlanToWms(shipment, shipperConfig, tenantId, userName, successMsg, errorMsg);
    }
  }

  /**
   * @description Checks if shipment is valid to share load plan data
   * @param shipmentStatus Status of shipment
   * @param shipmentTripType Trip trpe of shipment
   * @param isLoadPlanSentToWms Flag to kow if the load plan has been sent to WMS before
   * @param isLoadPlanConfirmedByWms Flag to know if the load plan data has been confirmated by WMS
   * @returns {boolean} True or false depending if the shipment is valid
   */
  public isShipmentValidToShareLoadPlanData(shipmentStatus: string, shipmentTripType: string, isLoadPlanSentToWms: boolean,
    isLoadPlanConfirmedByWms: boolean): boolean {
    return (shipmentStatus === ShipmentRequestStatusTEP.Assigned && shipmentTripType !== ShipmentType.Courier
      && shipmentTripType !== ShipmentType.Collection && !isLoadPlanConfirmedByWms && !isLoadPlanSentToWms);
  }

  /**
   * @description Validates if shipper needs confirmation by Wms and if exists the configuration for that
   * @param {string} shipperId Id from shipper
   * @param {ShipperConfiguration} shipperConfig Data about shipper
   * @param {ShipmentDataBody | ShipmentRowExtended} shipment Data of shipment to validate and share load plan data
   * @returns {boolean} true or false if the shipment and shipper are valid
   */
  public validateConfigToShareLoadPlan(shipperId: string, shipperConfig: ShipperConfiguration,
    shipment: ShipmentDataBody | ShipmentRowExtended): boolean {
    return (this.shipperNeedsConfirmationByWms(shipperId, shipperConfig) &&
      this.isShipmentValidToShareLoadPlanData(shipment.status, shipment.tripType, shipment.isLoadPlanSentToWms,
        shipment.confirmationByInterface));
  }

  /**
   * @description creates an object with shipment data
   * @param {ShipmentRowExtended} shipmentsData Data about shipment
   * @returns {<Array<Object>>} Array with shipment info created
   */
  public async createShipmentBody(shipmentsData: ShipmentDataBody): Promise<Array<object>> {
    const shipmentsBody = [];
    this.loadPlanInfo = {
      shipmentId: shipmentsData.shipmentId,
      internalReference: shipmentsData.internalReference,
      tripType: shipmentsData.tripType,
      origin: shipmentsData.origin.name,
      loadDateTime: shipmentsData.loadStartDate,
      transport: this.buildShipmentTransportBody(shipmentsData.transport),
      stops: await this.createStopsBody(shipmentsData.orders),
    };
    shipmentsBody.push(this.loadPlanInfo);

    return shipmentsBody;
  }

  /**
   * @description Builds transport body for load plan to share.
   * @param {TransportBody} shipmentTransport - Transport from current shipment.
   * @returns {TransportBody} Body built to share.
   */
  private buildShipmentTransportBody(shipmentTransport: TransportBody): TransportBody {
    const transportBody = {
      carrier: {
        identifier: shipmentTransport.carrierIdentifier,
        name: shipmentTransport.transportCarrier,
      },
      vehicleType: shipmentTransport.vehicle,
      plate: shipmentTransport.plates,
      driver: shipmentTransport.driver
    };

    return transportBody;
  }

  /**
   * @description Filter the orders depending if the are portage orders or not
   * @param {Array<OrderForShipment>} ordersData Orders data from shipment
   * @returns {<Array<StopsBody>>} an array with all stops info
   */
  public async createStopsBody(ordersData: Array<OrderForShipment>): Promise<Array<StopsBody>> {
    const orders = ordersData.filter(order => !order.plannedWarehouse);
    const portageOrders = ordersData.filter(order => order.plannedWarehouse);
    this.stopsData = [];

    if (orders.length) {
      this.createStopsForOrders(orders);
    }
    if (portageOrders.length) {
      await this.createStopsForPortageOrders(portageOrders);
    }

    return this.stopsData;
  }

  /**
   * @description Checks stops for the orders and then creates stops info with that orders info
   * @param {Array<OrderForShipment>} ordersData Array with all orders data
   */
  public createStopsForOrders(ordersData: Array<OrderForShipment>): void {
    const shipmentStops = [...new Set(ordersData.map(item => item.stop))];
    for (const stop of shipmentStops) {
      const ordersFromStop = ordersData.filter(order => order.stop === stop);
      const orders = [];
      for (const order of ordersFromStop) {
        const loadPlanOrder = { id: order.orderId, accountName: order.account.name };
        orders.push(loadPlanOrder);
      }
      const orderBody = {
        identifier: ordersFromStop[0].destination.locationId,
        name: ordersFromStop[0].destination.name,
        address: ordersFromStop[0].destination.address,
        orders: orders
      };
      this.stopsData.push(orderBody);
    }
  }

  /**
   * @description Checks stops for the portage orders and then creates stops info with that orders info
   * @param {Array<OrderForShipment>} portageOrdersData data about orders
   */
  public async createStopsForPortageOrders(portageOrdersData: Array<OrderForShipment>): Promise<void> {
    const stopsOfShipment = [...new Set(portageOrdersData.map(item => item.stop))];
    for await (const stop of stopsOfShipment) {
      const ordersFromStop = portageOrdersData.filter(order => order.stop === stop);
      const PlannedWarehouseData = await this.wareHouseProvider.getWarehouseByOid(ordersFromStop[0].plannedWarehouse._id);
      const orders = [];
      for (const order of ordersFromStop) {
        const loadPlanOrder = { id: order.orderId, accountName: order.account.name };
        orders.push(loadPlanOrder);
      }
      const orderBody = {
        name: ordersFromStop[0].plannedWarehouse.name,
        address: PlannedWarehouseData.addressInfo.address,
        orders: orders
      };
      this.stopsData.push(orderBody);
    }
  }

  /**
   * @description Method to change the flag isLoadPlanSentToWms depending if the share of load data has been succesfully
   * @param {string} tenantId Id of shipper
   * @param {ShipmentDataBody} shipmentData Data of shipment edited/created
   * @param {string} username name of user
   * @param {boolean} successShare Flag to know if load data has been shared succesfully to change the flag of shipment
   */
  public async changeSendToWmsStatus(tenantId: string, shipmentData: ShipmentDataBody, username: string, successShare: boolean):
    Promise<void> {
    if (successShare) {
      shipmentData.isLoadPlanSentToWms = true;
    } else if (!successShare && shipmentData.isLoadPlanSentToWms) {
      shipmentData.isLoadPlanSentToWms = true;
    } else {
      shipmentData.isLoadPlanSentToWms = false;
    }
    await this.shipmentProvider.updateShipment(tenantId, shipmentData.shipmentId, shipmentData, username);
  }

  /**
   * @description checks if data of load plan can be shared with WMS
   * @param {ShipmentDataBody} shipmentData Data about shipment to create/edit
   * @param {string} shipperId Id from shipper
   * @param {string} userName Username on shipper
   * @param {string} successMsg Success message to display if the operation is successfully
   * @param {string} errorMsg Error message to display if the operation fails
   */
  public async shareLoadPlanToWms(shipmentData: ShipmentDataBody, shipperConfig: ShipperConfiguration, shipperId: string, userName: string,
      successMsg: string, errorMsg: string): Promise<void> {
    try {
      const shipmentBody = await this.createShipmentBody(shipmentData);
      const loadDataToShare: LoadPlanData = {
        config: {
          url: shipperConfig.shareLoadPlanWms.url
        },
        data: shipmentBody
      };
      await this.shipmentProvider.shareLoadPlanData(loadDataToShare, shipperId);
      await this.changeSendToWmsStatus(shipperId, shipmentData, userName, true);
      this.toast.successAlert(successMsg);
    } catch (error) {
      this.toast.errorAlert(errorMsg);
      await this.changeSendToWmsStatus(shipperId, shipmentData, userName, false);
    }
  }

  /**
   * @description gets the valid of sears token if the request was successful
   * @returns {Promise<SearsResponse>} response
   */
  private async getTokenByCustomer(tenantId: string): Promise<SearsResponse> {
    try {
      return await this.searsProvider.getToken(tenantId);
    } catch (error) {
      return null;
    }
  }

  /**
   * @description Generates the request to send and share the data to an external provider
   * @param {ShipmentDataBody} shipmentData Data about shipment to create/edit
   * @param {string} shipperId or tenant for valid and generate the request
   * @param {string} messageSharedError error to show when generate some exception or faile the request to sears
   * @param {string} messageErrorSetShipmentLog error to show when failed the shipmentLog event
   * @param {string} messageErrorGenerateFileError error to show when failed the creation file of errors
   * @param {string} messageSharedSuccess message to show in case the request is success is optional
   */
  public async sharedShipment(
    shipmentData: ShipmentDataBody | ShipmentRowExtended,
    shipperId: string,
    messageSharedError: string,
    messageErrorSetShipmentLog: string,
    messageErrorGenerateFileError: string,
    messageSharedSuccess?: string,
    ): Promise<void> {
    if (
      !SHIPPERS_SHARED_LOAD_PLAN_SEARS.includes(shipperId) ||
      shipmentData.isLoadPlanSentToWms
    ) {
      return;
    }
    const userName = this._appService.getShipperNameCookie();
    try {
      const searsRequest = await this.buildSearsRequestBody(shipmentData);
      const response = await this.searsProvider.sharedData(shipperId, searsRequest);
      if (response.item) {
        this.toast.successAlert(response.item.message ?? messageSharedSuccess);
        await this.changeSendToWmsStatus(shipperId, shipmentData, userName, true);
        await this.setShipmentLogs(
          shipmentData,
          SEARS_CONSTANT.SUCCESS_SHARED_SHIPMENT,
          messageErrorSetShipmentLog
        );
        return;
      }
      this.toast.errorAlert(messageSharedError);
    } catch (exception) {
      const error = exception.error as ExceptionResponse;
      if (error.code === ERROR_CODE_DUPLICATE) {
        this.toast.errorAlert(error.description);
        return;
      } else {
        if (error.exceptionArray?.length) {
          this.processErrors(
            error.exceptionArray,
            shipmentData.shipmentId,
            messageErrorGenerateFileError
          );
        }
        if (shipmentData.shipmentId) {
          await this.changeSendToWmsStatus(shipperId, shipmentData, userName, false);
          await this.setShipmentLogs(
            shipmentData,
            SEARS_CONSTANT.FAIL_SHARED_SHIPMENT,
            messageSharedError
          );
        }
      }
      this.toast.errorAlert(messageSharedError);
    }
  }

  /**
   * @description Build the object to shared to provider 'sears'
   * @param {ShipmentDataBody} shipmentData Data about shipment to create/edit
   * @return {Promise<SearsRequestBody>} body valid to shared to sears
   */
  private async buildSearsRequestBody(shipmentData: ShipmentDataBody): Promise<SearsRequestBody> {
    const SearsRequestBody: SearsRequestBody = {
      data: []
    };
    const ZERO = 0;
    const searsRequest: SearsRequest = {} as SearsRequest;
    const warehouse = await this._appService.getUserWarehouse();
    const user = (await this._appService.getShipperNameCookie() ?? ZERO).match(/\d+$/)[0] ?? ZERO;
    const carrierList = (await this.carrierProvider.getShipperCarriers(shipmentData.tenantId)).lineasTransporte;
    const companyName = '1';
    const deliveryType = '1';
    const economicNumber = '0';
    const TIME_ZONE = 'America/Mexico_City';
    const carrier = carrierList?.find(
      (carrier) => carrier.nombre.toLowerCase() === shipmentData.transport.transportCarrier.toLowerCase()
      );
    const extendedVehicleList: Array<VehicleList> = (await this.vehicleProvider.getVehiculeTypesActive(carrier?._id)) as Array<VehicleList>;
    const vehicle = extendedVehicleList.find(vehicle => vehicle.tipo.nombre?.toLowerCase() === shipmentData.transport.vehicle?.toLowerCase())
    const loadDateTime =
      shipmentData.onRouteDateTime
      ? this.setTimeZoneToDate(shipmentData.onRouteDateTime, TIME_ZONE, DATEFORMAT_CONSTANTS.FORMAT_DATE_ISO_LITERAL_T)
      : undefined;
    const creationDate =
      this.setFormatToDate((shipmentData.creationDate ?? new Date()), DATEFORMAT_CONSTANTS.FULL_DATE_FORMAT_WITHOUT_SECONDS);
    searsRequest.internalReference = shipmentData.shipmentId ?? AppConstants.EMPTY_STRING;
    searsRequest.origin = warehouse.name;
    searsRequest.deliveryType = deliveryType;
    searsRequest.companyName = companyName;
    searsRequest.user = user;
    if (loadDateTime) {
      const hoursSubtract = 1;
      const newDate = this.dateToolsService.subtractHousToDate(loadDateTime, hoursSubtract);
      searsRequest.loadDateTime = this.getDateFormatLocalISO(newDate);
    }
    searsRequest.creationDate = creationDate;
    searsRequest.stops = [...await this.getStopSears(shipmentData)];
    searsRequest.transport = {
      carrier: carrier?.shortName ?? AppConstants.EMPTY_STRING,
      driver: shipmentData.transport.driver,
      economicNumber: vehicle.kmTraveled?.toString() ?? economicNumber,
      plate: shipmentData.transport.plates,
      vehicleType: shipmentData.transport.vehicle
    };
    searsRequest.tripType = shipmentData.tripType;
    SearsRequestBody.data.push(searsRequest);

    return SearsRequestBody;
  }

  /**
   * @description Group by the orders by stop and return a list of orders type 'OrderGroupByStopSears
   * @param {ShipmentDataBody} shipmentData Data about shipment to create/edit
   * @return {Promise<Array<StopSears>>} stopSearsList new list of stops with orders
   */
  private async getStopSears(shipmentData: ShipmentDataBody): Promise<Array<StopSears>> {
    const stopSearsList: Array<StopSears> = [];
    const stopStatus = 1;
    const radix = 10;
    const ordersGroupByStops = this.groupOrdersByStop(shipmentData.orders);
    if (ordersGroupByStops?.length) {
      for (const stopWithOrders of ordersGroupByStops) {
        if (stopWithOrders.stop && stopWithOrders.orders && stopWithOrders.orders.length) {
          const order = shipmentData.orders.find(order => order.stop.toString() === stopWithOrders.stop);
          const address = order.destination.address ?? AppConstants.EMPTY_STRING;
          const name = order.destination.name ?? AppConstants.EMPTY_STRING;
          const orders = await this.getOrdersFromShipment(stopWithOrders.orders);
          stopSearsList.push({
            orders: orders,
            address: address,
            name: name,
            stopNumber: parseInt(stopWithOrders.stop, radix),
            stopStatus: stopStatus
          });
        }
      }
    }

    return stopSearsList;
  }

  /**
   * @description Group by the orders by stop and return a list of orders type 'OrderGroupByStopSears
   * @param {Array<OrderForShipment>} orders for filtered
   * @return {Array<OrderGroupByStopSears>} Order for generate request
   */
  private groupOrdersByStop(orders: Array<OrderForShipment>): Array<OrderGroupByStopSears> {
    const KEY_STOP = 'stop';
    return _chain(orders)
      .groupBy(KEY_STOP)
      .map((value, key) => ({
        stop: key,
        orders: value
      })).value() as Array<OrderGroupByStopSears>;
  }

  /**
   * @description Get list of Orders related with the stop
   * @param {Array<OrderForShipment>} orders for filtered
   * @return {Promise<Array<OrderSears>>} Order for generate request
   */
  private async getOrdersFromShipment(orders: Array<OrderForShipment>): Promise<Array<OrderSears>> {
    const orderList = [];
    const orderIdList: Array<string> = orders.map(order => order._id);
    const arrayOids = {
      ordersIds: orderIdList
    }
    const orderListFound = await this.orderProvider.getOrdersByOids(arrayOids);
    for (const order of orders) {
      const _order = orderListFound?.find(
        (orderFind) => orderFind._id.toString() === order._id.toString()
      );
      orderList.push({
        id: _order?.orderGrouper ?? AppConstants.EMPTY_STRING,
        invoice: _order?.invoice ?? AppConstants.EMPTY_STRING
      });
    }

    return orderList;
  }

  /**
   * @description Set new log to shipment when the shipment shared
   * @param {ShipmentRowExtended} shipmentData Shipment data
   * @param {string} action message for set when generate new log for shipment
   * @param {string} messageErrorSetShipmentLog message to show in the toast error o some exception
   */
  public async setShipmentLogs(
    shipmentData: ShipmentDataBody,
    action: string,
    messageErrorSetShipmentLog: string
  ): Promise<void> {
    try {
      const shipmentLog: ShipmentLogs = {
        action: action,
        status: shipmentData.status,
        shipment: shipmentData._id,
        versionModified: false,
        user: await this._appService.getShipperNameCookie()
      };

      await this.shipmentProvider.setShipmentLogs(shipmentData._id, shipmentLog);
    } catch (error) {
      this.toast.errorAlert(messageErrorSetShipmentLog);
    }
  }

  /**
   * @description processes the response and if there are errors they will be handled
   * @param {Array<ExceptionGeneral>} errors to handle
   * @param {string} messageErrorGenerateFileError message to show in the toast error o some exception
   */
  private processErrors(
    errorList: Array<ExceptionGeneral>,
    fileId: string,
    messageErrorGenerateFileError
    ): void {
    try {
      let errorToProcess: Array<string> = [];
      errorToProcess.push(...this.getErrorList(errorList));

      if (errorToProcess.length === 0) { return; }

      this.generateErrorsFile(errorToProcess, fileId);
    } catch (error) {
      this.toast.errorAlert(messageErrorGenerateFileError);
    }
  }

  /**
   * @description processes the response and if there are errors they will be handled
   * @param {Array<string>} errors to handle
   * @param {string} fileId for identifier the file generated
   */
  private generateErrorsFile(errors: Array<string>, fileId: string): void {
    const TEXT_FILE_FORMAT = '.txt';
    const ERROR_FILE_NAME = 'ErroresSears-';
    this.downloadService.saveTextAsFile(
      errors.toString().replace(GenericRegexp.REG_COMMA, AppConstants.EMPTY_STRING),
      (ERROR_FILE_NAME + fileId + TEXT_FILE_FORMAT)
    );
  }

  /**
   * @description get the list of errors to set in error's file
   * @param {Array<ExceptionGeneral>} errors to handle
   * @returns {Array<string>} array of errors completes
   */
  private getErrorList(errorList: Array<ExceptionGeneral>): Array<string> {
    const errors: Array<string> = [];
    const LINE_BREAK = '\n';
    for (const error of errorList) {
      errors.push(error.description + LINE_BREAK);
    }

    return errors;
  }

  /**
   * @description Apply format to date, always this is valid
   * @param {Date} date to apply format
   * @param {string} format valid
   * @return {string} date formated
   */
  private setFormatToDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  /**
   * @description Apply time zone to date, always this is valid
   * @param {Date} date to apply format
   * @param {string} format valid
   * @return {string} date formated
   */
  private setTimeZoneToDate(date: Date | string, timezone: string, format: string): string {
    return moment.tz(date, timezone).format(format);
  }

  /**
   * @description Get the current local time in format 'YYYY-MM-ddTHH:mm:ss.SSSZ'
   * without add the time Universal Coordinated Time (UTC)
   * @param {string | Date} date to apply format
   * @returns {string} value with format
   */
  private getDateFormatLocalISO(date: string | Date): string {
    const KEY_GMT = 'GMT';
    const KEY_UTC = ' UTC';
    const date_str = new Date(date)
      .toString()
      .split(KEY_GMT)[0];

    return new Date(date_str + KEY_UTC).toISOString();
  }

  /**
   * @description Builds a temporal shipment object to compare la
   */
  private buildAuxShipments(shipment: AuxShipment): AuxShipment {
    const ordersSorted = _orderBy(shipment?.orders, ['folio'], [AppConstants.ASCENDENT]);
    const accountsSorted = _orderBy(shipment?.account, ['name'], [AppConstants.ASCENDENT]);
    const shipmentAux = {
      account: accountsSorted,
      boxes: shipment?.boxes,
      destination: shipment?.destination,
      isPortage: shipment?.isPortage,
      loadType: shipment?.loadType,
      orders: ordersSorted,
      origin: shipment?.origin,
      originWarehouse: shipment?.originWarehouse,
      pallets: shipment?.pallets,
      pieces: shipment?.pieces,
      shipmentClass: shipment?.shipmentClass,
      shipmentId: shipment?.shipmentId,
      shipmentServiceType: shipment?.shipmentServiceType,
      shipperId: shipment?.shipperId,
      specialRequirements: shipment?.specialRequirements,
      status: shipment?.status,
      suggestedTransport: shipment?.suggestedTransport,
      tenantId: shipment?.tenantId,
      transport: shipment?.transport,
      tripType: shipment?.tripType,
      volume: shipment?.volume,
      weight: shipment?.weight,
      _id: shipment?._id
    };
    if (shipment?.specialRequirements?.requirements) {
      shipmentAux.specialRequirements.requirements = shipment?.specialRequirements?.requirements.sort();
    }

    return shipmentAux;
  }

  /**
   * @description Verifies if the current shipment has been updated and is valid to share load plan
   * @param {Shipment} shipmentUpdated Shipment updated response
   * @param {ShipmentRowExtended} shipment Shipment before be updated
   * @param {string} shipperOid Current shipper object Id
   * @param {boolean} isCreation No required value to indicates if it's a shipment creation
   * @returns {boolean} A boolean value
   */
  public isValidToShareLoadPlan(shipmentUpdated: Shipments, shipment: ShipmentRowExtended, isCreation?: boolean): boolean {
    if (isCreation && !shipment && shipmentUpdated.status === ShipmentRequestStatusTEP.Assigned &&
      !this.invalidShipmentsForShareLoadShipments.includes(shipmentUpdated.tripType)) {
      return true;
    } else {
      const auxShipmentUpdated = this.buildAuxShipments(shipmentUpdated);
      const auxShipment = this.buildAuxShipments(shipment);

      return shipmentUpdated.status === ShipmentRequestStatusTEP.Assigned &&
        !this.invalidShipmentsForShareLoadShipments.includes(shipmentUpdated.tripType)
        && JSON.stringify(auxShipmentUpdated) !== JSON.stringify(auxShipment);
    }
  }
}
