import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatOption, ThemePalette } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { Account, AccountBody, AccountInfo, AccountResponse, AccountWithPod } from '../../interfaces/account';
import { AccountProvider } from '../../providers/accounts/account-provider.service';
import { AccountsAppointmentOrdersRequired, ApportionmentApiResponse, CedisAccess, ConfigurationChangesLog,
  DriverConfig, RateParamsConfig, ShipmentUndeliveredOrdersStatus, Withholdings } from './../../interfaces/configuration';
import { AppConstants } from '../../constants/app-constants.constants';
import {
  ApportionmentUpdateBody,
  ConfigurationLabelsData,
  FieldsSettings,
  TenderingConfig,
  ToleranceCedisAccessBody,
  WithholdingsBody
} from './../../interfaces/configuration';
import { AppService } from 'src/app/app.service';
import { BlobProvider } from './../../providers/evidence/blob-provider.service';
import { Charge } from 'src/app/interfaces/charge';
import {
  ConfigurationConstants,
  MaximumOffersTendering,
  ParametersToAutomaticAssignation,
  RatingCost,
  RatingQuality,
  RatingSecurity,
  RatingServices,
  SortOffersTendering
} from './configuration.constants';
import { ConfigurationLabels, ConfigurationTags, ActionLog } from './configuration.labels';
import { ConfigurationProvider } from '../../providers/configuration/configuration.provider.service';
import { ConfigurationViewProperties } from './configuration-view.properties';
import { CommunicationService } from '../../services/communication';
import { DialogCheckTemperaturesComponent } from './../../components/dialog/dialog-check-temperatures/dialog-check-temperatures.component';
import { DialogSelectWarehouseComponent } from '../../components/dialog/dialog-select-warehouse/dialog-select-warehouse.component';
import { DialogStandardFocusComponent } from '../../components/dialog/dialog-standard-focus/dialog-standard-focus.component';
import { Dimensions } from './../../interfaces/dimensions';
import { DragAndDropFile } from './../../interfaces/drag-and-drop';
import { environment } from '../../../environments/environment';
import { EventInput } from './../../interfaces/eventInput';
import { FileConversorService } from './../../services/file-conversor';
import { FilesToShow } from './../../interfaces/file-conversor';
import { GenericRegexp } from './../../regexp/generic.regexp';
import { IntegratorProvider } from '../../providers/integrator/integrator-provider.service';
import { StampProvider, StampProviderView } from '../../interfaces/stampProvider';
import { Rate, WarehouseDefault } from '../../interfaces';
import { Recurrence, RecurrenceForm, RecurrenceFromAzure, WeekdayList } from 'src/app/interfaces/recurrence';
import { SearchMatSelectResults } from '../../interfaces/search-mat-select';
import { ServerlessProvider } from '../../providers/serverless/serverless-provider.service';
import { ServiceType } from './../../interfaces/service-type/service-type.interface';
import { ServiceTypeProvider } from './../../providers/service-type/service-type-provider.service';
import { CostingConfigurationBody, DocumentsSettings, ShipperConfiguration } from './../../interfaces/ShipperConfiguration';
import { SortTypes } from '../../interfaces/marketplace';
import { SystemClientConfigs } from '../../interfaces/system-client-configs';
import { TemperaturesFormBody } from './../../interfaces/temperature-body';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';
import { TrackingService } from './../../providers/tracking/tracking.service';
import { UserService } from '../../providers/user/user.service';

import { isEqual as _isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

const ADMIN_PROFILES = ['Administrador', 'Sysadmin'];
const AUTO_ASSIGNMENT = 'Automática';
const ALLOWED_EXTENSIONS = ['image/jpeg', 'image/jpg', 'image/png'];
const BLOB_STORAGE_URL = environment.baseStorageUrl + environment.mainContainer;
const CONFIG_DIALOG_IMAGE = '../../../assets/confirmConfigModal.svg';
const CONFIRM = 'Confirm';
const DAILY = 'Diario';
const DATA = 'data';
const DAY = 'Day';
const DIALOG_HEIGHT = '300px';
const DIALOG_WIDTH = '640px';
const EMPTY_STRING = '';
const FILES_KEY = 'files';
const INFO_ICON_IMG = '../../../assets/icons/info.svg';
const INPUT_FILES_KEY = 'inputFiles';
const ID_FAKE = 'XY';
const KEY_BLOBNAME = 'blobName';
const KEY_SASURL = 'sasUrl';
const KEYS_ACCOUNT_IDS = 'accountIds';
const KEY_ALL = 'ALL';
const LOGO_DIALOG_IMAGE = '../../../assets/home.svg';
const MANUAL_ASSIGNMENT = 'Manual';
const MAX_FILES_ALLOWED = 1;
const MAX_IMAGE_HEIGHT = 800;
const MAX_IMAGE_WIDTH = 800;
const MONTH = 'Month';
const MONTHLY = 'Mensual';
const NAME = 'name';
const POD_COLUMNS = ['name', 'periodicity', 'actions'];
const POST = 'POST';
const SERVERLESS_URL = environment.serverlessUrl;
const SPLIT_TOKEN_EXTENSION = '.';
const TENANT_AUX = 'tenant';
const WEEK = 'Week';
const WEEKLY = 'Semanal';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss', '../../app.component.scss']
})

export class ConfigurationComponent implements OnInit, OnDestroy {
  public accountControlName: string;
  public accountSelectLabel: string;
  public accounts: Array<SearchMatSelectResults>;
  public accountList: Array<Account>;
  public accountsWithPOD: AccountWithPod[];
  public addAccountPodEnable: boolean;
  public allAccounts: AccountResponse;
  public allowedExtensions: Array<string>;
  public apportionmentFormGroup: UntypedFormGroup;
  public apportionmentPercentages: number;
  public availableAccounts: RecurrenceForm[];
  public biddingTenderingFormGroup: UntypedFormGroup;
  public cedisAccessData: ToleranceCedisAccessBody;
  public cedisAccessFormBody: ToleranceCedisAccessBody;
  public cedisAccessFormGroup: UntypedFormGroup;
  public cedisAccessRequest: CedisAccess;
  public charges: Array<Charge>;
  public chargesToAdd: Array<string>;
  public color: ThemePalette;
  public configShipmentOrderFormGroup: UntypedFormGroup;
  public currentApportSettings: ApportionmentApiResponse;
  public dataSource: MatTableDataSource<Charge>;
  public defaultValueAccount: Account;
  public defaultMaxDriverInactiveTime: number;
  public defaultWarehouse: WarehouseDefault;
  public disableSaveButton: boolean;
  public disableSaveButtonParams: boolean;
  public displayLabel: string;
  public displayedColumns: Array<string>;
  public editingPod: boolean;
  public enableSaveLogo: boolean;
  public filesAddedByButton: FileList;
  public hasStampProviderList: boolean;
  public imagesConverted: Array<DragAndDropFile>;
  public infoIcon: string;
  public isApportionmentValid: boolean;
  public isCedisAccessValid: boolean;
  public isCedisConfigEnable: boolean;
  public isCheckChargesAndDiscountsEnabled: boolean;
  public isCostConfigButtonEnabled: boolean;
  public isDisabledSaveButton: boolean;
  public isDocumentsSeetingsButtonEnabled: boolean;
  public isFieldSettingsChanged: boolean;
  public isFirstPreload: boolean;
  public isFreightCostRequiredToConfirmShipments: boolean;
  public isLoadPlanByProductsActive: boolean;
  public isMaxPercentageError: boolean;
  public isMinTemperatureError: boolean;
  public isPaymentTypeRequired: boolean;
  public isSavingAccountsInfo: boolean;
  public isSavingFieldsSettings: boolean;
  public isSavingTenderingInfo: boolean;
  public isServiceTypeSelected: boolean;
  public isShipConfigButtonEnabled: boolean;
  public isTenderingAutomaticAssignation: boolean;
  public isTenderingManualAssignation: boolean;
  public isToleranceCedisValid: boolean;
  public isWithholdingsValid: boolean;
  public labels: ConfigurationLabelsData;
  public lastCedisAccessStatus: boolean;
  public maxDriverInactiveTime: number;
  public maxFilesAllowed: number;
  public maxResolution: Dimensions;
  public maximumOffers: Array<number>;
  public modalSubscription: Subscription;
  public multipleFiles: boolean;
  public neccessaryRFCForLocations: boolean;
  public newCharge: string;
  public noChangesInAccountsSelected: boolean;
  public noWarehouses: boolean;
  public panelTendering: boolean;
  public parametersToAutomaticAssignation: Array<SortTypes>;
  public percentageFiscalBorderStrip: number;
  public percentageFreightRetention: number;
  public percentageISR: number;
  public percentageIVA: number;
  public percentageManeuverRetention: number;
  public percentageSymbol: string;
  public periodicityName: string;
  public podAccountsArray: string[];
  public podColumns: Array<string>;
  public podDataSource: MatTableDataSource<Account>;
  public podListResponse: RecurrenceFromAzure[];
  public publicAccessAccounts: UntypedFormGroup;
  public publicAccessAccountsSaved: Array<SearchMatSelectResults>;
  public ratesSelected: Array<Rate>;
  public ratingCost: Array<Rate>;
  public ratingQuality: Array<Rate>;
  public ratingSecurity: Array<Rate>;
  public ratingServices: Array<Rate>;
  public recurrence: Recurrence;
  public saveAvailable: boolean;
  public searchMatSelectEvent: Subscription;
  public searchMatSelectSelection: Subscription;
  public selectedAccounts: Array<AccountInfo>;
  public serviceType: Array<ServiceType>;
  public serviceTypePercentage: number;
  public serviceTypes: Array<ServiceType>;
  public shipperConfig: ShipperConfiguration;
  public shipperId: string;
  public shipperRateCost: Array<Rate>;
  public shipperRateParamsSaved: Array<Rate>;
  public shipperRateSecurity: Array<Rate>;
  public shipperRateServices: Array<Rate>;
  public shipperRatingQuality: Array<Rate>;
  public shipperTemperatureFormGroup: UntypedFormGroup;
  public shipperTemperatures: TemperaturesFormBody;
  public shipperTenderingConfig: TenderingConfig;
  public shipUndeliveredOrders: boolean;
  public shipRejectedOrders: boolean;
  public showCarrierNameInOffers: boolean;
  public showOrdersInPublicPortal: boolean;
  public showedMaxTemperature: string;
  public showedMinBox: string;
  public showedMinTemperature: string;
  public sortTypes: Array<SortTypes>;
  public sumCompleted: boolean;
  public stampProviderFormGroup: UntypedFormGroup;
  public stampProviderList: Array<StampProviderView>;
  public stampProviderViewActive: StampProviderView;
  public stampProviderViewChange: boolean;
  public systemClientConfigsList: Array<SystemClientConfigs>;
  public temperatureData: TemperaturesFormBody;
  public temperatureRangeEnabled: boolean;
  public temperatureRequest: TemperaturesFormBody;
  public tenantExists: boolean;
  public tenantId: string;
  public tenantName: string;
  public tenderingAssign: string;
  public tenderingConfigBody: TenderingConfig;
  public userIsAdmin: boolean;
  public userName: string;
  public userOid: string;
  public userProfile: string;
  public warehouseList: Array<WarehouseDefault>;
  public weekdayList: WeekdayList[];
  public withholdingsFormGroup: UntypedFormGroup;
  public isActivateDetentionChecked: boolean;
  public isActivateUseUccChecked: boolean;
  public isAppointmentOrdersRequired: boolean;
  public isTransportDatesRequired: boolean;

  constructor(
    private accountsProvider: AccountProvider,
    private comService: CommunicationService,
    private appService: AppService,
    private blobProvider: BlobProvider,
    private configurationProvider: ConfigurationProvider,
    private fileConversorService: FileConversorService,
    private readonly builder: UntypedFormBuilder,
    private readonly temperatureBuilder: UntypedFormBuilder,
    private router: Router,
    private serverlessProvider: ServerlessProvider,
    private serviceTypeProvider: ServiceTypeProvider,
    private toast: ToastrAlertsService,
    private trackingProvider: TrackingService,
    private userProvider: UserService,
    private integratorProvider: IntegratorProvider,
    public datepipe: DatePipe,
    public dialog: MatDialog
  ) {
    this.isLoadPlanByProductsActive = false;
    this.hasStampProviderList = false;
    this.isCheckChargesAndDiscountsEnabled = false;
    this.labels = ConfigurationTags;
    this.sortTypes = SortOffersTendering;
    this.maximumOffers = MaximumOffersTendering;
    this.parametersToAutomaticAssignation = ParametersToAutomaticAssignation;
    this.ratingServices = RatingServices;
    this.ratingQuality = RatingQuality;
    this.ratingCost = RatingCost;
    this.ratingSecurity = RatingSecurity;
    this.disableSaveButtonParams = true;
    this.disableSaveButton = true;
    this.noChangesInAccountsSelected = true;
    this.showOrdersInPublicPortal = false;
    this.neccessaryRFCForLocations = false;
    this.isSavingFieldsSettings = false;
    this.isFieldSettingsChanged = false;
    this.isFirstPreload = true;
    this.ratesSelected = [];
    this.accounts = [];
    this.infoIcon = INFO_ICON_IMG;
    this.accountControlName = ConfigurationConstants.ACCOUNT_CONTROL_NAME;
    this.accountSelectLabel = this.labels.requiredAccount;
    this.stampProviderList = [];
    this.stampProviderViewChange = false;
    this.defaultValueAccount = {
      _id: ID_FAKE,
      name: KEY_ALL
    };
    this.isShipConfigButtonEnabled = false;
  }

  /**
   * @description Event fires when init component
   */
  public async ngOnInit(): Promise<void> {
    this.initForm(this.builder);
    this.initFormStampProvider(this.builder);
    this.initFormconfigShipmentOrder(this.builder);
    this.setTenderingConfigToForm();
    this.initTenderingForm();
    this.isCedisAccessValid = false;
    this.isSavingTenderingInfo = false;
    this.isCedisConfigEnable = false;
    this.isToleranceCedisValid = false;
    this.lastCedisAccessStatus = false;
    this.panelTendering = true;
    this.tenantId = this.appService.getShipperOid();
    this.shipperId = this.appService.getShipperOid();
    this.getApportionmentConfig();
    this.getServicesTypes();
    this.setValuesTemperatures();
    this.setValuesToTemperaturesForm();
    this.initTemperatureForm();
    this.serviceTypes = [];
    await this.getStampProvidersIntoSystemClientsConfigs();
    await this.getTemperaturesToForm();
    this.validateChangeTemperatureForm();
    this.warehouseList = [];
    this.noWarehouses = true;
    this.imagesConverted = [];
    this.enableSaveLogo = false;
    this.percentageSymbol = '%';
    this.multipleFiles = false;
    this.allowedExtensions = ALLOWED_EXTENSIONS;
    this.maxFilesAllowed = MAX_FILES_ALLOWED;
    this.displayLabel = ConfigurationLabels.dragAndDropLabel;
    this.defaultMaxDriverInactiveTime = ConfigurationConstants.DEFAULT_MAX_DRIVER_INACTIVE_TIME;
    this.maxResolution = { width: MAX_IMAGE_WIDTH, height: MAX_IMAGE_HEIGHT };
    this.tenantName = this.appService.getShipperNameCookie();
    this.userOid = this.appService.getUserOid();
    this.userName = (await this.userProvider.getUserById(this.userOid)).username;
    this.userProfile = this.appService.getProfile();
    this.userIsAdmin = false;
    this.checkProfilePermission();
    this.charges = [];
    this.displayedColumns = ['number', 'charge'];
    this.podColumns = POD_COLUMNS;
    await this.getShipperConfig();
    this.getTenderingConfig();
    this.listenSearchMatSelect();
    this.listenSearchMatSelection();
    this.shipUndeliveredOrders = this.shipperConfig.isShipUndeliveredOrdersChecked;
    this.shipRejectedOrders = this.shipperConfig.canReshipRejectedOrders;
    this.isActivateDetentionChecked = this.shipperConfig.isActivateDetentionChecked;
    this.isActivateUseUccChecked = this.shipperConfig.isActivateUseUccChecked;
    this.isAppointmentOrdersRequired = this.shipperConfig.isAppointmentOrdersRequired;
    this.temperatureRangeEnabled = this.shipperConfig.thermometersManagement;
    this.percentageFiscalBorderStrip = this.shipperConfig.percentageFiscalBorderStrip ? this.shipperConfig.percentageFiscalBorderStrip : 0;
    this.percentageFreightRetention = this.shipperConfig.percentageFreightRetention ? this.shipperConfig.percentageFreightRetention : 0;
    this.percentageISR = this.shipperConfig.percentageISR ? this.shipperConfig.percentageISR : 0;
    this.percentageIVA = this.shipperConfig.percentageIVA ? this.shipperConfig.percentageIVA : 0;
    this.percentageManeuverRetention = this.shipperConfig.percentageManeuverRetention ? this.shipperConfig.percentageManeuverRetention : 0;
    this.isFreightCostRequiredToConfirmShipments = this.shipperConfig.isFreightCostRequiredToConfirmShipments;
    this.isLoadPlanByProductsActive = this.shipperConfig.documentsSettings.isLoadPlanByProductsActive;
    this.isCheckChargesAndDiscountsEnabled = this.shipperConfig.isCheckChargesAndDiscountsEnabled ?? false;
    this.isPaymentTypeRequired = this.shipperConfig.isPaymentTypeRequired ?? false;
    await this.configurationProvider.getCostApiSettings(this.tenantId)
      .then(res => {
        this.tenantExists = true;
      })
      .catch(error => {
        this.tenantExists = false;
      });
    await this.configurationProvider.getChargesTypesByTenant(this.tenantId)
      .then((res: Array<Charge>) => {
        if (!res) {
          this.charges = [];
        } else {
          this.charges = res;
        }
        this.dataSource = new MatTableDataSource(this.charges);
      });
    this.chargesToAdd = [];
    this.saveAvailable = false;
    await this.getUserWarehouses();
    this.addAccountPodEnable = false;
    this.editingPod = false;
    try {
      const podResponse = await this.serverlessProvider.podConfiguration({ list: [] });
      this.podAccountsArray = this.getAllAcountsWithPod(podResponse);
      this.podListResponse = this.getPodRecurrenceInfo(podResponse);
      await this.accountsProvider.getAccounts()
        .then(accountsResponse => {
          this.allAccounts = accountsResponse;
          this.accountList = this.getAccountNames(this.allAccounts.cuentas);
          this.separateAccountsWithPOD(this.allAccounts);
          this.podDataSource = new MatTableDataSource(this.accountsWithPOD);
        })
        .catch(error => {
          this.toast.warningAlert(ConfigurationLabels.noAccountsError);
        });
    } catch (error) {}
    this.isWithholdingsValid = false;
    this.shipperTemperatures = await this.configurationProvider.getTemperatureSettings(this.tenantId);
    this.apportionmentPercentages = 0;
    this.isFirstPreload = false;
    if (this.shipperConfig.accountsAppointmentOrdersRequired?.length) {
      this.setDefaultAccounts(this.shipperConfig.accountsAppointmentOrdersRequired);
      this.verifyIfExistChanges();
    }
  }

  /**
   * @description gets all tendering config of shipper
   */
  public getTenderingConfig(): void {
    this.shipperTenderingConfig = this.shipperConfig.tenderingConfig ? this.shipperConfig.tenderingConfig : null;
    this.shipperRateParamsSaved = this.shipperConfig.tenderingConfig ? this.shipperConfig.tenderingConfig.rateParams : null;
    if (this.shipperRateParamsSaved) {
      this.generateParamsSelected();
    }
    this.setTenderingConfigToForm();
    this.initTenderingForm();
  }

  /**
   * gets and generates and array with all rate params saved from shipper
   */
  public generateParamsSelected(): void {
    const paramsSaved = [];
    for (let i = 0; i < this.shipperRateParamsSaved.length; i++) {
      const param = {
        rating: 0,
        category: this.shipperRateParamsSaved[i].category,
        description: this.shipperRateParamsSaved[i].description,
        position: this.shipperRateParamsSaved[i].position
      };
      paramsSaved.push(param);
    }
    this.shipperRateParamsSaved = [...paramsSaved];
    this.ratesSelected = paramsSaved;
  }

  /**
    * @description search and sorts accounts by name
    * @param {string} name Account's name to search
    * @returns {Promise<Array<AccountBody>>} Accounts found
    */
  public async getAccountsByName(name: string): Promise<Array<AccountBody>> {
    try {
      const accounts = await this.accountsProvider.getAccountsByNameCoincidences(name, this.tenantId);
      const accountsSorted = this.sortAndCheckAccountResults(accounts);
      const results: Array<SearchMatSelectResults> = [];
      accountsSorted.forEach(account => results.push({ name: account.nombre, _id: account._id }));
      this.accounts = results;
      if (!this.accounts.length) {
        this.toast.warningAlert(ConfigurationLabels.noAccountsFound);
      }

      return accountsSorted;
    } catch (error) {
      this.toast.errorAlert(ConfigurationLabels.searchAccountsError);
    }
  }

  /**
   * @description checks if the accounts results are major to 10 to display just the first 10 accounts sorted by name.
   * Otherwise if the reasults are minor to 10 this results are displayed
   * @param {Array<AccountBody>} accounts results of the account search to sort and chack
   * @returns {Array<AccountBody>} accounts remainings and sorted
   */
  public sortAndCheckAccountResults(accounts: Array<AccountBody>): Array<AccountBody> {
    let accountsSorted = [];
    accounts.sort((a, b) => (a.nombre.toLocaleLowerCase() > b.nombre.toLocaleLowerCase()) ? 1 :
      ((b.nombre.toLocaleLowerCase() > a.nombre.toLocaleLowerCase()) ? -1 : 0));

    if (accounts.length > 9) {
      for (let i = 0; i < 9; i++) {
        accountsSorted.push(accounts[i]);
      }
    } else {
      accountsSorted = accounts;
    }

    return accountsSorted;
  }

  /**
   * @description displays a modal to confirm save the changes in accounts selected for the shipper
   */
  public confirmSaveAccounts(): void {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: ConfigurationConstants.TENDERING_ICON,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });
    this.modalSubscription = dialogRef.afterClosed().subscribe(async result => {
      if (result === CONFIRM) {
        this.isSavingAccountsInfo = true;
        this.toast.processingAlert();
        await this.saveAccountsInfo();
      }
    });
  }

  /**
   * @description Displays a dailog to save fields settings
   */
   public confirmFieldsSettings(): void {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: ConfigurationConstants.TENDERING_ICON,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });
    this.modalSubscription = dialogRef.afterClosed().subscribe(async result => {
      if (result === CONFIRM) {
        this.isSavingFieldsSettings = true;
        this.toast.processingAlert();
        await this.saveFieldsSettings();
      }
    });
  }

  /**
   * @description Saves shipper fields settings
   */
  public async saveFieldsSettings(): Promise<void> {
    try {
      const fieldsToUpdate: FieldsSettings = {
        locationRFCRequired: this.neccessaryRFCForLocations,
        transportDatesRequired: this.isTransportDatesRequired,
      };
      await this.configurationProvider.saveShipperFieldsSettings(this.shipperId, fieldsToUpdate).then(async () => {
        const logsConfigToSave = await this.getChangesLogs();
        if (logsConfigToSave.length) {
          await this.configurationProvider.saveConfigurationLogs(logsConfigToSave, this.shipperId);
        }
        this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
      });

    } catch {
      this.toast.errorAlert(ConfigurationLabels.toastErrorSavingFieldsSettings);
    } finally {
      this.isSavingFieldsSettings = false;
      this.toast.closeProcessing();
      this.redirectConfigurationView();
    }
  }

  /**
   * @description Check if there are changes for field settings
   */
  public onChangesFieldsSettings(): void {
    if (this.shipperConfig.fieldsSettings) {
      if (this.shipperConfig.fieldsSettings.locationRFCRequired !== this.neccessaryRFCForLocations ||
        this.shipperConfig.fieldsSettings.transportDatesRequired !== this.isTransportDatesRequired) {
        this.isFieldSettingsChanged = true;
      } else {
        this.isFieldSettingsChanged = false;
      }
    }
  }

  /**
   * @description saves the accounts selected in the shipper configuration
   */
  public async saveAccountsInfo(): Promise<void> {
    try {
      await this.configurationProvider.savePublicAccessAccountConfig(this.shipperId, this.selectedAccounts);
      this.toast.closeProcessing();
      this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
      this.isSavingAccountsInfo = false;
      this.redirectConfigurationView();
    } catch (error) {
      this.isSavingAccountsInfo = false;
      this.toast.closeProcessing();
      this.toast.errorAlert(ConfigurationLabels.errorSaveAccounts);
    }
  }

  /**
   * @description checks if the param exists in shipper configuration
   * @param {rate} parameter data of the parameter to check
   * @returns {boolean} true/false if the param exists in the shipper configurations
   */
  public paramSavedInShipper(parameter: Rate): Boolean {
    if (this.shipperRateParamsSaved && this.shipperRateParamsSaved.find(param => param.description === parameter.description)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Listener to account search events
   */
  public listenSearchMatSelect(): void {
    this.searchMatSelectEvent = this.comService.searchMatSelectSubscribe().subscribe(async search => {
      await this.getAccountsByName(search.pharse);
    });
  }

  /**
   * @description sets the tendering configuration data to display the information in form
   */
  public setTenderingConfigToForm(): void {
    if (this.shipperConfig) {
      if (this.shipperTenderingConfig.showCarrierNameInOffers === false) {
        this.showCarrierNameInOffers = false;
      } else {
        this.showCarrierNameInOffers = true;
      }
      this.shipperTenderingConfig = {
        assignationType: this.shipperTenderingConfig.assignationType ? this.shipperTenderingConfig.assignationType
          : ConfigurationConstants.MANUAL_TENDERING_ASSIGNATION,
        sortOffersBy: this.shipperTenderingConfig.sortOffersBy ? this.shipperTenderingConfig.sortOffersBy : null,
        maximumOffers: this.shipperTenderingConfig.maximumOffers ? this.shipperTenderingConfig.maximumOffers :
          ConfigurationConstants.DEFAULT_TENDERING_OFFERS,
        showCarrierNameInOffers: this.showCarrierNameInOffers,
        maximumShipmentsPerCarrier: this.shipperTenderingConfig.maximumShipmentsPerCarrier ?
          this.shipperTenderingConfig.maximumShipmentsPerCarrier : null,
        autoAssignBy: this.shipperTenderingConfig.autoAssignBy ? this.shipperTenderingConfig.autoAssignBy : null,
        daysRangeActive: this.shipperTenderingConfig.daysRangeActive ? this.shipperTenderingConfig.daysRangeActive : null
      };
    } else {
      this.shipperTenderingConfig = {
        assignationType: null,
        sortOffersBy: null,
        maximumOffers: null,
        showCarrierNameInOffers: null,
        maximumShipmentsPerCarrier: null,
        autoAssignBy: null,
        daysRangeActive: null
      };
    }
  }

  /**
   * @description method to add/remove a param from the rate params of shipper
   * @param {rate} parameter data about the param checked/unchecked
   * @param {MatCheckboxChange} $event event triggered when a change in the checkbox is made
   */
  public onRateSelected(parameter: Rate, $event: MatCheckboxChange): void {
    if ($event.checked) {
      const rateParam = {
        rating: 0,
        category: parameter.category,
        description: parameter.description,
        position: parameter.position
      };
      this.ratesSelected.push(rateParam);
    } else {
      const filtered = this.ratesSelected.filter(req => {
        return req.description !== parameter.description;
      });
      this.ratesSelected = filtered;
    }
    this.checkChangesInParams();
  }

  /**
   * @description validates if exists changes about the rate params selected and saved to enable the save button
   */
  public checkChangesInParams(): void {
    this.removeValidatorsAutoAssign();
    if (this.biddingTenderingFormGroup.valid) {
      this.ratesSelected.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 :
        ((b.description.toLocaleLowerCase() > a.description.toLocaleLowerCase()) ? -1 : 0));
      this.shipperRateParamsSaved.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 :
        ((b.description.toLocaleLowerCase() > a.description.toLocaleLowerCase()) ? -1 : 0));

      if (_isEqual(this.ratesSelected, this.shipperRateParamsSaved)) {
        this.disableSaveButtonParams = true;
      } else {
        this.disableSaveButtonParams = false;
      }
    }
  }

  /**
   * @description Initialize forms
   * @param fb The form builder to associated
   */
  public initForm(fb: UntypedFormBuilder): void {
    this.apportionmentFormGroup = fb.group({
      serviceType: new UntypedFormControl(0),
      boxesPercentage: new UntypedFormControl(0),
      distancePercentage: new UntypedFormControl(0),
      palletsPercentage: new UntypedFormControl(0),
      piecesPercentage: new UntypedFormControl(0),
      serviceTypePercentage: new UntypedFormControl(0),
      volumePercentage: new UntypedFormControl(0),
      weightPercentage: new UntypedFormControl(0)
    });

    this.withholdingsFormGroup = fb.group({
      freightsPercentage: new UntypedFormControl(0.1),
      maneuversPercentage: new UntypedFormControl(0)
    });
    this.cedisAccessFormGroup = fb.group({
      earlyArrivalHours: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(120), Validators.maxLength(3)]),
      earlyArrivalMinutes: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(59), Validators.maxLength(2)]),
      lateArrivalHours: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(120), Validators.maxLength(3)]),
      lateArrivalMinutes: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(59), Validators.maxLength(2)])
    });
  }

  /**
   * @description Initialize forms for stamp provider
   * @param fb The form builder to associated
   */
  private initFormStampProvider(fb: UntypedFormBuilder): void {
    this.stampProviderFormGroup = fb.group({
      stampProvider: new UntypedFormControl(null)
    });
    this.changesInStampProviderFormGroup();
  }

  /**
   * @description Initialize forms for config shipment order
   * @param fb The form builder to associated
   */
  private initFormconfigShipmentOrder(fb: UntypedFormBuilder): void {
    this.configShipmentOrderFormGroup = fb.group({
      accountIds: new UntypedFormControl().disable()
    });
  }

  /**
   * @description sets the formGroup of tendering config to display the existing information or just initialize the formGroup
   * to can create a new tendering configuration
   */
  public initTenderingForm(): void {
    this.biddingTenderingFormGroup = this.builder.group({
      assignationType: new UntypedFormControl(this.shipperTenderingConfig.assignationType, Validators.required),
      sortOffersBy: new UntypedFormControl(this.shipperTenderingConfig.sortOffersBy),
      maximumOffers: new UntypedFormControl(this.shipperTenderingConfig.maximumOffers),
      showCarrierNameInOffers: new UntypedFormControl(this.showCarrierNameInOffers),
      maximumShipmentsPerCarrier: new UntypedFormControl(this.shipperTenderingConfig.maximumShipmentsPerCarrier,
        Validators.pattern(GenericRegexp.NON_DECIMAL_ALLOWED)),
      autoAssignBy: new UntypedFormControl(this.shipperTenderingConfig.autoAssignBy),
      daysRangeActive: new UntypedFormControl(this.shipperTenderingConfig.daysRangeActive),
    });
    this.biddingTenderingFormGroup.valueChanges.subscribe(() => {
      this.validateChangesInTenderingForm();
    });
    this.onSelection();
    this.biddingTenderingFormGroup.get('assignationType').valueChanges.subscribe((value) => {
      switch (value) {
        case ConfigurationConstants.MANUAL_TENDERING_ASSIGNATION:
          this.biddingTenderingFormGroup.get('sortOffersBy').enable();
          this.biddingTenderingFormGroup.get('maximumOffers').enable();
          this.biddingTenderingFormGroup.get('maximumShipmentsPerCarrier').enable();
          this.biddingTenderingFormGroup.get('sortOffersBy').setValidators(Validators.required);
          this.biddingTenderingFormGroup.get('maximumOffers').setValidators(Validators.required);
          this.biddingTenderingFormGroup.get('autoAssignBy').disable();
          this.biddingTenderingFormGroup.get('daysRangeActive').disable();
          break;
        case ConfigurationConstants.AUTOMATIC_TENDERING_ASSIGNATION:
          this.biddingTenderingFormGroup.get('autoAssignBy').enable();
          this.biddingTenderingFormGroup.get('daysRangeActive').enable();
          this.biddingTenderingFormGroup.get('sortOffersBy').disable();
          this.biddingTenderingFormGroup.get('maximumOffers').disable();
          this.biddingTenderingFormGroup.get('maximumShipmentsPerCarrier').disable();
          this.biddingTenderingFormGroup.get('autoAssignBy').setValidators(Validators.required);
          break;
      }
      this.biddingTenderingFormGroup.controls['sortOffersBy'].updateValueAndValidity();
      this.biddingTenderingFormGroup.controls['maximumOffers'].updateValueAndValidity();
      this.biddingTenderingFormGroup.controls['maximumShipmentsPerCarrier'].updateValueAndValidity();
      this.biddingTenderingFormGroup.controls['autoAssignBy'].updateValueAndValidity();
    });
  }

  /**
   * @description method to update the status for the toggle to display carrier names and set it to the formGroup
   * @param {boolean} showCarrierNameStatus status of flag to set true/false the option to display the carrierGroup name in offers
   */
  public showCarrierNameToggle(showCarrierNameStatus: boolean): void {
    if (showCarrierNameStatus) {
      this.showCarrierNameInOffers = true;
    } else {
      this.showCarrierNameInOffers = false;
    }
    this.biddingTenderingFormGroup.get('showCarrierNameInOffers').setValue(this.showCarrierNameInOffers);
  }

  /**
   * @description checks if exists any change in data saved of tendering config data with actual tendering config data in formGroup
   */
  public validateChangesInTenderingForm(): void {
    const tenderingConfigForm = this.biddingTenderingFormGroup.getRawValue();
    if (tenderingConfigForm.assignationType === MANUAL_ASSIGNMENT) {
      this.removeValidatorsAutoAssign();
      if (this.shipperTenderingConfig.sortOffersBy === tenderingConfigForm.sortOffersBy && this.shipperTenderingConfig.maximumOffers ===
        tenderingConfigForm.maximumOffers && this.shipperTenderingConfig.maximumShipmentsPerCarrier ===
        tenderingConfigForm.maximumShipmentsPerCarrier && this.shipperTenderingConfig.showCarrierNameInOffers
        === tenderingConfigForm.showCarrierNameInOffers) {
          this.disableSaveButton = true;
      } else {
          this.disableSaveButton = false;
      }
    } else if (tenderingConfigForm.assignationType === AUTO_ASSIGNMENT) {
      if (this.shipperTenderingConfig.autoAssignBy === tenderingConfigForm.autoAssignBy && this.shipperTenderingConfig.daysRangeActive ===
          this.verifyDaysRangeActive(tenderingConfigForm.daysRangeActive)) {
          this.disableSaveButton = true;
      } else {
          this.disableSaveButton = false;
      }
    }
  }

  /**
   * @description Remove validators for auto assign field
   */
  public removeValidatorsAutoAssign(): void {
    if (!this.biddingTenderingFormGroup.get('autoAssignBy').value &&
      this.biddingTenderingFormGroup.value.assignationType === MANUAL_ASSIGNMENT) {
      this.biddingTenderingFormGroup.get('autoAssignBy').setErrors(null);
      this.biddingTenderingFormGroup.get('autoAssignBy').setValidators(null);
    }
  }

  /**
   * @description Checks if exists days range active params and return a valid value
   * @param {string} daysRangeActive Days range active from tendering settings
   * @returns {number} Days range active in type number
   */
  public verifyDaysRangeActive(daysRangeActive: string): number {
    const radix = 10;
    if (daysRangeActive) {
      return parseInt(daysRangeActive, radix);
    } else {
      return null;
    }
  }

  /**
   * @description Do the user only enters numbers on the inputs
   * @param {KeyboardEvent} event Detects input modifications on specific event
   */
  public onKeyPressCode(event: KeyboardEvent): void {
    const input = event.key;
    if (!GenericRegexp.ONLY_NUMBERS.test(input)) {
      event.preventDefault();
    }
  }

  /**
   * @description Save changes on shipment configurations section
   */
  public async onSaveShipmentConfig(): Promise<void> {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM) {
        this.toast.processingAlert();
        try {
          const shipmentUndeliveredOrdersStatus = this.buildShipmentUndeliveredOrdersStatus();
          let isConfigUpdated: boolean;
          await this.configurationProvider.setShipUndeliveredOrdersStatus(
            this.appService.getShipperOid(),
            shipmentUndeliveredOrdersStatus
          ).then(async (response) => {
            isConfigUpdated = response;
            const logsConfigToSave = await this.getChangesLogs();
            await this.configurationProvider.saveConfigurationLogs(logsConfigToSave, this.shipperId).then(async (res) => {
              this.shipperConfig.isShipUndeliveredOrdersChecked = isConfigUpdated;
              this.shipperConfig = await this.configurationProvider.getShipperConfig();
              this.toast.closeProcessing();
              this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
              this.isShipConfigButtonEnabled = false;
            });
          });
        } catch (error) {
          this.isShipConfigButtonEnabled = false;
          this.toast.closeProcessing();
        }
      }
    });
  }

  /**
   * @description Build the object 'ShipmentUndeliveredOrdersStatus' to send in the request
   * @returns {ShipmentUndeliveredOrdersStatus} object type ShipmentUndeliveredOrdersStatus
   */
  private buildShipmentUndeliveredOrdersStatus(): ShipmentUndeliveredOrdersStatus {
    const shipmentUndeliveredOrdersStatus: ShipmentUndeliveredOrdersStatus = {
      shipUndeliveredOrders: this.shipUndeliveredOrders,
      isActivateUseUccChecked: this.isActivateUseUccChecked,
      isAppointmentOrdersRequired: this.isAppointmentOrdersRequired,
      isActivateDetentionChecked: this.isActivateDetentionChecked,
      canReshipRejectedOrders: this.shipRejectedOrders,
      accountsAppointmentOrdersRequired: this.getAccountSelected()
    };

    return shipmentUndeliveredOrdersStatus;
  }

  /**
   * @description Change the value in ship costing configurations
   */
  public onChangeIsFreightCostRequiredToConfirmShipments(): void {
    this.isFreightCostRequiredToConfirmShipments = !(!this.isFreightCostRequiredToConfirmShipments);
    this.costConfigurationsHasChanges();
  }

  /**
   * @description Updates status of configuration to check charges/discounts.
   * Also calls method that checks changes in configuration from costing section
   */
  public onCheckChargesAndDiscounts(): void {
    this.isCheckChargesAndDiscountsEnabled  = !!this.isCheckChargesAndDiscountsEnabled;
    this.costConfigurationsHasChanges();
  }

  /**
   * @description Updates the shipper's payment type configuration
   */
  public onChangePaymentType(): void {
    this.isPaymentTypeRequired = !!this.isPaymentTypeRequired;
    this.costConfigurationsHasChanges();
  }

  /**
   * @description Updates the shipper's documents settings.
   */
  public onDocumentsSettings(): void {
    if (this.shipperConfig?.documentsSettings?.isLoadPlanByProductsActive !== this.isLoadPlanByProductsActive) {
      this.isDocumentsSeetingsButtonEnabled = true;
    } else {
      this.isDocumentsSeetingsButtonEnabled = false;
    }
  }

  /**
   * @description Allows to ingress only integer numbers.
   * @param {KeyboardEvent} event - Value of the key pressed by the user.
   */
  public allowIntegerNumbersOnly(event: KeyboardEvent): void {
    if (ConfigurationConstants.NO_NUMERICAL_INTEGER_CHARACTERS.includes(event.key)) {
        event.preventDefault();
    }
  }

  /**
   * @description Action when a input of percentage from costing configuration changes.
   */
  public onPercentageInputChanges(): void {
    this.costConfigurationsHasChanges();
  }

  /**
   * @description Verifies whether all percentages fields are in the valid range.
   * @returns {boolean} - Returns whether all percentages fields are in the valid range.
   */
  public isEveryPercentageFieldValid(): boolean {
    if ((this.percentageFiscalBorderStrip >= 0 && this.percentageFiscalBorderStrip <= ConfigurationConstants.MAX_QUANTITY_PERCENTAGE) &&
        (this.percentageFreightRetention >= 0 && this.percentageFreightRetention <= ConfigurationConstants.MAX_QUANTITY_PERCENTAGE) &&
        (this.percentageISR >= 0 && this.percentageISR <= ConfigurationConstants.MAX_QUANTITY_PERCENTAGE) && 
        (this.percentageIVA >= 0 && this.percentageIVA <= ConfigurationConstants.MAX_QUANTITY_PERCENTAGE) &&
        (this.percentageManeuverRetention >= 0 && this.percentageManeuverRetention <= ConfigurationConstants.MAX_QUANTITY_PERCENTAGE)) {
      return true;
    }

    return false;
  }

  /**
   * @description Verifies whether any percentage field has changed.
   * @returns {boolean} - Returns whether any percentage field has changed.
   */
  public hasAnyPercentageFieldChanges(): boolean {
    if (this.shipperConfig.percentageFiscalBorderStrip !== this.percentageFiscalBorderStrip ||
        this.shipperConfig.percentageFreightRetention !== this.percentageFreightRetention ||
        this.shipperConfig.percentageISR !== this.percentageISR ||
        this.shipperConfig.percentageIVA !== this.percentageIVA ||
        this.shipperConfig.percentageManeuverRetention !== this.percentageManeuverRetention) {
       return true;
     }

    return false;
 }

  /**
   * @description Detect if there are changes in costing configuration to enable/disable save button.
   */
  public costConfigurationsHasChanges(): void {
    if ((this.shipperConfig.isFreightCostRequiredToConfirmShipments !== this.isFreightCostRequiredToConfirmShipments ||
        this.shipperConfig.isCheckChargesAndDiscountsEnabled !== this.isCheckChargesAndDiscountsEnabled ||
        this.shipperConfig.isPaymentTypeRequired !== this.isPaymentTypeRequired || this.hasAnyPercentageFieldChanges()) &&
        this.isEveryPercentageFieldValid()) {
      this.isCostConfigButtonEnabled = true;
    } else {
      this.isCostConfigButtonEnabled = false;
    }
  }

  /**
   * @description opens a dialog to confirm data saving,
   * calls configuration provider to update the shipper configurations
   * and configuration log
   */
  public onSaveCostConfig(): void {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: CONFIG_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM) {
        await this.saveCostingConfig();
      }
    });
  }

  /**
   * @description Opens a dialog to update the shipper's documents settings.
   */
  public onSaveDocumentsSettings(): void {
     const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        iconPath: CONFIG_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async(result: string) => {
      if (result === CONFIRM) {
        await this.saveDocumentsSettings();
      }
    });
  }

  /**
   * @description Sets zero to every percentage that has a null value.
   */
  private setPercentagesDefaultValue(): void {
    this.percentageFiscalBorderStrip = this.percentageFiscalBorderStrip !== null ? this.percentageFiscalBorderStrip : 0;
    this.percentageFreightRetention = this.percentageFreightRetention !== null ? this.percentageFreightRetention : 0;
    this.percentageISR = this.percentageISR !== null ? this.percentageISR : 0;
    this.percentageIVA = this.percentageIVA !== null ? this.percentageIVA : 0;
    this.percentageManeuverRetention = this.percentageManeuverRetention !== null ? this.percentageManeuverRetention : 0;
  }

  /**
   * @description Updates costing config. Additionally creates a log for configurations modified.
   */
  private async saveCostingConfig(): Promise<void> {
    try {
      this.toast.processingAlert();
      this.isCostConfigButtonEnabled = false;
      this.setPercentagesDefaultValue();
      const costingConfiguration: CostingConfigurationBody = {
        isCheckChargesAndDiscountsEnabled: this.isCheckChargesAndDiscountsEnabled,
        isFreightCostRequiredToConfirmShipments: this.isFreightCostRequiredToConfirmShipments,
        isPaymentTypeRequired: this.isPaymentTypeRequired,
        percentageFiscalBorderStrip: this.percentageFiscalBorderStrip,
        percentageFreightRetention: this.percentageFreightRetention,
        percentageISR: this.percentageISR,
        percentageIVA: this.percentageIVA,
        percentageManeuverRetention: this.percentageManeuverRetention
      };
      await this.configurationProvider.saveCostingConfiguration(this.shipperId, costingConfiguration);
      const logsConfigToSave = await this.getChangesLogs();
      await this.configurationProvider.saveConfigurationLogs(logsConfigToSave, this.shipperId);
      this.shipperConfig = await this.configurationProvider.getShipperConfig();
      this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
      this.isCostConfigButtonEnabled = false;
    } catch (error) {
      this.toast.errorAlert(ConfigurationLabels.errorToSaveCostConfig);
      this.isCostConfigButtonEnabled = true;
    } finally {
      this.toast.closeProcessing();
    }
  }

  /**
   * @description Updates costing config. Additionally creates a log for configurations modified.
   */
  private async saveDocumentsSettings(): Promise<void> {
    try {
      this.toast.processingAlert();
      this.isDocumentsSeetingsButtonEnabled = false;
      const documentsSettings: DocumentsSettings = { isLoadPlanByProductsActive: this.isLoadPlanByProductsActive };
      await this.configurationProvider.saveDocumentsSettings(this.shipperId, documentsSettings);
      const logsConfigToSave = await this.getChangesLogs();
      await this.configurationProvider.saveConfigurationLogs(logsConfigToSave, this.shipperId);
      this.shipperConfig = await this.configurationProvider.getShipperConfig();
      this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
      this.isDocumentsSeetingsButtonEnabled = false;
    } catch (error) {
      this.toast.errorAlert(ConfigurationLabels.errorToSaveCostConfig);
      this.isDocumentsSeetingsButtonEnabled = true;
    } finally {
      this.toast.closeProcessing();
    }
  }

  /**
   * @description Change the value in ship undelivered orders
   */
  public onChangesShipUndeliveredOrders() {
    this.shipUndeliveredOrders = !(!this.shipUndeliveredOrders);
    this.isChanged();
  }

  /**
   * @description Change the value in stop orders
   */
  public onChangesActivateDetention() {
    this.isActivateDetentionChecked = !(!this.isActivateDetentionChecked);
    this.isChanged();
  }

  /**
   * @description Change the value in use of UCCs for orders
   */
  public onChangesActivateUseUcc() {
    this.isActivateUseUccChecked = !(!this.isActivateUseUccChecked);
    this.isChanged();
  }

  /**
   * @description Changes the value of Appointment Orders required boolean
   */
  public onChangeAppointmentOrdersRequired() {
    this.isAppointmentOrdersRequired = !(!this.isAppointmentOrdersRequired);
    this.isChanged();
    this.updateValidationsForm(this.isAppointmentOrdersRequired);
  }

  /**
   * @description remove or set the validation to form configShipmentOrderFormGroup dynamically
   */
  private updateValidationsForm(value: boolean): void {
    if (value) {
      this.configShipmentOrderFormGroup.get(KEYS_ACCOUNT_IDS).enable();
      this.configShipmentOrderFormGroup.get(KEYS_ACCOUNT_IDS).setValidators([Validators.required]);
      this.configShipmentOrderFormGroup.get(KEYS_ACCOUNT_IDS).markAsTouched();
      this.setDefaultAccounts(this.shipperConfig.accountsAppointmentOrdersRequired);
    } else {
      this.configShipmentOrderFormGroup.get(KEYS_ACCOUNT_IDS).clearValidators();
      this.configShipmentOrderFormGroup.get(KEYS_ACCOUNT_IDS).disable();
      this.setDefaultAccounts([]);
    }
    this.configShipmentOrderFormGroup.get(KEYS_ACCOUNT_IDS).updateValueAndValidity();
  }

  /**
   * @description Changes the value of transport dates required boolean
   */
  public onChangeTransportDatesRequired() {
    this.isTransportDatesRequired = !(!this.isTransportDatesRequired);
    this.onChangesFieldsSettings();
  }

  /**
   * @description Change the value in ship rejected orders
   */
  public onChangesShipRejectedOrders() {
    this.shipRejectedOrders = !(!this.shipRejectedOrders);
    this.isChanged();
  }

  /**
   * @description Detect if there are changes in shimpents configs and if de changes are reverted de button is disabled
   */
  public isChanged() {
    if (
      this.shipperConfig.isShipUndeliveredOrdersChecked !== this.shipUndeliveredOrders ||
      this.shipperConfig.isActivateDetentionChecked !== this.isActivateDetentionChecked ||
      this.shipperConfig.isActivateUseUccChecked !== this.isActivateUseUccChecked ||
      this.shipperConfig.isAppointmentOrdersRequired !== this.isAppointmentOrdersRequired ||
      this.shipperConfig.canReshipRejectedOrders !== this.shipRejectedOrders ||
      JSON.stringify(this.configShipmentOrderFormGroup.controls.accountIds.value) !==
      JSON.stringify(this.shipperConfig.accountsAppointmentOrdersRequired)
    ) {
      this.isShipConfigButtonEnabled = true;
    } else {
      this.isShipConfigButtonEnabled = false;
    }
  }

  public onRegisterTenant(): void {
    const auxObj = {
      useTripType: 1,
      useCollectionType: 1,
      useCarrier: 1,
      useTruckType: 1,
      useForeign: 1,
      useModality: 1,
      apportionmentSetting: [
        {
          paymentUnitId: 1,
          percentage: '100'
        }
      ]
    };
    this.configurationProvider.createCostApiSettings(this.tenantId, auxObj)
      .then(res => {
        this.toast.successAlert(ConfigurationLabels.successTenant);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/configuration']));
      })
      .catch(error => {
        this.toast.errorAlert(ConfigurationLabels.errorTenant);
        this.tenantExists = false;
      });
  }

  public onAddNewCharge(): void {
    const charge = this.newCharge.trim();
    if (charge !== EMPTY_STRING && charge !== undefined) {
      if (this.chargeExists(charge)) {
        this.toast.warningAlert(ConfigurationLabels.errorChargeExist);
        return;
      } else {
        this.chargesToAdd.push(this.newCharge);
        const auxCharge: Charge = {
          id: -1,
          chargeName: charge,
          tenantId: TENANT_AUX
        };
        this.charges.push(auxCharge);
        this.dataSource = new MatTableDataSource(this.charges);
        this.newCharge = EMPTY_STRING;
        this.saveAvailable = true;
      }
    } else {
      this.toast.warningAlert(ConfigurationLabels.insertChargeName);
    }
  }

  public chargeExists(charge: string): boolean {
    if (this.charges.length < 1) {
      return false;
    }
    const chargesDuplicated = this.charges.filter(elem => elem.chargeName === charge);
    return chargesDuplicated.length > 0 ? true : false;
  }

  public onSaveChanges(): void {
    const newCharges = {
      Charges: []
    };
    for (let i = 0; i < this.chargesToAdd.length; i++) {
      const chargeAux = {
        chargeName: this.chargesToAdd[i]
      };
      newCharges.Charges.push(chargeAux);
    }

    this.configurationProvider.createCharges(this.tenantId, newCharges)
      .then(res => {
        this.toast.successAlert(ConfigurationLabels.successCharges);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/configuration']));
      })
      .catch(error => {
        this.toast.errorAlert(ConfigurationLabels.errorChargeSave);
      });
  }

  public async getUserWarehouses() {
    const user = await this.userProvider.getUserById(this.userOid);
    if (!_.isEmpty(user.warehouses)) {
      this.warehouseList = user.warehouses;
      this.defaultWarehouse = user.warehouseDefault;
      this.noWarehouses = false;
    } else {
      this.noWarehouses = true;
    }
  }

  public onChangeWarehouse(): void {
    if (this.warehouseList && this.warehouseList.length > 0) {
      const dialogRef = this.dialog.open(DialogSelectWarehouseComponent, {
        data: {
          title: ConfigurationLabels.dialogWarehouseTitle,
          resume: ConfigurationLabels.dialogWarehouseResume,
          imgRef: '../../../assets/warehouse_switch.svg',
          button1: ConfigurationLabels.dialogWarehouseCancel,
          button2: ConfigurationLabels.dialogWarehouseAccept,
          warehouses: this.warehouseList,
          defaultWarehouse: this.defaultWarehouse,
          userId: this.userOid
        },
        width: DIALOG_WIDTH,
        height: DIALOG_HEIGHT
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === CONFIRM) {
          location.reload();
        }
      });
    }
  }

  public checkProfilePermission(): void {
    this.userIsAdmin = ADMIN_PROFILES.includes(this.userProfile);
    return;
  }

  /**
   * @description Separate all accounts with and without POD configurations
   * @param {AccountResponse} accountsRes All accounts for this shipper
   **/
  public separateAccountsWithPOD(accountsRes: AccountResponse): void {
    const accountsNames: AccountResponse = accountsRes;
    const accountsWithPOD: AccountWithPod[] = [];
    const accountWithoutPOD: Account[] = [];
    if (accountsNames) {
      accountsNames.cuentas.forEach(cuenta => {
        const accountAux = {
          _id: cuenta._id,
          name: cuenta.nombre,
          prefix: (cuenta.prefix) ? cuenta.prefix : EMPTY_STRING,
          recurrence: {},
          periodicity: EMPTY_STRING,
        };
        if (this.podAccountsArray.includes(cuenta._id)) {
          this.podListResponse.forEach(podConfig => {
            if (cuenta._id === podConfig.name && podConfig.data.recurrence) {
              accountAux.recurrence = podConfig.data.recurrence;
              accountAux.periodicity = this.getPeriodicityEquivalent(podConfig.data.recurrence.frequency);
            }
          });
          accountsWithPOD.push(accountAux);
        } else {
          accountWithoutPOD.push(accountAux);
        }
      });
      this.availableAccounts = accountWithoutPOD;
      this.accountsWithPOD = accountsWithPOD;
    }
  }

  /**
   * @description Get periodicity equivalents in spanish
   * @returns {string} Selected periodicity in spanish
   **/
  public getPeriodicityEquivalent(periodicity: string): string {
    switch (periodicity) {
      case DAY:
        return DAILY;
      case WEEK:
        return WEEKLY;
      case MONTH:
        return MONTHLY;
      default:
        return ConfigurationLabels.withoutInformation;
    }
  }

  /**
   * @description Get periodicity for scpecific account
   * @param {Object} podList Response from all information stored in Azure Logic Apps
   * @returns {Array} Array of accounts with POD
   **/
  public getAllAcountsWithPod(podList: any): string[] {
    const nameArray = [];
    if (podList.value) {
      podList.value.forEach(cuenta => {
        nameArray.push(cuenta.name);
      });
    }
    return nameArray;
  }

  /**
   * @description Get recurrence info for each pod in list
   * @param {Object} podList Response from all information stored in Azure Logic Apps
   * @returns {RecurrenceFromAzure[]}
   **/
  public getPodRecurrenceInfo(podList: any): RecurrenceFromAzure[] {
    const podListResponse = [];
    if (podList.value) {
      podList.value.forEach(cuenta => {
        const recurrence = {};
        recurrence[NAME] = cuenta.name;
        recurrence[DATA] = cuenta.properties.definition.triggers.Recurrence;
        podListResponse.push(recurrence);
      });
    }
    return podListResponse;
  }

  /**
   * @description Add pod configuration to account button
   **/
  public podFormToggle(): void {
    this.recurrence = undefined;
    this.addAccountPodEnable = !this.addAccountPodEnable;
  }

  /**
   * @description Calls delete POD
   * @params POD: Account with POD config to be removed
   */
  public async onDeletePod(row: AccountWithPod): Promise<void> {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogDeleteCancel,
        textButton2: ConfigurationLabels.dialogDeleteAccept,
        focusText: ConfigurationLabels.dialogDefaultFocusText,
        iconPath: '../../../assets/eliminate.svg',
        question: ConfigurationLabels.dialogDefaultQuestion,
        resume: ConfigurationLabels.dialogDeleteResumeBegin + row.name + ConfigurationLabels.dialogDeleteResumeEnd,
        title: ConfigurationLabels.dialogDeleteTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === CONFIRM) {
        this.toast.processingAlert();
        await this.serverlessProvider.podConfiguration({ delete: { id: row._id } })
          .then(() => {
            this.serverlessProvider.podConfiguration({ list: [] }).then(res => {
              this.toast.closeProcessing();
              this.podAccountsArray = this.getAllAcountsWithPod(res);
              this.podListResponse = this.getPodRecurrenceInfo(res);
              this.separateAccountsWithPOD(this.allAccounts);
              this.podDataSource = new MatTableDataSource(this.accountsWithPOD);
              this.cancelEvent();
              this.toast.successAlert(ConfigurationLabels.toastSuccessDelete);
            });
          })
          .catch(err => {
            this.toast.closeProcessing();
            this.toast.errorAlert(ConfigurationLabels.toastErrorDelete);
          });
      }
    });
  }

  /**
   * @description Validates form before save
   * @params formValue: new POD configuration
   */
  public async onCreatePod(formValue): Promise<void> {
    this.toast.processingAlert();
    if (this.editingPod) {
      await this.serverlessProvider.podConfiguration({ delete: { id: formValue.accountId } });
    }
    const POD_BODY = {
      accountId: formValue.accountId,
      recurrence: formValue,
      action: {
        method: POST,
        body: {
          accountOId: formValue.accountId,
          accountName: formValue.accountName
        },
        uri: SERVERLESS_URL + '/podReport'
      }
    };
    await this.serverlessProvider.podConfiguration({ create: POD_BODY }).then(status => {
      this.serverlessProvider.podConfiguration({ list: [] }).then(res => {
        this.toast.closeProcessing();
        this.podAccountsArray = this.getAllAcountsWithPod(res);
        this.podListResponse = this.getPodRecurrenceInfo(res);
        this.separateAccountsWithPOD(this.allAccounts);
        this.podDataSource = new MatTableDataSource(this.accountsWithPOD);
        this.cancelEvent();
        this.toast.successAlert(ConfigurationLabels.toastSuccessCreate);
      });
    })
      .catch(error => {
        this.toast.closeProcessing();
        this.toast.errorAlert(ConfigurationLabels.toastErrorCreate);
      });
  }

  /**
   * @description Validates form before save
   * @param {RecurrenceForm} formValue: new POD configuration
   */
  public async onEditPod(formValue: RecurrenceForm): Promise<void> {
    this.editingPod = true;
    this.recurrence = {
      accountId: formValue._id,
      accountName: formValue.name,
      frequency: formValue.recurrence.frequency,
      interval: 1,
      schedule: {
        weekDays: formValue.recurrence.schedule.weekDays,
        hours: formValue.recurrence.schedule.hours,
        minutes: formValue.recurrence.schedule.minutes
      }
    };
    this.addAccountPodEnable = true;
  }

  /**
   * @description On cancel cleans and hides form
   */
  public cancelEvent(): void {
    this.addAccountPodEnable = false;
    this.editingPod = false;
    this.recurrence = undefined;
  }

  /**
   * @description Gets the shipper config
   */
  public async getShipperConfig(): Promise<void> {
    this.shipperConfig = await this.configurationProvider.getShipperConfig();
    this.getPublicAccessAccountsSaved(this.shipperConfig);
    this.getFieldsSettings(this.shipperConfig);
    if (this.shipperConfig.logoUrl && this.shipperConfig.logoUrl !== EMPTY_STRING) {
      await this.getShipperLogoFromBlobStorage([this.shipperConfig.logoUrl]);
    }

    if (this.shipperConfig.withholdings.freightsPercentage) {
      const freightsValue: number = this.shipperConfig.withholdings.freightsPercentage;
      const maneuversValue: number = this.shipperConfig.withholdings.maneuversPercentage;
      await this.setShipperWithholdings(freightsValue, maneuversValue);
    }

    if (this.shipperConfig.cedisAccess.lastModifiedDate) {
      const earlyHourValue: number = this.shipperConfig.cedisAccess.earlyArrivalHours;
      const earlyMinValue: number = this.shipperConfig.cedisAccess.earlyArrivalMinutes;
      const lateHourValue: number = this.shipperConfig.cedisAccess.lateArrivalHours;
      const lateMinValue: number = this.shipperConfig.cedisAccess.lateArrivalMinutes;
      this.isCedisConfigEnable = this.shipperConfig.cedisAccess.isCedisConfigEnable;
      await this.setShipperCedis(earlyHourValue, earlyMinValue, lateHourValue, lateMinValue);
    }

    if (this.shipperConfig.driverConfig && this.shipperConfig.driverConfig.maxDriverInactiveTime) {
      this.maxDriverInactiveTime = this.shipperConfig.driverConfig.maxDriverInactiveTime;
    } else {
      this.maxDriverInactiveTime = this.defaultMaxDriverInactiveTime;
    }

    this.onActivateCedisAccess(this.isCedisConfigEnable);
  }

  /**
   * @description Gets fields settings saved
   * @param {ShipperConfiguration} shipperSettings Current shipper configuration recovered
   */
  private getFieldsSettings(shipperSettings: ShipperConfiguration): void {
    if (shipperSettings && shipperSettings.fieldsSettings) {
      this.neccessaryRFCForLocations = shipperSettings.fieldsSettings.locationRFCRequired;
      this.isTransportDatesRequired = shipperSettings.fieldsSettings.transportDatesRequired;
    } else if (shipperSettings && !shipperSettings.fieldsSettings) {
      this.neccessaryRFCForLocations = false;
      this.isTransportDatesRequired = false;
    }
  }

  /**
   * @description validates in shipper's configuration if exists accounts of public access to preload that accounts info
   * @param {ShipperConfiguration} shipperConfig configuration retrieved of shipper
   */
  public getPublicAccessAccountsSaved(shipperConfig: ShipperConfiguration): void {
    if (shipperConfig.publicAccessAccounts && shipperConfig.publicAccessAccounts.length) {
      this.publicAccessAccountsSaved = shipperConfig.publicAccessAccounts;
      this.showOrdersInPublicPortal = true;
    } else {
      this.publicAccessAccountsSaved = null;
      this.showOrdersInPublicPortal = false;
    }
  }

  /**
   * @description Set the shipper withholdings configuration in the form.
   * @param {number} freightsValue Freights percentage value
   * @param {number} maneuversValue Maneuvers percentage value
   */
  public async setShipperWithholdings(freightsValue: number, maneuversValue: number): Promise<void> {
    this.withholdingsFormGroup.setValue({
      freightsPercentage: freightsValue,
      maneuversPercentage: maneuversValue
    });
  }

  /**
   * @description Gets the shipper current logo if so.
   * @param {string[]} shipperLogo Current shipper's logo
   */
  public async getShipperLogoFromBlobStorage(shipperLogo: string[]): Promise<void> {
    const shipperLogos = [];
    for (const image of shipperLogo) {
      shipperLogos.push(BLOB_STORAGE_URL + image);
    }
    await this.fileConversorService.fileConversor(shipperLogos, false)
      .then((filesToShow: FilesToShow[]) => {
        this.imagesConverted = filesToShow;
      });
  }

  /**
   * @description Gets all files from prompt
   */
  public selectFiles(): void {
    this.filesAddedByButton = undefined;
    const filesByButton = document.getElementById(INPUT_FILES_KEY);
    this.filesAddedByButton = filesByButton[FILES_KEY];
  }

  /**
   * @description Triggers the prompt file selection
   */
  public openFileSelector(): void {
    document.getElementById(INPUT_FILES_KEY).click();
  }

  /**
   * @description Handles the draganddrop component output event
   * @param {Array<DragAndDropFile>} addedFiles Selected files
   */
  public async addedFilesHandler(addedFiles: Array<DragAndDropFile>): Promise<void> {
    if (addedFiles && addedFiles.length > 0) {
      this.enableSaveLogo = true;
    } else if (this.shipperConfig.logoUrl
      && this.shipperConfig.logoUrl !== EMPTY_STRING
      && addedFiles.length === 0) {
      this.enableSaveLogo = true;
    } else {
      this.enableSaveLogo = false;
    }
    const inputValue = (<HTMLInputElement>document.getElementById(INPUT_FILES_KEY));
    inputValue.value = null;
  }

  /**
   * @description Creates Sas Url, blobContainer and save the file
   * @param {File} file File to be saved
   * @param {string} extension Extension of the file
   * @returns {Promise<string>} Created blob name
   */
  public async createFile(file: File, extension: string): Promise<string> {
    let sasUrl: string;
    let blobName: string;
    let MIMEType: string;

    const resultSAS = await this.trackingProvider.generateSAS(SPLIT_TOKEN_EXTENSION + extension.toLowerCase());
    sasUrl = resultSAS[KEY_SASURL];
    blobName = resultSAS[KEY_BLOBNAME];
    MIMEType = await this.blobProvider.getMIMEtype(extension.toLowerCase());
    const blob = new Blob([file], { type: MIMEType });
    await this.blobProvider.sendBlob(sasUrl, blob).catch(() => false);
    return blobName;
  }

  /**
   * @description Handles logo save action
   */
  public async onSaveLogo(): Promise<void> {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM) {
        this.toast.processingAlert();
        try {
          let logoUrl = EMPTY_STRING;
          if (!_.isEmpty(this.imagesConverted)) {
            const firstFile = _.first(this.imagesConverted);
            const extension: Array<string> = firstFile.name.split(SPLIT_TOKEN_EXTENSION);
            const createdFileBlobName = await this.createFile(firstFile.file, _.last(extension));
            logoUrl = createdFileBlobName;
          }
          const updatedLogoUrl = await this.configurationProvider.setShipperLogo(logoUrl);
          this.shipperConfig.logoUrl = updatedLogoUrl;
          this.enableSaveLogo = false;
          this.toast.closeProcessing();
          this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
        } catch (error) {
          this.enableSaveLogo = false;
          this.toast.closeProcessing();
        }
      }
    });
  }

  /**
   * @description validates the option selected in assignType for tendering, to enable/disable inputs
   * @param {MatRadioChange} event the event trigered when the function is called from radio button a
   * change is made with the value of the radio button selected
   */
  public onSelection(event?: MatRadioChange): void {
    if (event && event.value === ConfigurationConstants.MANUAL_TENDERING_ASSIGNATION) {
      this.biddingTenderingFormGroup.get('assignationType').setValue(ConfigurationConstants.MANUAL_TENDERING_ASSIGNATION);
      this.isTenderingManualAssignation = true;
      this.isTenderingAutomaticAssignation = false;
    } else if (event && event.value === ConfigurationConstants.AUTOMATIC_TENDERING_ASSIGNATION) {
      this.biddingTenderingFormGroup.get('assignationType').setValue(ConfigurationConstants.AUTOMATIC_TENDERING_ASSIGNATION);
      this.showCarrierNameInOffers = false;
      this.isTenderingAutomaticAssignation = true;
      this.isTenderingManualAssignation = false;
    } else {
      const assignationType = this.biddingTenderingFormGroup.get('assignationType').value;
      if (assignationType === ConfigurationConstants.MANUAL_TENDERING_ASSIGNATION) {
        this.isTenderingManualAssignation = true;
        this.isTenderingAutomaticAssignation = false;
        this.biddingTenderingFormGroup.get('assignationType').setValue(ConfigurationConstants.MANUAL_TENDERING_ASSIGNATION);
      } else {
        this.isTenderingManualAssignation = false;
        this.isTenderingAutomaticAssignation = true;
        this.biddingTenderingFormGroup.get('assignationType').setValue(ConfigurationConstants.AUTOMATIC_TENDERING_ASSIGNATION);
      }
    }
  }

  /**
   * @description displays a modal to confirm the creation/update of tendering config
   */
  public confirmSaveTenderingConfig(): void {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        title: ConfigurationLabels.titleSaveTenderingConfig,
        resume: ConfigurationLabels.resumeSaveTenderingConfig,
        iconPath: ConfigurationConstants.TENDERING_ICON,
        question: ConfigurationLabels.dialogDefaultQuestion,
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept
      },
      width: ConfigurationConstants.TENDERING_DIALOG_WIDTH
    });
    this.modalSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result === CONFIRM) {
        this.isSavingTenderingInfo = true;
        this.toast.processingAlert();
        this.saveTenderingConfig();
      }
    });
  }

  /**
   * @description generates an array object with all params selected to be saved
   * @returns {Array<RateParamsConfig>} the array with all params selected
   */
  public generateRateParams(): Array<RateParamsConfig> {
    const RatingParamsConfig = [];
    if (this.ratesSelected) {
      for (let i = 0; i < this.ratesSelected.length; i++) {
        const rateParams = {
          category: this.ratesSelected[i].category,
          description: this.ratesSelected[i].description,
          position: this.ratesSelected[i].position
        };
        RatingParamsConfig.push(rateParams);
      }
    }
    return RatingParamsConfig;
  }

  /**
   * @description gets the tendering info from the formGroup, then creates the object with the config data,
   * finally sends that info to be saved
   */
  public async saveTenderingConfig(): Promise<void> {
    const assignationType = this.biddingTenderingFormGroup.get('assignationType').value;
    const sortOffersBy = this.biddingTenderingFormGroup.get('sortOffersBy').value;
    const maximumOffers = this.biddingTenderingFormGroup.get('maximumOffers').value;
    const autoAssignBy = this.biddingTenderingFormGroup.get('autoAssignBy').value;
    const maximumShipmentsPerCarrier = this.biddingTenderingFormGroup.get('maximumShipmentsPerCarrier').value;
    const daysRangeActive = this.biddingTenderingFormGroup.get('daysRangeActive').value;
    const rateParams = this.generateRateParams();
    this.tenderingConfigBody = {
      assignationType: assignationType ? assignationType : null,
      sortOffersBy: assignationType === MANUAL_ASSIGNMENT && sortOffersBy ? sortOffersBy : null,
      maximumOffers: assignationType === MANUAL_ASSIGNMENT && maximumOffers ? maximumOffers : null,
      showCarrierNameInOffers: this.showCarrierNameInOffers,
      maximumShipmentsPerCarrier: assignationType === MANUAL_ASSIGNMENT && maximumShipmentsPerCarrier ? maximumShipmentsPerCarrier : null,
      autoAssignBy: assignationType === AUTO_ASSIGNMENT && autoAssignBy ? autoAssignBy : null,
      daysRangeActive: assignationType === AUTO_ASSIGNMENT && daysRangeActive ? daysRangeActive : null,
      rateParams: rateParams ? rateParams : null
    };
    try {
      await this.configurationProvider.postTenderingSettings(this.shipperId, this.tenderingConfigBody);
      this.toast.closeProcessing();
      this.toast.successAlert(ConfigurationLabels.toastSaveSuccessTenderingConfig);
      this.isSavingTenderingInfo = false;
      this.resetTenderingConfig(assignationType);
      this.disableSaveButton = true;
      this.shipperConfig = await this.configurationProvider.getShipperConfig();
      this.shipperTenderingConfig = this.shipperConfig.tenderingConfig;
    } catch (error) {
      this.toast.closeProcessing();
      this.toast.errorAlert(ConfigurationLabels.toastErrorConfig);
      this.isSavingTenderingInfo = false;
    }
  }

  /**
   * @description Reset Tendering form group
   * @param {string} assignationType Type assignment selected
   */
  public resetTenderingConfig(assignationType: string): void {
    if (assignationType === MANUAL_ASSIGNMENT) {
      this.biddingTenderingFormGroup.controls['autoAssignBy'].reset();
      this.biddingTenderingFormGroup.controls['daysRangeActive'].reset();
    } else if (assignationType === AUTO_ASSIGNMENT) {
      this.biddingTenderingFormGroup.controls['sortOffersBy'].reset();
      this.biddingTenderingFormGroup.controls['sortOffersBy'].markAsPristine();
      this.biddingTenderingFormGroup.controls['sortOffersBy'].markAsUntouched();
      this.biddingTenderingFormGroup.get('sortOffersBy').clearValidators();
      this.biddingTenderingFormGroup.get('maximumOffers').setValue(3);
      this.biddingTenderingFormGroup.controls['maximumShipmentsPerCarrier'].reset();
    }
  }

  /**
   * @description Gets a service types list by shipper identifier
   */
  private async getServicesTypes(): Promise<void> {
    try {
      this.serviceTypes = await this.serviceTypeProvider.getAll(this.shipperId);
    } catch (error) {
    }
  }

  /**
   * @description sort and check changes in the the accounts selected and the accounts saved in shipper config
   * to enable the save button
   */
  public checkChangesInAccountsSelected(): void {
    if (this.publicAccessAccountsSaved) {
      const savedAccountsAux = this.publicAccessAccountsSaved.map(({ name, _id }) => ({ name, _id }));
      this.selectedAccounts.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 :
        ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0));
      savedAccountsAux.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) ? 1 :
        ((b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()) ? -1 : 0));

      if (_isEqual(this.selectedAccounts, savedAccountsAux)) {
        this.noChangesInAccountsSelected = true;
      } else {
        this.noChangesInAccountsSelected = false;
      }
    } else if (this.selectedAccounts && this.selectedAccounts.length) {
      this.noChangesInAccountsSelected = false;
    } else {
      this.noChangesInAccountsSelected = true;
    }
  }

  /**
   * @description check if the budget is valid to hide/display an error message
   */
  public validateQuantity(): void {
    const maximumShipmentsInput = this.biddingTenderingFormGroup.get('maximumShipmentsPerCarrier').value;
    if (maximumShipmentsInput !== null && (maximumShipmentsInput <= 0)) {
      this.biddingTenderingFormGroup.controls['maximumShipmentsPerCarrier'].setErrors({ 'invalidQuantity': true });
    }
  }
  /**
   * @description Get apportionment configuration by tenant
   */
  private async getApportionmentConfig() {
    this.configurationProvider.getApportionmentSettings(this.tenantId)
      .then(res => {
        if (res && !_.isEmpty(res)) {
          this.isApportionmentValid = false;
          this.currentApportSettings = res[0];
          this.apportionmentFormGroup.patchValue({
            piecesPercentage: this.currentApportSettings.piecesPercent,
            boxesPercentage: this.currentApportSettings.boxesPercent,
            palletsPercentage: this.currentApportSettings.palletsPercent,
            weightPercentage: this.currentApportSettings.weightPercent,
            volumePercentage: this.currentApportSettings.volumePercent,
            distancePercentage: this.currentApportSettings.distancePercent,
            serviceTypePercentage: this.currentApportSettings.serviceTypePercent,
            serviceType: this.currentApportSettings.serviceType
          });
          if (this.apportionmentFormGroup.value.serviceType.name) {
            this.isServiceTypeSelected = true;
          }
          if (this.apportionmentPercentages = 100) {
            this.isApportionmentValid = true;
          } else if (this.apportionmentPercentages > 100) {
            this.isApportionmentValid = false;
          } else {
            this.isApportionmentValid = false;
          }
        } else {
          this.defaultApportionmentValues();
          this.isApportionmentValid = true;
        }
      }
      );
  }

  /**
   * @description set default apportionment form values
   */
  private defaultApportionmentValues() {
    this.apportionmentFormGroup.patchValue({
      piecesPercentage: 0,
      boxesPercentage: 100,
      palletsPercentage: 0,
      weightPercentage: 0,
      volumePercentage: 0,
      distancePercentage: 0
    });
    this.isApportionmentValid = true;
  }

  /**
   * @description Sum total apportionment percentages
   * @returns sum value
   */
  public sumApportPercentages(): number {
    this.apportionmentPercentages = (this.apportionmentFormGroup.value.boxesPercentage +
      this.apportionmentFormGroup.value.piecesPercentage +
      this.apportionmentFormGroup.value.palletsPercentage +
      this.apportionmentFormGroup.value.weightPercentage +
      this.apportionmentFormGroup.value.volumePercentage +
      this.apportionmentFormGroup.value.distancePercentage);
    return (this.apportionmentPercentages);
  }

  /**
   * @description set the color of progressbar in apportionments
   * @returns the background color of progressbar
   */
  public getColor() {
    if (this.apportionmentPercentages < 100) {
      this.color = 'primary';
    } else if (this.apportionmentPercentages > 100) {
      this.color = 'warn';
    } else {
      this.color = 'accent';
    }
    return this.color;
  }

  /**
   * @description Listener of accounts selection
   */
  public listenSearchMatSelection(): void {
    this.searchMatSelectSelection = this.comService.searchMatSelectionSubscribe().subscribe(selection => {
      this.selectedAccounts = selection.map(({ name, _id }) => ({ name, _id }));
      this.checkChangesInAccountsSelected();
    });
  }

  /**
   * @description get of the select of field  the special concept
   * @returns value of the serviceType
   */
  public specialConceptSelect(): string {
    const serviceType = this.apportionmentFormGroup.get('serviceType').value.name;
    return serviceType;
  }

  /**
   * @description set type of service in special concepts
   * @param {ServiceType} serviceType service type from list of service types
   * @param {ServiceType} incomingServiceType service type from database
   * @returns the value of the select in special concepts
   */
  public setSpecialConcept(serviceType: ServiceType, incomingServiceType: ServiceType): boolean {
    return (serviceType && incomingServiceType) ? serviceType.name === incomingServiceType.name : serviceType === incomingServiceType;
  }

  /**
   * @description Initialize a form
   */
  public initTemperatureForm(): void {
    this.shipperTemperatureFormGroup = this.temperatureBuilder.group({
      minTemperature: new UntypedFormControl(this.temperatureData.minTemperature, [Validators.required]),
      maxTemperature: new UntypedFormControl(this.temperatureData.maxTemperature, [Validators.required]),
    });
  }

  /**
   * @description Check if there are changes in the form's field
   */
  public validateChangeTemperatureForm(): void {
    const temperatureInfoForm = this.shipperTemperatureFormGroup.value;
    if (_.isEqual(this.temperatureData, temperatureInfoForm)) {
      this.isDisabledSaveButton = true;
    } else {
      this.isDisabledSaveButton = false;
    }
  }

  /**
   * @description Redirect to Configuration view
   */
  public redirectConfigurationView(): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([ConfigurationConstants.CONFIGURATION_ROUTE]));
  }

  /**
   * @description Create temperatures body request
   */
  public createTemperatureRequest(): void {
    this.temperatureRequest.maxTemperature = this.shipperTemperatureFormGroup.value.maxTemperature;
    this.temperatureRequest.minTemperature = this.shipperTemperatureFormGroup.value.minTemperature;
  }

  /**
   * @description Check if minTemperature is greater to maxTemperature
   */
  public checkTemperatures(): void {
    this.isMinTemperatureError = false;
    if (this.temperatureRequest.maxTemperature < this.temperatureRequest.minTemperature) {
      this.isMinTemperatureError = true;
    }
  }

  /**
   * @description Submit temperatures data
   */
  public async submitTemperatures(): Promise<void> {
    try {
      this.createTemperatureRequest();
      this.checkTemperatures();
      if (!this.isMinTemperatureError) {
        await this.configurationProvider.postTemperatureSettings(this.tenantId, this.temperatureRequest);
        this.toast.successAlert(ConfigurationConstants.TEMPERATURES_SAVED);
        this.redirectConfigurationView();
      } else {
        this.toast.errorAlert(ConfigurationConstants.ERROR_TEMPERATURES);
      }
    } catch (error) {
      this.toast.errorAlert(ConfigurationConstants.ERROR_TEMPERATURES_SAVED);
    }
  }

  /**
   * @description Set initial values of variables
   */
  public setValuesTemperatures(): void {
    this.temperatureRequest = {
      minTemperature: null,
      maxTemperature: null
    };
  }

  /**
   * @description Set values of form
   */
  public async setValuesToTemperaturesForm(): Promise<void> {
    this.temperatureData = {
      minTemperature: null,
      maxTemperature: null
    };
  }

  /**
   * @description Get Shipper Temperatures
   */
  public async getTemperaturesToForm(): Promise<void> {
    try {
      const shipperOid = this.appService.getShipperOid();
      await this.configurationProvider.getTemperatureSettings(shipperOid)
        .then(res => {
          if (res) {
            this.shipperTemperatureFormGroup.setValue({
              maxTemperature: res.maxTemperature,
              minTemperature: res.minTemperature
            });
            this.showedMaxTemperature = res.maxTemperature + ConfigurationConstants.SYMBOL_TEMPERATURE;
            this.showedMinTemperature = res.minTemperature + ConfigurationConstants.SYMBOL_TEMPERATURE;
          } else {
            this.shipperTemperatureFormGroup.reset();
          }
        });
      this.temperatureData = {
        minTemperature: this.shipperTemperatureFormGroup.value.minTemperature,
        maxTemperature: this.shipperTemperatureFormGroup.value.maxTemperature
      };
      this.shipperTemperatureFormGroup.valueChanges.subscribe(() => {
        this.validateChangeTemperatureForm();
      });
    } catch (error) {
      this.shipperTemperatureFormGroup.setValue({
        maxTemperature: ConfigurationConstants.INITIAL_MAX_TEMPERATURE,
        minTemperature: ConfigurationConstants.INITIAL_MIN_TEMPERATURE
      });
      this.showedMaxTemperature = ConfigurationConstants.INITIAL_MAX_TEMPERATURE_STRING;
      this.showedMinTemperature = ConfigurationConstants.INITIAL_MIN_TEMPERATURE_STRING;
      this.temperatureData = {
        minTemperature: ConfigurationConstants.INITIAL_MIN_TEMPERATURE,
        maxTemperature: ConfigurationConstants.INITIAL_MAX_TEMPERATURE
      };
      this.shipperTemperatureFormGroup.valueChanges.subscribe(() => {
        this.validateChangeTemperatureForm();
      });
    }
  }

  /**
   * @description Show max temperature value
   * @param $event
   */
  public onInputChangeMaxTemperature($event: any): void {
    this.showedMaxTemperature = $event.value + ConfigurationConstants.SYMBOL_TEMPERATURE;
  }

  /**
   * @description Show min temperature value
   * @param $event
   */
  public onInputChangeMinTemperature($event: any): void {
    this.showedMinTemperature = $event.value + ConfigurationConstants.SYMBOL_TEMPERATURE;
  }

  /**
   * @description Enable or disable Cedis Acccess module and verify its status to be save it
   * @param {boolean} isActive Validated value for Tolerance Cedis Access checkbox
   */
  public onActivateCedisAccess(isActive?: boolean): void {
    this.isCedisConfigEnable = isActive;

    if (isActive) {
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISEARLYHOUR).enable();
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISEARLYMINUTE).enable();
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISLATEHOUR).enable();
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISLATEMINUTE).enable();
    } else {
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISEARLYHOUR).disable();
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISEARLYMINUTE).disable();
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISLATEHOUR).disable();
      this.cedisAccessFormGroup.get(ConfigurationConstants.CEDISLATEMINUTE).disable();
    }
    this.isToleranceCedisValid = true;
    this.isCedisAccessValid = this.existsChangesInCedissAccessConfig();
  }

  /**
   * @description checks data entered in CEDIS access config versus this current config in shipper config.
   * @returns {boolean} True if exists any change in any parameter of cedis access config. Otherwise false.
   */
  public existsChangesInCedissAccessConfig(): boolean {
    const lateArrivalMinutes = this.cedisAccessFormGroup.controls.lateArrivalMinutes.value;
    const lateArrivalHours = this.cedisAccessFormGroup.controls.lateArrivalHours.value;
    const earlyArrivalMinutes = this.cedisAccessFormGroup.controls.earlyArrivalMinutes.value;
    const earlyArrivalHours = this.cedisAccessFormGroup.controls.earlyArrivalHours.value;

    return lateArrivalMinutes !== this.shipperConfig.cedisAccess.lateArrivalMinutes ||
      lateArrivalHours !== this.shipperConfig.cedisAccess.lateArrivalHours ||
      earlyArrivalMinutes !== this.shipperConfig.cedisAccess.earlyArrivalMinutes ||
      earlyArrivalHours !== this.shipperConfig.cedisAccess.earlyArrivalHours ||
      this.shipperConfig.cedisAccess.isCedisConfigEnable !== this.isCedisConfigEnable;
  }

  /**
   * @description Set the Shipper CedisAccess configuration in the form
   * @param {number} earlyHourValue Early arrival hours value in Cedis Access Tolerance Time
   * @param {number} earlyMinValue Early arrival minutes value in Cedis Access Tolerance Time
   * @param {number} lateHourValue Late arrival hours value in Cedis Access Tolerance Time
   * @param {number} lateMinValue Late arrival minutes value in Cedis Access Tolerance Time
   */
  public setShipperCedis(earlyHourValue: number, earlyMinValue: number, lateHourValue: number, lateMinValue: number): void {
    this.cedisAccessFormGroup.setValue({
      earlyArrivalHours: earlyHourValue,
      earlyArrivalMinutes: earlyMinValue,
      lateArrivalHours: lateHourValue,
      lateArrivalMinutes: lateMinValue
    });
  }

  /**
   * @description Validates inputs entry to prevent activate save button from null or repeat values already saved
   */
  public onInputCedisAccess(): void {
    const earlyArrivalHours = this.cedisAccessFormGroup.controls.earlyArrivalHours.value;
    const earlyArrivalMinutes = this.cedisAccessFormGroup.controls.earlyArrivalMinutes.value;
    const lateArrivalHours = this.cedisAccessFormGroup.controls.lateArrivalHours.value;
    const lateArrivalMinutes = this.cedisAccessFormGroup.controls.lateArrivalMinutes.value;

    if (earlyArrivalHours === null || earlyArrivalMinutes === null || lateArrivalHours === null || lateArrivalMinutes === null) {
      this.isCedisAccessValid = false;
      this.lastCedisAccessStatus = false;
    } else if (!this.existsChangesInCedissAccessConfig()) {
      this.isCedisAccessValid = false;
      this.lastCedisAccessStatus = false;
    } else {
      this.isCedisAccessValid = true;
      this.lastCedisAccessStatus = true;
    }
  }

  /**
   * @description Create body and send the changes in Cedis Access configuration
   */
  public async submitCedisAccessChanges(): Promise<void> {
    const createCedisAccessBody: ToleranceCedisAccessBody = {
      earlyArrivalHours: this.cedisAccessFormGroup.value.earlyArrivalHours,
      earlyArrivalMinutes: this.cedisAccessFormGroup.value.earlyArrivalMinutes,
      lateArrivalHours: this.cedisAccessFormGroup.value.lateArrivalHours,
      lateArrivalMinutes: this.cedisAccessFormGroup.value.lateArrivalMinutes,
      user: this.tenantName,
      lastModifiedDate: new Date(),
      isCedisConfigEnable: this.isCedisConfigEnable
    };
    const createCedisAccessObject: CedisAccess = {
      cedisAccess: createCedisAccessBody
    };
    try {
      await this.configurationProvider.putCedisAccessSetting(this.tenantId, createCedisAccessObject);
      this.toast.closeProcessing();
      this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
    } catch (error) {
      this.toast.closeProcessing();
    }
  }

  /**
   * @description Save changes on Cedis Access configurations section
   */
  public async onSaveCedisAccess(): Promise<void> {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM && this.isToleranceCedisValid) {
        this.toast.processingAlert();
        this.submitCedisAccessChanges();
        this.redirectConfigurationView();
      }
    });
  }

  /**
   * @description Validates freights the percent value from slider to enable save withholdings configuration button
   * @param {EventInput} $event Detects input modifications on specific event
   */
  public onInputFreights($event: EventInput): void {
    const freightsPercent = this.withholdingsFormGroup.controls.freightsPercentage.value;
    const inputValue = $event.value;
    if (freightsPercent > 0 && inputValue != null) {
      this.isWithholdingsValid = true;
    } else {
      this.isWithholdingsValid = false;
    }
  }

  /**
   * @description Save changes on whithholdings configurations section
   */
  public async onSaveWithholdings(): Promise<void> {
    const user = await this.userProvider.getUserById(this.userOid);
    const createWithholdingsBody: WithholdingsBody = {
      lastModifiedDate: this.datepipe.transform((new Date), ConfigurationConstants.DATE_FORMAT),
      freightsPercentage: +this.withholdingsFormGroup.value.freightsPercentage,
      maneuversPercentage: +this.withholdingsFormGroup.value.maneuversPercentage,
      user: user.username,
    };
    const createWithholdingsObject: Withholdings = {
      withholdings: createWithholdingsBody
    };
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM && this.isWithholdingsValid) {
        this.toast.processingAlert();
        try {
          await this.configurationProvider.createWithholdings(createWithholdingsObject);
          this.toast.closeProcessing();
          this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
          this.isWithholdingsValid = false;
        } catch (error) {
          this.toast.closeProcessing();
        }
      }
    });
  }

  /**
   * @description Show Input boxes
   * @param $event
   */
  public onInputBoxes($event: EventInput): void {
    this.isApportionmentValid = this.sumApportPercentages() === 100;
    this.currentApportSettings.boxesPercent = $event.value;
  }

  /**
   * @description Show Input distance
   * @param $event
   */
  public onInputDistance($event: EventInput): void {
    this.isApportionmentValid = this.sumApportPercentages() === 100;
    this.currentApportSettings.distancePercent = $event.value;
  }

  /**
   * @description Show Input pallets
   * @param $event
   */
  public onInputPallets($event: EventInput): void {
    this.isApportionmentValid = this.sumApportPercentages() === 100;
    this.currentApportSettings.palletsPercent = $event.value;
  }

  /**
   * @description Show volume
   * @param $event
   */
  public onInputVolume($event: EventInput): void {
    this.isApportionmentValid = this.sumApportPercentages() === 100;
    this.currentApportSettings.volumePercent = $event.value;
  }

  /**
   * @description Show Input weight
   * @param $event
   */
  public onInputWeight($event: EventInput): void {
    this.isApportionmentValid = this.sumApportPercentages() === 100;
    this.currentApportSettings.weightPercent = $event.value;
  }

  /**
   * @description Show Input pieces
   * @param $event
   */
  public onInputPieces($event: EventInput): void {
    this.isApportionmentValid = this.sumApportPercentages() === 100;
    this.currentApportSettings.piecesPercent = $event.value;
  }

  /**
   * @description Show Input special Concepts
   * @param $event
   */
  public onInputSpecialConcepts($event: EventInput): void {
    this.currentApportSettings.serviceTypePercent = $event.value;
    if (this.sumApportPercentages() === 100 && this.currentApportSettings.serviceTypePercent > 1) {
      this.isApportionmentValid = true;
    }
  }

  /**
   * @description On save apportionment button click
   */
  public async onSaveApportionment() {
    const user = await this.userProvider.getUserById(this.userOid);
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });
    const createApportionmentBody: ApportionmentUpdateBody = {
      boxesPercent: +this.apportionmentFormGroup.value.boxesPercentage,
      palletsPercent: +this.apportionmentFormGroup.value.palletsPercentage,
      piecesPercent: +this.apportionmentFormGroup.value.piecesPercentage,
      shipperId: this.tenantId,
      user: user.username,
      volumePercent: +this.apportionmentFormGroup.value.volumePercentage,
      weightPercent: +this.apportionmentFormGroup.value.weightPercentage,
      distancePercent: +this.apportionmentFormGroup.value.distancePercentage,
      serviceType: this.apportionmentFormGroup.value.serviceType,
      serviceTypePercent: this.apportionmentFormGroup.value.serviceTypePercentage
    };
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM) {
        this.toast.processingAlert();
        try {
          if (this.currentApportSettings) {
            await this.configurationProvider.putApportionmentSettings(this.tenantId, createApportionmentBody).then(status => {
              this.isApportionmentValid = false;
              this.toast.closeProcessing();
              this.toast.successAlert(ConfigurationLabels.toastSuccessCreateApport);
              this.redirectConfigurationView();
            })
              .catch(error => {
                this.toast.closeProcessing();
                this.toast.errorAlert(ConfigurationLabels.toastErrorCreateApport);
              });
          } else {
            await this.configurationProvider.postApportionmentSettings(this.tenantId, createApportionmentBody).then(status => {
              this.isApportionmentValid = false;
              this.toast.closeProcessing();
              this.toast.successAlert(ConfigurationLabels.toastSuccessCreateApport);
            });
          }
        } catch (error) {
          this.toast.closeProcessing();
          this.toast.errorAlert(ConfigurationLabels.toastErrorCreateApport);
        }
      }
    });
  }

  /**
   * @description Show a modal to accept the changes
   */
  public async onSaveTemperatures(): Promise<void> {
    const dialogRef = this.dialog.open(DialogCheckTemperaturesComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogContinue,
        iconPath: ConfigurationConstants.LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });
    this.modalSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result === ConfigurationConstants.CONFIRM_STATUS) {
        this.submitTemperatures();
      }
    });
  }
  public changed() {
    this.isServiceTypeSelected = true;
  }

  /**
   * @description Shows Save driver configuration modal
   */
  public async onSaveDriverConfig(): Promise<void> {
    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM) {
        await this.saveDriverConfig();
      }
    });
  }

  /**
   * @description Save driver configuration
   */
  public async saveDriverConfig(): Promise<void> {
    try {
      this.toast.processingAlert();
      const driverConfig: DriverConfig = { maxDriverInactiveTime: this.maxDriverInactiveTime };
      await this.configurationProvider.saveDriverConfig(this.tenantId, driverConfig);
      this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
    } catch (error) {
      this.toast.errorAlert(ConfigurationLabels.toastErrorConfig);
    } finally {
      this.toast.closeProcessing();
    }
  }

  /**
   * @description unsubscribe to mat select events (if exists) when view is changed
   */
  public ngOnDestroy(): void {
    if (this.searchMatSelectEvent && this.searchMatSelectSelection) {
      this.searchMatSelectEvent.unsubscribe();
      this.searchMatSelectSelection.unsubscribe();
    }
  }

  /**
   * @description Save the stamp provider selected if the tenant some has
   */
  public async saveStampProvider(): Promise<void> {
    if (this.stampProviderFormGroup.invalid) { return; }

    const dialogRef = this.dialog.open(DialogStandardFocusComponent, {
      data: {
        textButton1: ConfigurationLabels.dialogSaveCancel,
        textButton2: ConfigurationLabels.dialogSaveAccept,
        iconPath: LOGO_DIALOG_IMAGE,
        question: ConfigurationLabels.dialogSaveQuestion,
        resume: ConfigurationLabels.dialogSaveResume,
        title: ConfigurationLabels.dialogSaveTitle
      },
      width: ConfigurationViewProperties.dialogDeleteWidth
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === CONFIRM) {
        try {
          this.toast.processingAlert();
          const systemClientConfigsToActive = this.buildSystemClientConfigsToActive();
          const response = await this.integratorProvider.setNewStampProvider(
            this.tenantId,
            systemClientConfigsToActive.stampProvider.id,
            systemClientConfigsToActive
          );
          if (response.systemClientConfig) {
            await this.getStampProvidersIntoSystemClientsConfigs();
          }
          this.toast.successAlert(ConfigurationLabels.successOnConfigSave);
        } catch (error) {
          this.toast.errorAlert(ConfigurationLabels.toastErrorConfig);
        } finally {
          this.toast.closeProcessing();
        }
      }
    });
  }

  /**
   * @description Gets the configuration changes to create logs data for registration
   * @returns {Array<ConfigurationChangesLog>}  Configuration logs to save on db
   */
  public async getChangesLogs(): Promise<Array<ConfigurationChangesLog>> {
    const logsConfigToSave: Array<ConfigurationChangesLog> = [];

    if (this.shipperConfig.fieldsSettings) {
      if (this.shipperConfig.fieldsSettings.transportDatesRequired !== this.isTransportDatesRequired) {

        const transportDatesAction = this.isTransportDatesRequired ?
        ActionLog.transportDatesRequiredEnabled :
        ActionLog.transportDatesRequiredDisabled;

        const configurationLog = await this.buildConfigurationLog(transportDatesAction, this.userName);
        logsConfigToSave.push(configurationLog);
      }
    }

    if (this.shipperConfig.isAppointmentOrdersRequired !== this.isAppointmentOrdersRequired) {

      const appointmentOrdersAction = this.isAppointmentOrdersRequired ?
      ActionLog.appointmentOrdersRequiredEnabled :
      ActionLog.appointmentOrdersRequiredDisabled;
      const configurationLog = await this.buildConfigurationLog(appointmentOrdersAction, this.userName);
      logsConfigToSave.push(configurationLog);
    }

    if (this.shipperConfig.isFreightCostRequiredToConfirmShipments !== this.isFreightCostRequiredToConfirmShipments) {
      const costConfigAction = this.isFreightCostRequiredToConfirmShipments ? ActionLog.requiredFreightCostEnabled :
      ActionLog.requiredFreightCostDisabled;
      const configurationLog = await this.buildConfigurationLog(costConfigAction, this.userName);
      logsConfigToSave.push(configurationLog);
    }

    if (this.shipperConfig.isCheckChargesAndDiscountsEnabled  !== this.isCheckChargesAndDiscountsEnabled ) {
      const logAction = this.isCheckChargesAndDiscountsEnabled  ? ActionLog.checkChargesAndDiscountsEnabled :
      ActionLog.checkChargesAndDiscountsDisabled;
      const configurationLog = await this.buildConfigurationLog(logAction, this.userName);
      logsConfigToSave.push(configurationLog);
    }

    if (this.shipperConfig.isPaymentTypeRequired !== this.isPaymentTypeRequired) {
      const logAction =  this.isPaymentTypeRequired ? ActionLog.paymentTypeRequiredEnabled :
      ActionLog.paymentTypeRequiredDisabled
      const configurationLog = await this.buildConfigurationLog(logAction, this.userName);
      logsConfigToSave.push(configurationLog);
    }

    if (this.shipperConfig.documentsSettings.isLoadPlanByProductsActive !== this.isLoadPlanByProductsActive) {
      const logAction =  this.isLoadPlanByProductsActive ? ActionLog.loadPlanByProductEnabled :
        ActionLog.loadPlanByProductDisabled;
      const configurationLog = await this.buildConfigurationLog(logAction, this.userName);
      logsConfigToSave.push(configurationLog);
    }

    if (this.hasAnyPercentageFieldChanges) {
      const logAction = ActionLog.percentagesHasBeenChanged;
      const configurationLog = await this.buildConfigurationLog(logAction, this.userName);
      logsConfigToSave.push(configurationLog);
    }

    return logsConfigToSave;
  }

  /**
   * @decription Builds configuration changes log
   * @param {string} actionToSave Action to be registered on the log data
   * @param {string} userName User who does the changes
   * @returns {ConfigurationChangesLog} Created log
   */
  public async buildConfigurationLog(actionToSave: string, userName: string): Promise<ConfigurationChangesLog> {
    const log: ConfigurationChangesLog = {
      action: actionToSave,
      date: new Date(),
      user: userName
    };

    return log;
  }

  /**
   * @description Get the stamp providers registred in 'SystemClientsConfigs'
   */
  private async getStampProvidersIntoSystemClientsConfigs(): Promise<void> {
    try {
      this.stampProviderViewChange = false;
      this.stampProviderList = [];
      const response = await this.integratorProvider.getSystemClientsConfigsStampProviders(this.tenantId);
      const resultSystemClientConfigsList = response.systemClientConfig as Array<SystemClientConfigs>;
      if (resultSystemClientConfigsList.length) {
        this.stampProviderViewActive = this.buildStampProvider(resultSystemClientConfigsList.find(element => element?.isActive));
        for (const _systemClientConfig of resultSystemClientConfigsList) {
          this.stampProviderList.push(this.buildStampProvider(_systemClientConfig));
        }
      }
      this.stampProviderFormGroup.patchValue({ stampProvider: this.stampProviderViewActive });
      this.hasStampProviderList = this.stampProviderList && this.stampProviderList?.length > 0 ? true : false;
    } catch {
    }
  }

  /**
   * @description Get the stamp providers registred in 'SystemClientsConfigs'
   * @param {SystemClientConfigs} systemClientConfigs get the information for build the object StampProviderView
   * for add into the list stampProviderList
   * @returns {StampProviderView} object type StampProviderView
   */
  private buildStampProvider(systemClientConfigs: SystemClientConfigs): StampProviderView {

    if (!systemClientConfigs) { return null; }

    let stampProviderView: StampProviderView = {} as StampProviderView;
    stampProviderView = systemClientConfigs.stampProvider;
    stampProviderView.systemClientConfigId = systemClientConfigs.id;
    stampProviderView.isActive = systemClientConfigs?.isActive;

    return stampProviderView;
  }

  /**
   * @description Build the object type SystemClientConfigs for send in the request
   * @returns {SystemClientConfigs} object type SystemClientConfigs for send
   */
  private buildSystemClientConfigsToActive(): SystemClientConfigs {
    try {
      const systemClientConfigs: SystemClientConfigs = {} as SystemClientConfigs;
      const stampProviderView = (this.stampProviderFormGroup.value?.stampProvider) as StampProviderView;
      systemClientConfigs.id = stampProviderView.systemClientConfigId;
      systemClientConfigs.client =  {
        name: AppConstants.EMPTY_STRING,
        _id: this.tenantId
      };
      systemClientConfigs.isActive = true;
      systemClientConfigs.stampProvider = stampProviderView as StampProvider;

      return systemClientConfigs;
    } catch {
      return null;
    }
  }

  /**
   * @description Verify if the fields of form has change to edit
   */
  private changesInStampProviderFormGroup(): void {
    let initialValues = this.stampProviderFormGroup.value;
    this.stampProviderFormGroup.valueChanges.subscribe(values => {
      initialValues = this.stampProviderViewActive ? { stampProvider: this.stampProviderViewActive } : initialValues;
      Object.keys(values).forEach(key => values[key] = values[key] === AppConstants.EMPTY_STRING ? null : values[key]);
      this.stampProviderViewChange = Object.keys(initialValues).some(key => values[key] !== initialValues[key]);
    });
  }

  /**
   * @description Creates the account list for the Ship orders by appointment only setup
   * @param {Array<Account>} accounts response
   * @returns {Array<Account>} list of account
   */
   public getAccountNames(accountResponseList: Array<AccountBody>): Array<Account> {
    const accountsAux: Array<Account> = [];
    if (accountResponseList) {
      accountResponseList.forEach(cuenta => {
        const accountAux = {
          _id: cuenta._id,
          name: cuenta.nombre
        };
        accountsAux.push(accountAux);
      });
    }

    return accountsAux;
  }

  /**
   * @description Set the shipper setup accounts to form control
   * @param {Array<AccountsAppointmentOrdersRequired>} accounts set to shipper configuration
   */
  private setDefaultAccounts(accounts: Array<AccountsAppointmentOrdersRequired>): void {
    const data = (!accounts || accounts.length === 0)
      ? []
      : this.accountsSelected(accounts);
    this.configShipmentOrderFormGroup.patchValue({
      accountIds: data
    });
  }

  /**
   * @description Determine if the accounts in configuration shipper is equals to show in control select
   * @param {Array<AccountsAppointmentOrdersRequired>} accounts set to shipper configuration
   * @returns {AccountsSelected} response of validation with values ​​and it is equals
   */
  private accountsSelected(accounts: Array<AccountsAppointmentOrdersRequired>): Array<Account> {
    const accountIds = accounts.map((account) => {
      return account._id;
    });
    const accountList = this.accountList.filter(account => accountIds.includes(account._id));
    const difference = this.accountList.filter(account => !accountList.includes(account));
    if (difference.length === 0) {
      accountList.push(this.defaultValueAccount);
    }

    return accountList;
  }

  /**
   * @description Set and check if all items or some element if selected in the control
   * @param {MatSelect} matSelect object from form configShipmentOrderFormGroup
   */
  public selectAll(matSelect: MatSelect): void {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value._id === this.defaultValueAccount._id)
      .map((item: MatOption) => item.selected)[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  /**
   * @description Get only values selected in the control
   * @returns {Array<AccountsAppointmentOrdersRequired>} values selectes in the control configShipmentOrderFormGroup
   */
  private getAccountSelected(): Array<AccountsAppointmentOrdersRequired> {
    return this.configShipmentOrderFormGroup.value.accountIds ? (this.configShipmentOrderFormGroup.value.accountIds as Array<AccountsAppointmentOrdersRequired>)
      .filter(element => element._id !== this.defaultValueAccount._id) : [];
  }

  /**
   * @description Verify if the fields of form has change to edit
   */
  private verifyIfExistChanges(): void {
    if (!this.isAppointmentOrdersRequired) { return; }

    const initialValues = this.configShipmentOrderFormGroup.value.accountIds as Array<AccountsAppointmentOrdersRequired>;
    this.configShipmentOrderFormGroup.valueChanges.subscribe((values) => {
      if (values.hasOwnProperty(KEYS_ACCOUNT_IDS)) {
        const accountIdsInitial = initialValues.map((valForm) => {
          return valForm._id;
        });
        const accountIdsMod = (values.accountIds as Array<AccountsAppointmentOrdersRequired>)
          .map((init) => {
            return init._id;
          });
        const result =
          accountIdsInitial.length === accountIdsMod.length &&
          accountIdsInitial.every((value, index) => value === accountIdsMod[index]);
        this.isShipConfigButtonEnabled = !result;
      }
    });
  }
}
