export enum DialogChargeTypeLabels {
  delivery = 'Por entrega',
  deliveryValue = 'delivery',
  typeOfCharge = 'Tipo de cobro',
  unit = 'Unidad completa',
  unitValue = 'unit'
}

export interface IDialogChargeTypeLabels {
  delivery: string;
  deliveryValue: string;
  typeOfCharge: string;
  unit: string;
  unitValue: string;
}
