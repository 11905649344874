<div class="general-container">
  <h3 class="title-page">{{ labels.title }}</h3>
  <mat-divider class="mat-divider-header"></mat-divider>

  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.driverSettingsTitle }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="4" rowHeight="1:.3">
          <mat-grid-tile>
            <mat-form-field>
              <input
                id="maxDriverInactiveTime"
                placeholder="{{ labels.maxDriverInactiveTime }}"
                (keypress)="onKeyPressCode($event)"
                matInput
                [min]="defaultMaxDriverInactiveTime"
                [(ngModel)]="maxDriverInactiveTime"
                type="number">
              </mat-form-field>
              <img
                [matTooltip]="labels.maxDriverInactiveTimeInfo"
                src="../../../assets/icons/info.svg"
                alt="inactive time info image">
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              [disabled]="(maxDriverInactiveTime < defaultMaxDriverInactiveTime)"
              mat-raised-button
              class="primary-button"
              (click)="onSaveDriverConfig()">
              {{ labels.saveChanges }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.costingConfigurationsCardTitle }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="cost-config-container">
        <mat-grid-list cols="4" rowHeight="1:.2">
          <mat-grid-tile>
            <mat-slide-toggle
              id="freightCostRequiredToggle"
              class="ship-slide-toggle"
              [(ngModel)]="isFreightCostRequiredToConfirmShipments"
              (change)="onChangeIsFreightCostRequiredToConfirmShipments()"
              [matTooltip]="labels.freightCostRequiredTooltip">
              {{ labels.freightCostRequiredSlideToggleTextPt1 }} <br>
              {{ labels.freightCostRequiredSlideToggleTextPt2 }}
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-slide-toggle
              id="chargesAndDiscountsToggle"
              class="ship-slide-toggle"
              [(ngModel)]="isCheckChargesAndDiscountsEnabled"
              (change)="onCheckChargesAndDiscounts()">
              {{ labels.checkChargesAndDiscounts }}
              <img
                [matTooltip]="labels.checkChargesAndDiscountsTooltipBegin + labels.checkChargesAndDiscountsTooltipEnd"
                src="{{ infoIcon }}"
                alt="check charges and discounts config toggle">
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-slide-toggle
              id="paymentTypeRequiredToogle"
              class="ship-slide-toggle"
              [(ngModel)]="isPaymentTypeRequired"
              (change)="onChangePaymentType()">
              {{ labels.paymentTypeRequiredText }}
              <img
              alt="saveSettingsDialogIcon"
              [matTooltip]="labels.paymentTypeRequiredTooltip"
              src="../../../assets/icons/info.svg">
            </mat-slide-toggle>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list  rowHeight="1:.1">
          <div class="container-subtitle">
            <div>
              <h2 class="cost-configuration-subtitle">{{ labels.withholdingsAndTaxes }}</h2>
            </div>
            <hr class="hr-subtitle">
          </div>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="1:.2" class="cost-inputs-container">
          <mat-grid-tile>
            <mat-form-field>
              <input
                [(ngModel)]="percentageISR"
                placeholder="{{ labels.percentageISR }}"
                id="cc-percentage-isr"
                matInput
                type="number"
                min="0"
                max="100"
                step="1"
                value="0"
                class="inputnumber"
                (keydown)="allowIntegerNumbersOnly($event)"
                (ngModelChange)="onPercentageInputChanges()">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                [(ngModel)]="percentageIVA"
                placeholder="{{ labels.percentageIVA }}"
                id="cc-percentage-iva"
                matInput
                type="number"
                min="0"
                max="100"
                step="1"
                value="0"
                class="inputnumber"
                (keydown)="allowIntegerNumbersOnly($event)"
                (ngModelChange)="onPercentageInputChanges()">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                [(ngModel)]="percentageFiscalBorderStrip"
                placeholder="{{ labels.percentageFiscalBorderStrip }}"
                id="cc-percentage-fiscal"
                matInput
                type="number"
                min="0"
                max="100"
                step="1"
                value="0"
                class="inputnumber"
                (keydown)="allowIntegerNumbersOnly($event)"
                (ngModelChange)="onPercentageInputChanges()">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                [(ngModel)]="percentageFreightRetention"
                placeholder="{{ labels.percentageFreightRetention }}" 
                id="cc-percentage-freight-retention"
                matInput type="number"
                min="0"
                max="100"
                step="1"
                value="0"
                class="inputnumber"
                (keydown)="allowIntegerNumbersOnly($event)"
                (ngModelChange)="onPercentageInputChanges()">
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="4" rowHeight="1:.2" class="cost-inputs-container">
          <mat-grid-tile>
            <mat-form-field>
              <input
                [(ngModel)]="percentageManeuverRetention"
                placeholder="{{ labels.percentageManeuverRetention }}"
                id="cc-percentage-maneuver-retention"
                matInput
                type="number"
                min="0"
                max="100"
                step="1"
                value="0"
                class="inputnumber"
                (keydown)="allowIntegerNumbersOnly($event)"
                (ngModelChange)="onPercentageInputChanges()">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              id="costingConfigurationSaveButton"
              class="primary-button button-container"
              mat-raised-button
              [disabled]="!isCostConfigButtonEnabled"
              (click)="onSaveCostConfig()">
              {{ labels.save }}
            </button>
          </mat-grid-tile>
      </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.documentsSettingsCardTitle }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="cost-config-container">
        <mat-grid-list cols="4" rowHeight="1:.3">
          <div>
            <mat-grid-tile>
              <mat-slide-toggle
                id="conc-load-plan-products-toogle"
                class="ship-slide-toggle"
                [(ngModel)]="isLoadPlanByProductsActive"
                (change)="onDocumentsSettings()">
                {{ labels.loadPlanByProductsPlaceholderPt1 }}
                <img
                  alt="saveSettingsDialogIcon"
                  [matTooltip]="labels.loadPlanByProductsTooltip"
                  src="{{ infoIcon }}">
                <br>
                {{ labels.loadPlanByProductsPlaceholderPt2 }}
              </mat-slide-toggle>
            </mat-grid-tile>
          </div>
          <mat-grid-tile>
            <button
              id="conc-documents-settings-save-button"
              class="primary-button button-container"
              mat-raised-button
              [disabled]="!isDocumentsSeetingsButtonEnabled"
              (click)="onSaveDocumentsSettings()">
              {{ labels.save }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight='2.5em' expandedHeight='2.5em'>
        <mat-panel-title>
          {{ this.labels.titleShipment }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content class="ship-orders-config-container">
        <mat-grid-list cols="3" rowHeight="1:.1">
          <mat-grid-tile>
            <mat-slide-toggle
              class="ship-slide-toggle"
              [(ngModel)]="shipUndeliveredOrders"
              (change)="onChangesShipUndeliveredOrders()">
              {{ labels.shipNotDeliveredOrders }}
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-slide-toggle
              class="ship-slide-toggle"
              [(ngModel)]="shipRejectedOrders"
              (change)="onChangesShipRejectedOrders()">
              {{ labels.shipRejectedOrders }}
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-slide-toggle
              class="ship-slide-toggle"
              [(ngModel)]="isActivateDetentionChecked"
              (change)="onChangesActivateDetention()">
              {{ labels.activateDetention }}
              <img [matTooltip]="labels.activateDetentionInfo" src="../../../assets/icons/info.svg" alt="Order detention slide info">
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-slide-toggle
              class="ship-slide-toggle"
              [(ngModel)]="isActivateUseUccChecked"
              (change)="onChangesActivateUseUcc()">
              {{ labels.activateUseUcc }}
            <img [matTooltip]="labels.activateUseUccInfo" src="../../../assets/icons/info.svg" alt="Use UCC's slide info">
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-slide-toggle
              class="ship-slide-toggle"
              [(ngModel)]="isAppointmentOrdersRequired"
              (change)="onChangeAppointmentOrdersRequired()">
              {{ labels.appointmentOrdersRequired }}
            <img [matTooltip]="labels.appointmentOrdersRequiredInfo" src="../../../assets/icons/info.svg">
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <form class="form-config-shipment-order" *ngIf="configShipmentOrderFormGroup" [formGroup]="configShipmentOrderFormGroup">
              <mat-form-field>
                <mat-label>{{ labels.accountsAppointmentOrdersRequired }}</mat-label>
                <mat-select
                  [disabled]="!isAppointmentOrdersRequired"
                  (selectionChange)="isChanged()"
                  [required]="isAppointmentOrdersRequired"
                  formControlName="accountIds"
                  id="accounts-select"
                  multiple
                  #allAccountsSelected
                  disableOptionCentering="true">
                  <mat-option
                    class="select-all"
                    [value]="defaultValueAccount"
                    (click)="selectAll(allAccountsSelected)"
                    *ngIf="accountList && accountList.length">
                    {{ labels.selectAll }}
                  </mat-option>
                  <mat-option
                    *ngFor="let account of accountList"
                    [value]="account">
                    {{ account.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </mat-grid-tile>
          <mat-grid-tile colspan="3">
            <button
              [disabled]="!isShipConfigButtonEnabled ||
                configShipmentOrderFormGroup?.invalid"
              mat-raised-button
              (click)="onSaveShipmentConfig()"
              class="primary-button button-container">
              {{ labels.save }}
          </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-expansion-panel>
  </div>

  <div *ngIf="!userIsAdmin">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight='2.5em' expandedHeight='2.5em'>
        <mat-panel-title>
          {{ labels.fieldsSettings }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content class="ship-orders-config-container">
        <mat-grid-list cols="3" rowHeight="1:.1">
          <mat-grid-tile>
            <mat-slide-toggle
              class="ship-slide-toggle"
              id="fieldsSettings"
              [(ngModel)]="neccessaryRFCForLocations"
              (change)="onChangesFieldsSettings()"
              color="primary">
              {{ labels.neccessaryRFCForLocations }}
              <img
                alt="saveSettingsDialogIcon"
                [matTooltip]="labels.fieldsSettingsInfo"
                src="{{ infoIcon }}">
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-slide-toggle
              class="ship-slide-toggle"
              [(ngModel)]="isTransportDatesRequired"
              (change)="onChangeTransportDatesRequired()">
              {{ labels.transportDatesRequired }}
            <img [matTooltip]="labels.transportDatesRequiredInfo" src="../../../assets/icons/info.svg">
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              id="fieldsSettingsButton"
              (click)="confirmFieldsSettings()"
              [disabled]="isSavingFieldsSettings || !isFieldSettingsChanged"
              mat-raised-button
              class="primary-button button-container">
              {{ labels.save }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-expansion-panel>
  </div>

  <div *ngIf="temperatureRangeEnabled">
    <form [formGroup]="shipperTemperatureFormGroup" *ngIf="shipperTemperatureFormGroup">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{ labels.titleTemperature }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="1:.05">
            <mat-grid-tile>
              <h4> {{ labels.minTemperaturePlaceHolder }} </h4>
              <p class="required-field">
                <sup> {{ labels.requiredMark }} </sup>
              </p>
            </mat-grid-tile>
            <mat-grid-tile>
              <h4> {{ labels.maxTemperaturePlaceHolder }} </h4>
              <p class="required-field">
                <sup> {{ labels.requiredMark }} </sup>
              </p>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="1:.2">
            <mat-grid-tile>
              <div class="border-temperatures">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ this.showedMinTemperature }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container">
                      <div>
                        <h5> {{ labels.minTemperatureSymbol }} </h5>
                      </div>
                    </div>
                    <div class="lg-flex-container">
                      <div>
                        <h5> {{ labels.zeroTemperature }} </h5>
                      </div>
                    </div>
                    <div class="xs-flex-container">
                      <div>
                        <h5> {{ labels.maxTemperatureSymbol }} </h5>
                      </div>
                    </div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="sliderMinTemperature"
                        min="-20"
                        max="20"
                        step="1"
                        required
                        formControlName="minTemperature"
                        value="minTemperature"
                        (change)="onInputChangeMinTemperature($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="border-temperatures">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ this.showedMaxTemperature }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container">
                      <div>
                        <h5> {{ labels.minTemperatureSymbol }} </h5>
                      </div>
                    </div>
                    <div class="lg-flex-container">
                      <div>
                        <h5> {{ labels.zeroTemperature }} </h5>
                      </div>
                    </div>
                    <div class="xs-flex-container">
                      <div>
                        <h5> {{ labels.maxTemperatureSymbol }} </h5>
                      </div>
                    </div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="sliderMaxTemperature"
                        min="-20"
                        max="20"
                        step="1"
                        required
                        formControlName="maxTemperature"
                        value="maxTemperature"
                        (change)="onInputChangeMaxTemperature($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="1:.1">
            <mat-grid-tile>
              <button
                mat-raised-button
                color="primary"
                (click)="onSaveTemperatures()"
                [disabled]="
                shipperTemperatureFormGroup.invalid ||
                isDisabledSaveButton"
                class="tenant-button
                       margin-button">
                {{ labels.save }}
              </button>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </form>
  </div>

  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.titleWarehouse }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="4" rowHeight="1:.3">
          <mat-grid-tile>
            <button
              mat-raised-button
              (click)="onChangeWarehouse()"
              [disabled]="noWarehouses || (warehouseList && warehouseList.length === 0)"
              class="tenant-button">
              {{ labels.changeWarehouse }}
            </button>
          </mat-grid-tile>
          <mat-grid-tile>
            <p *ngIf="noWarehouses">{{ labels.noWarehouses }}</p>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="!userIsAdmin">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.titleTenant }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="4" rowHeight="1:.3">
          <mat-grid-tile>
            <p *ngIf="tenantExists">
              {{ labels.tenantRegistred }} <strong>{{ tenantName }}</strong>
            </p>
            <p *ngIf="!tenantExists">{{ labels.tenantUnregistred }}</p>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              mat-raised-button
              (click)="onRegisterTenant()"
              [disabled]="tenantExists"
              class="tenant-button">
              {{ labels.createTenant }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <form [formGroup]="cedisAccessFormGroup" *ngIf="cedisAccessFormGroup">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{ labels.titleCedisAccess }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list cols="1" rowHeight="1:.1">
            <mat-grid-tile>
              <mat-checkbox
                class="margin-checkbox"
                (change)="onActivateCedisAccess($event.checked)"
                [(ngModel)]="isCedisConfigEnable"
                [checked]="isCedisConfigEnable"
                id="ActivateCedisAccess">
                {{ labels.activateTolerance }}
              </mat-checkbox>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="6" rowHeight="1:.3">
            <mat-grid-tile
              class="arrive-align">
              {{ labels.earlyArrive }}
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2">
            </mat-grid-tile>
            <mat-grid-tile
              class="arrive-align">
              {{ labels.lateArrive }}
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list
            cols="8"
            rowHeight="1:.4">
            <mat-grid-tile
              class="align-first-Cedis-input">
              <mat-form-field>
                <input
                  id="earlyArrivalHours"
                  [disabled]="!isCedisConfigEnable"
                  (keypress)="onKeyPressCode($event)"
                  matInput
                  min="0"
                  max="120"
                  maxlength="3"
                  value="0"
                  formControlName="earlyArrivalHours"
                  (input)="onInputCedisAccess()"
                  type="number">
                <mat-error *ngIf="cedisAccessFormGroup.controls['earlyArrivalHours'].hasError('required')">
                  {{ labels.hoursIs }} <strong>{{ labels.isRequired }}</strong>
                </mat-error>
                <mat-error *ngIf="cedisAccessFormGroup.controls['earlyArrivalHours'].hasError('max')">
                  {{ labels.hoursMax }} <strong>{{ labels.isMaxHours }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              class="align-first-Cedis-label size-Cedis-label">
              {{ labels.hours }}
            </mat-grid-tile>
            <mat-grid-tile
              class="align-second-Cedis-input">
              <mat-form-field>
                <input
                  id="earlyArrivalMinutes"
                  (keypress)="onKeyPressCode($event)"
                  matInput
                  min="0"
                  max="59"
                  maxlength="2"
                  value="0"
                  formControlName="earlyArrivalMinutes"
                  (input)="onInputCedisAccess()"
                  type="number">
                <mat-error *ngIf="cedisAccessFormGroup.controls['earlyArrivalMinutes'].hasError('required')">
                  {{ labels.minutesIs }} <strong>{{ labels.isRequired }}</strong>
                </mat-error>
                <mat-error *ngIf="cedisAccessFormGroup.controls['earlyArrivalMinutes'].hasError('max')">
                  {{ labels.minutesMax }} <strong>{{ labels.isMaxMinutes }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              class="align-second-Cedis-label size-Cedis-label">
              {{ labels.minutes }}
            </mat-grid-tile>
            <mat-grid-tile
              class="align-thrid-Cedis-input">
              <mat-form-field>
                <input
                  id="lateArrivalHours"
                  (keypress)="onKeyPressCode($event)"
                  matInput
                  min="0"
                  max="120"
                  maxlength="3"
                  value="0"
                  formControlName="lateArrivalHours"
                  (input)="onInputCedisAccess()"
                  type="number">
                <mat-error *ngIf="cedisAccessFormGroup.controls['lateArrivalHours'].hasError('required')">
                  {{ labels.hoursIs }} <strong>{{ labels.isRequired }}</strong>
                </mat-error>
                <mat-error *ngIf="cedisAccessFormGroup.controls['lateArrivalHours'].hasError('max')">
                  {{ labels.hoursMax }} <strong>{{ labels.isMaxHours }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              class="align-third-Cedis-label size-Cedis-label">
              {{ labels.hours }}
            </mat-grid-tile>
            <mat-grid-tile
              class="align-four-Cedis-input">
              <mat-form-field>
                <input
                  id="lateArrivalMinutes"
                  (keypress)="onKeyPressCode($event)"
                  matInput
                  min="0"
                  max="59"
                  maxlength="2"
                  value="lateArrivalMinutes"
                  formControlName="lateArrivalMinutes"
                  (input)="onInputCedisAccess()"
                  type="number">
                <mat-error *ngIf="cedisAccessFormGroup.controls['lateArrivalMinutes'].hasError('required')">
                  {{ labels.minutesIs }} <strong>{{ labels.isRequired }}</strong>
                </mat-error>
                <mat-error *ngIf="cedisAccessFormGroup.controls['lateArrivalMinutes'].hasError('max')">
                  {{ labels.minutesMax }} <strong>{{ labels.isMaxMinutes }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile
              class="align-four-Cedis-label size-Cedis-label">
              {{ labels.minutes }}
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="1:.1">
            <mat-grid-tile>
              <button
                id="saveCedisAccess"
                mat-raised-button
                class="tenant-button margin-button"
                (click)="onSaveCedisAccess()"
                [disabled]="
                !isToleranceCedisValid ||
                !isCedisAccessValid ||
                cedisAccessFormGroup.invalid"
                color="primary">
                {{ labels.save }}
                </button>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </form>
  </div>

  <div>
    <mat-card *ngIf="tenantExists">
      <mat-card-header>
        <mat-card-title id="chargesCard">
          {{ labels.variableCharges }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="4" rowHeight="1:.2">
          <mat-grid-tile></mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                type="text"
                required
                id="newCharge"
                [(ngModel)]="newCharge"
                matInput
                [placeholder]="labels.newCharge"
                maxlength="20"/>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              mat-raised-button
              class="tenant-button"
              (click)="onAddNewCharge()"
              [disabled]="!newCharge">
              {{ labels.addCharge }}
            </button>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              mat-raised-button
              class="save-button"
              (click)="onSaveChanges()"
              [disabled]="!saveAvailable">
              {{ labels.saveChanges }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="4" rowHeight="1:.8">
          <mat-grid-tile></mat-grid-tile>
          <mat-grid-tile [colspan]="2" class="table--scroll">
            <table
              mat-table
              [dataSource]="dataSource"
              aria-describedby="chargesCard">
              <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef id="number">
                  {{ labels.number }}
                </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="charge">
                <th mat-header-cell *matHeaderCellDef id="charge">
                  {{ labels.chargeName }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.chargeName }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-grid-tile>
          <mat-grid-tile></mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <form [formGroup] = "withholdingsFormGroup" *ngIf="withholdingsFormGroup">
      <mat-card>
        <mat-card-header class="marginWithholdingsHeader">
          <mat-card-title>
            {{this.labels.withholdings}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="1:.05">
            <mat-grid-tile>
              <h4 class="h4-withholdings">{{ labels.freightsPlaceholder }}</h4>
            </mat-grid-tile>
            <mat-grid-tile>
              <h4 class="h4-withholdings">{{ labels.maneuversPlaceholder }} </h4>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="1:.2">
            <mat-grid-tile>
              <div class="border-withholdings">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span class="border-span">
                        {{ withholdingsFormGroup.value.freightsPercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container"></div>
                    <div class="lg-flex-container"></div>
                    <div class="xs-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="freightsPercentage"
                        min="0.1"
                        max="100"
                        step="0.1"
                        formControlName="freightsPercentage"
                        (change)="onInputFreights($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="border-withholdings">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span class="border-span">
                        {{ withholdingsFormGroup.value.maneuversPercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container"></div>
                    <div class="lg-flex-container"></div>
                    <div class="xs-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="maneuversPercentage"
                        min="0"
                        max="100"
                        step="0.1"
                        formControlName="maneuversPercentage"
                        (change)="onInputFreights($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="1" rowHeight="1:.1">
            <mat-grid-tile>
              <button
                id="saveWhithholdings"
                mat-raised-button
                class="primary-button button-container btn-withholdings"
                (click)="onSaveWithholdings()"
                [disabled] = "!isWithholdingsValid">
                {{ labels.save }}
              </button>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
      </mat-card>
    </form>
  </div>

  <div>
    <form [formGroup]="apportionmentFormGroup" *ngIf="apportionmentFormGroup">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{ this.labels.apportionmentParams }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="1:.05">
            <mat-grid-tile>
              <h4 class="h4Personalized"> {{ labels.piecesPlaceholder }} </h4>
            </mat-grid-tile>
            <mat-grid-tile>
              <h4 class="h4Personalized"> {{ labels.boxesPlaceholder }} </h4>
              </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="1:.2">
            <mat-grid-tile>
              <div class="border-params">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ apportionmentFormGroup.value.piecesPercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container"></div>
                    <div class="lg-flex-container"></div>
                    <div class="xs-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="piecesPercentage"
                        min="0"
                        max="100"
                        step="1"
                        formControlName="piecesPercentage"
                        (change)="onInputPieces($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="border-params">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ apportionmentFormGroup.value.boxesPercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container">
                    </div>
                    <div class="lg-flex-container">
                    </div>
                    <div class="xs-flex-container">
                    </div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="boxesPercentage"
                        min="0"
                        max="100"
                        step="1"
                        formControlName="boxesPercentage"
                        (change)="onInputBoxes($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="1:.05">
            <mat-grid-tile>
              <h4 class="h4Personalized"> {{ labels.palletsPlaceholder }} </h4>
            </mat-grid-tile>
            <mat-grid-tile>
              <h4 class="h4Personalized"> {{ labels.weightPlaceholder }} </h4>
              </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="1:.2">
            <mat-grid-tile>
              <div class="border-params">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ apportionmentFormGroup.value.palletsPercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container">
                    </div>
                    <div class="lg-flex-container">
                    </div>
                    <div class="xs-flex-container">
                    </div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="palletsPercentage"
                        min="0"
                        max="100"
                        step="1"
                        formControlName="palletsPercentage"
                        (change)="onInputPallets($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="border-params">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ apportionmentFormGroup.value.weightPercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container">
                    </div>
                    <div class="lg-flex-container">
                    </div>
                    <div class="xs-flex-container">
                    </div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="weightPercentage"
                        min="0"
                        max="100"
                        step="1"
                        formControlName="weightPercentage"
                        (change)="onInputWeight($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="1:.05">
            <mat-grid-tile>
              <h4 class="h4Personalized"> {{ labels.volumePlaceholder }} </h4>
            </mat-grid-tile>
            <mat-grid-tile>
              <h4 class="h4Personalized"> {{ labels.distancePlaceholder }} </h4>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="2" rowHeight="1:.2">
            <mat-grid-tile>
              <div class="border-params">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ apportionmentFormGroup.value.volumePercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container">
                    </div>
                    <div class="lg-flex-container">
                    </div>
                    <div class="xs-flex-container">
                    </div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="volumePercentage"
                        min="0"
                        max="100"
                        step="1"
                        formControlName="volumePercentage"
                        (change)="onInputVolume($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div class="border-params">
                <div class="sm-flex-container">
                  <div class="scf-flex-row">
                    <div class="full-flex-container"></div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="full-flex-container">
                      <span
                        class="border-span">
                        {{ apportionmentFormGroup.value.distancePercentage + labels.zeroPercentage }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="lg-flex-container">
                  <div class="scf-flex-row" style="height:20px">
                    <div class="xs-flex-container">
                    </div>
                    <div class="lg-flex-container">
                    </div>
                    <div class="xs-flex-container">
                    </div>
                  </div>
                  <div class="scf-flex-row">
                    <div class="slider-container">
                      <mat-slider
                        id="distancePercentage"
                        min="0"
                        max="100"
                        step="1"
                        formControlName="distancePercentage"
                        (change)="onInputDistance($event)">
                      </mat-slider>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-content>
        <mat-card-content>
          <mat-grid-list cols="2" rowHeight="0.3:.04">
            <mat-grid-tile>
              <h4 class="h4Personalized"> {{ labels.totalCalcule }} </h4>
              <h4 class="sumPers"> {{ sumApportPercentages() }} </h4>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-progress-bar
            id="totalPercentage"
            mode="determinate"
            [value]="sumApportPercentages()"
            [color]="getColor()"
            class="progressBarr mat-accent">
          </mat-progress-bar>
          <mat-grid-tile>
            <h4> {{ labels.totalCalcule }} </h4>
          </mat-grid-tile>
            <p *ngIf="apportionmentPercentages > 100"
              class="progressbarSaturated"> {{ labels.saturedProgressBar }} </p>
            <P *ngIf="apportionmentPercentages < 100"
              class="progressBarHalft">{{labels.halftProgressBar}} </P>
        </mat-card-content>

        <mat-card-content>
          <h4 class="specialConcept">{{ labels.specialConcepts }}</h4>
            <mat-card-content>
              <mat-grid-list cols="3" rowHeight="1:.30">
                <mat-grid-tile *ngIf="serviceTypes.length !== 0" >
                  <mat-form-field>
                    <mat-select
                      id="serviceType"
                      [placeholder]="labels.serviceTypes"
                      formControlName="serviceType"
                      disableOptionCentering="true"
                      [compareWith]="setSpecialConcept"
                      (selectionChange)="changed($event); specialConceptSelect()">
                      <mat-option *ngFor="let serviceType of serviceTypes" [value]="serviceType">
                        {{ serviceType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </mat-grid-tile>
                 <mat-grid-tile>
                      <div class="spanPercentage">
                        <span
                          class="border-span1">
                          {{ (apportionmentFormGroup.value.serviceTypePercentage || 0) + labels.zeroPercentage }}
                        </span>
                      </div>
                  <div class="sliderSpecialConcept">
                    <div class="scf-flex-row">
                    <mat-slider
                        id="serviceTypePercentage"
                        min="0"
                        max="100"
                        step="1"
                        formControlName="serviceTypePercentage"
                        (change)="onInputSpecialConcepts($event)"
                        [disabled]="isServiceTypeSelected !== true">
                      </mat-slider>
                      </div>
                  </div>
                </mat-grid-tile>
               <mat-grid-tile>
                    <button
                      id="saveApportionment"
                      mat-raised-button
                      class="tenant-button"
                      (click)="onSaveApportionment()"
                      [disabled] = "!isApportionmentValid">
                      {{ labels.save }}
                    </button>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card-content>
        </mat-card-content>
      </mat-card>
    </form>
  </div>

  <div *ngIf="!userIsAdmin">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.podInterfaceTitle }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <button
            mat-icon-button
            [matTooltip]="labels.addPodInterface"
            [ngClass]="{
              'disabled-effect-button':
                !availableAccounts || availableAccounts.length === 0
            }"
            matTooltipPosition="above"
            [disabled]="!availableAccounts || availableAccounts.length === 0"
            *ngIf="!addAccountPodEnable"
            class="icon-button"
            (click)="podFormToggle()">
            <img
              alt="Create disabled"
              id="createPod"
              src="../../../assets/create_warehouse.svg"
              width="40%"
              height="40%"/>
          </button>
        </div>
        <app-recurrence
          *ngIf="addAccountPodEnable"
          [allAccounts]="allAccounts"
          [availableAccounts]="availableAccounts"
          [recurrence]="recurrence"
          (cancelEvent)="cancelEvent()"
          (recurrenceExecution)="onCreatePod($event)">
        </app-recurrence>
        <mat-grid-list cols="4" rowHeight="1:.8" *ngIf="!addAccountPodEnable">
          <mat-grid-tile></mat-grid-tile>
          <mat-grid-tile [colspan]="2" class="table--scroll">
            <table
              mat-table
              [dataSource]="podDataSource"
              aria-describedby="podCard">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef id="account">
                  {{ labels.account }}
                </th>
                <td mat-cell *matCellDef="let account of accountsWithPOD">
                  {{ account.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="periodicity">
                <th mat-header-cell *matHeaderCellDef id="periodicity">
                  {{ labels.periodicity }}
                </th>
                <td mat-cell *matCellDef="let account of accountsWithPOD">
                  {{ account.periodicity }}
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th
                  id="actions"
                  [matTooltip]="labels.tooltipNoPermission"
                  [matTooltipDisabled]="manageResources"
                  mat-header-cell
                  *matHeaderCellDef
                  class="icon-column">
                  {{ labels.actions }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  class="functions"
                  style="white-space: nowrap">
                  <mat-icon
                    [ngClass]="{ 'disabled-effect': !manageResources }"
                    [matTooltip]="labels.tooltipEdit"
                    [matTooltipDisabled]="!manageResources"
                    class="icon-button"
                    matTooltipPosition="above"
                    (click)="onEditPod(row)">
                    edit
                  </mat-icon>
                  <mat-icon
                    [ngClass]="{ 'disabled-effect': !manageResources }"
                    [matTooltip]="labels.tooltipDelete"
                    [matTooltipDisabled]="!manageResources"
                    class="icon-button"
                    matTooltipPosition="above"
                    (click)="onDeletePod(row)">
                    delete
                  </mat-icon>
                </td></ng-container>

              <tr mat-header-row *matHeaderRowDef="podColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: podColumns"></tr>
            </table>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="!userIsAdmin">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div class="add-file">
            <span>{{ labels.logoTitle }}</span>
            <button
              (click)="openFileSelector()"
              class="icon-button"
              isIconButton="true">
              <mat-icon class="white-icon" aria-label="Browse and add new files">add</mat-icon>
            </button>
            <input
              type="file"
              name="files"
              id="inputFiles"
              (change)="selectFiles()"
              hidden
              accept=".jpg,.jpeg,.png"/>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="logo-container">
        <div>
          <p>{{ labels.logoDescription }}</p>
          <app-drag-and-drop
            [filesInDropZone]="imagesConverted"
            [filesAddedByButton]="filesAddedByButton"
            [multiple]="multipleFiles"
            [allowedFormatExtensions]="allowedExtensions"
            [maxFilesAllowed]="maxFilesAllowed"
            [label]="displayLabel"
            [maxImageResolution]="maxResolution"
            (filesDropped)="addedFilesHandler($event)">
          </app-drag-and-drop>
          <button
            [disabled]="!enableSaveLogo"
            mat-raised-button
            (click)="onSaveLogo()"
            class="primary-button button-container">
            {{ labels.save }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="!userIsAdmin">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.publicPortalTitle }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="5" rowHeight="1:.30">
          <mat-grid-tile colspan="2">
            <mat-slide-toggle
              class="public-portal-toggle"
              id="showAccountsToggle"
              [(ngModel)]="showOrdersInPublicPortal"
              color="primary">
              {{ labels.showOrdersInPublicPortal }}
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <app-select-search
              class="margin-19"
              id="accountSelector"
              [selectLabel]="accountSelectLabel"
              [controlName]="accountControlName"
              [results]="accounts"
              [isFirstPreload]="isFirstPreload"
              [isRequiredInput]=true
              [enableInput]="showOrdersInPublicPortal"
              [pre_results]="publicAccessAccountsSaved">
            </app-select-search>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              id="saveAccountsInfoBtn"
              [disabled]="isSavingAccountsInfo || noChangesInAccountsSelected"
              (click)="confirmSaveAccounts()"
              mat-raised-button
              class="save-accounts-btn">
              {{ labels.save }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="!userIsAdmin">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.titleTendering }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="container-subtitle">
          <div>
            <h2 class="subtitle">{{ labels.assignationBy }}</h2>
          </div>
          <hr class="hr-subtitle">
        </div>
        <form [formGroup]="biddingTenderingFormGroup" *ngIf="biddingTenderingFormGroup">
          <mat-grid-list cols="4" rowHeight="1:.3">
            <mat-grid-tile colspan="2">
              <mat-radio-group
                id="assignationTypeRadioGroup"
                formControlName="assignationType"
                class="radio-buttons-class">
                <mat-radio-button
                  id="manualOptionRadioButton"
                  (change)="onSelection($event)"
                  value="{{ labels.manual }}"
                  [checked]="(this.shipperTenderingConfig.assignationType === labels.manual || !this.shipperTenderingConfig.assignationType) ? true : false"
                  class="radio-buttons-class">{{ labels.manualAssignation }}
                </mat-radio-button>
                <mat-radio-button
                  id="automaticOptionRadioButton"
                  [checked]="this.shipperTenderingConfig.assignationType === labels.automatic ? true : false"
                  (change)="onSelection($event)"
                  value="{{ labels.automatic }}"
                  class="radio-buttons-class">{{ labels.automaticAssignation }}
                </mat-radio-button>
              </mat-radio-group>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="5" rowHeight="1:.3" *ngIf="isTenderingManualAssignation">
            <mat-grid-tile>
              <mat-form-field>
                <mat-label>{{ labels.sortOffersPlaceholder }}</mat-label>
                <mat-select
                  id="sortTypeSelect"
                  required
                  formControlName="sortOffersBy">
                  <mat-option
                    *ngFor="let types of sortTypes"
                    [id]="types.id"
                    [value]="types.name">{{ types.value }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <strong>{{ labels.orderOffersRequired }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-label>{{ labels.maximumOffersPlaceholder }}</mat-label>
                <mat-select
                  required
                  id="maximumOffersSelect"
                  formControlName="maximumOffers">
                  <mat-option
                    *ngFor="let number of maximumOffers"
                    [id]="number"
                    [value]="number">{{ number }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <strong>{{ labels.maximumOffersRequired }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <input
                  matInput
                  [placeholder]=labels.maximumshipmentsPlaceholder
                  (input)="validateQuantity()"
                  (focusout)="validateQuantity()"
                  type="number"
                  id="maximumShipmentsInput"
                  formControlName="maximumShipmentsPerCarrier">
                  <mat-error *ngIf="biddingTenderingFormGroup.controls['maximumShipmentsPerCarrier'].hasError('invalidQuantity')">
                    <strong>{{ labels.invalidNumber }}</strong>
                  </mat-error>
                  <mat-error *ngIf="biddingTenderingFormGroup.controls['maximumShipmentsPerCarrier'].hasError('pattern')">
                    <strong>{{ labels.errorDecimalNotAllowed }}</strong>
                  </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <mat-slide-toggle
                  id="showCarrierNameToggle"
                  [(ngModel)]="showCarrierNameInOffers"
                  (change)="showCarrierNameToggle(showCarrierNameInOffers)"
                  [ngModelOptions]="{standalone: true}"
                  color="primary">
                  {{ labels.displayCarrierNameInOffer }}
                </mat-slide-toggle>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="1:.3" *ngIf="isTenderingAutomaticAssignation">
            <mat-grid-tile>
              <mat-form-field>
                <mat-label>{{ labels.autoAssignByPlaceholder }}</mat-label>
                <mat-select
                  id="ParamToAutoAcceptSelect"
                  required
                  formControlName="autoAssignBy">
                  <mat-option
                    *ngFor="let param of parametersToAutomaticAssignation"
                    [id]="param.id"
                    [value]="param.value">{{ param.value }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  <strong>{{ labels.paramToAutoAssignRequired }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="this.biddingTenderingFormGroup.get('autoAssignBy').value === labels.timePeriod">
              <mat-form-field>
                <input
                  id="dayRangeInput"
                  matInput
                  [placeholder]=labels.daysOfferActive
                  formControlName="daysRangeActive"
                  required>
                <mat-error>
                  <strong>{{ labels.daysRangeActiveRequired }}</strong>
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
          <div class="container-subtitle">
            <div>
              <h2 class="subtitle">{{ labels.ratingTitle }}</h2>
            </div>
            <hr class="hr-subtitle">
          </div>
          <p class="title-rating">{{ labels.services }}</p>
          <p class="title-security-rating">{{ labels.security }}</p>
          <mat-grid-list class="margin-3" cols="4" rowHeight="1:.3">
            <mat-grid-tile colspan="2" class="checkbox-services-section">
              <div class="full-flex-container">
                <span *ngFor="let service of ratingServices"
                  [ngClass]="{'rating-service-span': service.description === labels.deliveryTime}">
                  <mat-checkbox
                    [checked]="paramSavedInShipper(service)"
                    class="rating-checkboxes"
                    (change)="onRateSelected(service, $event)"
                    [color]="'primary'">
                    {{ service.description }}
                  </mat-checkbox>
                </span>
              </div>
            </mat-grid-tile>
            <mat-grid-tile class="checkbox-security-section" colspan="2">
              <div>
                <span *ngFor="let param of ratingSecurity"
                  [ngClass]="{'rating-security-span': param.description === labels.accidents}">
                  <mat-checkbox
                    [checked]="paramSavedInShipper(param)"
                    class="rating-checkboxes"
                    (change)="onRateSelected(param, $event)"
                    [color]="'primary'">
                    {{ param.description }}
                  </mat-checkbox>
                </span>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
          <div class="container-subtitle">
            <div>
              <p class="title-costs-rating">{{ labels.cost }}</p>
            </div>
            <div>
              <p class="title-quality-rating">{{ labels.quality }}</p>
            </div>
          </div>
          <mat-grid-list class="margin-3" cols="4" rowHeight="1:.3">
            <mat-grid-tile colspan="2" class="checkbox-costs-section">
                <span *ngFor="let service of ratingCost"
                  class="cost-span-class">
                  <mat-checkbox
                    [checked]="paramSavedInShipper(service)"
                    class="rating-checkboxes"
                    (change)="onRateSelected(service,$event)"
                    [color]="'primary'">
                    {{ service.description }}
                  </mat-checkbox>
                </span>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" class="checkbox-quality-section">
              <span class="quality-span-class" *ngFor="let service of ratingQuality">
                <mat-checkbox
                  [checked]="paramSavedInShipper(service)"
                  class="rating-checkboxes"
                  (change)="onRateSelected(service,$event)"
                  [color]="'primary'">
                  {{ service.description }}
                </mat-checkbox>
              </span>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="5" rowHeight="1:.3">
            <mat-grid-tile>
              &nbsp;
            </mat-grid-tile>
            <mat-grid-tile>
              &nbsp;
            </mat-grid-tile>
            <mat-grid-tile>
              &nbsp;
            </mat-grid-tile>
            <mat-grid-tile>
              &nbsp;
            </mat-grid-tile>
            <mat-grid-tile>
              <button *ngIf="isTenderingManualAssignation || isTenderingAutomaticAssignation || isSavingTenderingInfo"
                id="saveTenderingConfigBtn"
                [disabled]="biddingTenderingFormGroup.invalid || (disableSaveButton && disableSaveButtonParams)"
                (click)="confirmSaveTenderingConfig()"
                mat-raised-button
                class="tendering-config-btn">
                {{ labels.save }}
              </button>
            </mat-grid-tile>
          </mat-grid-list>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ labels.invoiceProviderTitle }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="stampProviderFormGroup" *ngIf="stampProviderFormGroup">
          <mat-grid-list cols="4" rowHeight="1:.3" >
            <mat-grid-tile>
              <p> {{ labels.invoiceProviderLabel }} </p>
            </mat-grid-tile>
            <mat-grid-tile>
              <mat-form-field>
                <mat-select
                  [disabled]="!hasStampProviderList"
                  id="stampProvider"
                  required
                  formControlName="stampProvider">
                  <mat-option *ngFor="let stampProvider of stampProviderList" [value]="stampProvider">
                    {{ stampProvider.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
              <button
                mat-raised-button
                [disabled]="stampProviderFormGroup.invalid || !hasStampProviderList || !stampProviderViewChange"
                (click)="saveStampProvider()"
                class="primary-button">
                {{ labels.save }}
              </button>
            </mat-grid-tile>
          </mat-grid-list>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
