import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';
import { LOAD_PLAN_PROPERTIES } from '../../components/dialog/dialog-load-plan/dialog-load-plan-constants';
import { OrderByStop, OrderForShipment, OrdersApi, Shipments } from '../../interfaces';

/**
 * @description Service with methods used in load plan components to group and build shipment data.
 */
@Injectable()
export class ShipmentService {
  /**
   * @description Groups orders by stop in shipment.
   * @param {Shipments} shipment - Data from shipment to process.
   * @param {Array<OrdersApi>} fullOrdersData - Full orders data from orders associated to shipment.
   */
  public buildShipmentByStops(shipment: Shipments, fullOrdersData: Array<OrdersApi>): void {
    let stops = this.buildStopsFromShipment(shipment);
    stops = stops.sort((a: number, b: number) => {
      return b - a;
    });
    this.groupAndSetOrdersByStop(stops, fullOrdersData, shipment);
    this.setShipmentTotalQuantities(fullOrdersData, shipment);
  }

  /**
   * @description Checks and builds an array with all stops from shipment.
   * @param {Shipments} shipment - Data from shipment to check.
   * @returns {Array<number>} - An array with all number stops from shipment.
   */
  public buildStopsFromShipment(shipment: Shipments): Array<number> {
    return [...new Set(shipment.orders.map((item: OrderForShipment) => {
      return item.stop;
    }))];
  }

  /**
   * @description Groups full orders data by stop in shipment provided.
   * @param {Array<number>} stops -  An array with all number of stops in shipment.
   * @param {Array<OrdersApi>} orders - An array with full orders data associated to current shipment.
   * @param {Shipments} shipment - Shipment data to check.
   */
  public groupAndSetOrdersByStop(stops: Array<number>, orders: Array<OrdersApi>, shipment: Shipments) {
    shipment.ordersByStop = [];

    for (const stop of stops) {
      const ordersFromStop = shipment.orders.filter((order: OrderForShipment) => {
        return order.stop === stop;
      });

      const stopData: OrderByStop = {
        address: ordersFromStop[0].destination.address,
        isPortage: ordersFromStop[0].stopIsPortage,
        name: ordersFromStop[0].destination.name,
        orders: this.setFullOrdersDataFromStop(ordersFromStop, orders),
        plannedWarehouse: ordersFromStop[0].stopIsPortage ? ordersFromStop[0].plannedWarehouse : null,
        postalCode: ordersFromStop[0].destination.postalCode,
        settlement: ordersFromStop[0].destination.settlement,
        state: ordersFromStop[0].destination.state,
        stop: stop
      };

      shipment.ordersByStop.push(stopData);
    }
  }

  /**
   * @description Checks if shipment has any transport data.
   * @param {Shipments} shipment - Shipment to check.
   * @returns {boolean} True if shipment has carrier, vehicles, tariler, driver or helper assigned.
   */
  public isShipmentWithTransportData(shipment: Shipments): boolean {
    return Boolean(shipment.transport.transportCarrier || shipment.transport.plates || shipment.transport.trailer ||
      shipment.transport.driver || shipment.transport.helper);
  }

  /**
   * @description Builds an array with all different accounts associated in orders from shipment.
   * @param {Array<OrdersApi>} orders - All orders associated to shipment.
   * @returns {string} A string with all differents accounts associated in all orders from shipment.
   */
  public setAllAccountsInShipment(orders: Array<OrdersApi>): string {
    const uniqueAccounts = [...new Set(orders.map((order: OrdersApi) => {
      return ` ${order.account.name.trim()}`;
    }))];

    return uniqueAccounts.toString();
  }

  /**
   * @description Builds stops from shipment with all full orders data.
   * @param {Array<OrderForShipment>} ordersFromStop - Orders from stop (from shipment property).
   * @param {Array<OrdersApi>} orders - All frull orders from shipment.
   * @returns {Array<OrdersApi>} An array with full orders data associated to stop.
   */
  public setFullOrdersDataFromStop(ordersFromStop: Array<OrderForShipment>, orders: Array<OrdersApi>): Array<OrdersApi> {
    const allOrders: Array<OrdersApi> = [];

    for (const order of ordersFromStop) {
      const fullDataOrder = orders.find((item: OrdersApi) => {
        return item._id.toString() === order._id.toString();
      });
      allOrders.push(fullDataOrder);
    }

    return allOrders;
  }

  /**
   * @description Iterates over all orders associated in shipment to set real merchandise totals in shipment.
   * @param {Array<OrdersApi>} fullOrdersData - Full orders data from orders associated to shipment.
   * @param {Shipments} shipment - Data from shipment to process.
   */
  public setShipmentTotalQuantities(fullOrdersData: Array<OrdersApi>, shipment: Shipments): void {
    let totalBoxes = 0;
    let totalPallets = 0;
    let totalPieces = 0;

    for (const order of fullOrdersData) {
      totalBoxes = totalBoxes + order.boxes;
      totalPallets = totalPallets + order.pallets;
      totalPieces = totalPieces + order.pieces;
    }
    shipment.boxes = totalBoxes;
    shipment.pallets = totalPallets;
    shipment.pieces = totalPieces;
  }
}
