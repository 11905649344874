<div class="container-100-percent flex-class center-items-class modal-actions">
  <div class="flex-class center-items-class">
    <mat-label >{{ 'loadPlanTags.format' | translate }}</mat-label>
    <mat-button-toggle-group
      id="dlpc-load-plan-format-button-toggle-group"
      (change)="onLoadPlanVersion($event.value)"
      [value]="isLandscapeVersion">
      <mat-button-toggle
        id="dlpc-load-plan-portrait-format-button"
        [ngClass]="{'button-toggle-selected-class': !isLandscapeVersion}"
        [value]="false">{{
        'loadPlanTags.portrait' | translate }}
      </mat-button-toggle>
      <mat-button-toggle
        id="dlpc-load-plan-landscape-format-button"
        [ngClass]="{'button-toggle-selected-class': isLandscapeVersion}"
        [value]="true">
        {{ 'loadPlanTags.landscape' | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <button
    *ngIf="data.isPrintable"
    class="icon-button print-icon"
    id="dlpc-print-button"
    isIconButton="true"
    [useExistingCss]="true"
    printSectionId="dlpc-print-section"
    ngxPrint>
    <mat-icon class="print-icon">
      print
    </mat-icon>
  </button>
  <div class="close-dialog">
    <mat-icon id="dlpc-close-dialog-button" mat-dialog-close>
      close
    </mat-icon>
  </div>
</div>

<app-load-plan-landscape-version *ngIf="isLandscapeVersion"
  id="dlpc-print-section"
  [ordersData]="ordersPerPage"
  [ordersProductsData]="ordersProductsPerPage"
  [fullOrdersData]="data.fullOrdersData"
  [shipmentData]="data.shipment"
  [logo]="data.shipperLogo"
></app-load-plan-landscape-version>

<app-load-plan-portrait-version *ngIf="!isLandscapeVersion"
  id="dlpc-print-section"
  [ordersData]="ordersPerPage"
  [ordersProductsData]="ordersProductsPerPage"
  [fullOrdersData]="data.fullOrdersData"
  [shipmentData]="data.shipment"
  [logo]="data.shipperLogo"
></app-load-plan-portrait-version>
