export const SHIPMENT_CLASS_TYPES = [
  { value: 'Nacional', label: 'national' },
  { value: 'Internacional', label: 'international' },
];

export const SHIPMENT_CREATION_CONSTANTS = {
  ACCOUNT: 'accountId',
  ASSIGNEDSTATUS: 'Asignado',
  ASTERIK_LEFT: '* ',
  ASTERISK_RIGHT: ' *',
  BLANK: ' ',
  CANCEL: 'Cancel',
  CANCELDIALOGWIDTH: '650px',
  CAPTURED_GUIDES: 'capturedGuides',
  CLOSED: 'Closed',
  CONFIRM: 'Confirm',
  CONFIRM_AND_CONTINUE: 'CONFIRM_AND_CONTINUE',
  CONSUFFIX: 'CON',
  CONTINUE_CREATING_DIALOG_WIDTH: '600px',
  CONTINUE_PLANNING_ADDITIONAL_INFO_DIALOG_WITH: '700px',
  COUNTRY_ORIG_DEST_FORM: 'countryOriginDest',
  COURIER_SUFFIX: 'PAQ',
  COURRIER_GUIDE_PARAM: 'courierGuide',
  CREATIONDATE: 'creationDate',
  CUSTOMER_PICKUP_PREFIX: 'CR',
  DATE_FORMAT_TABLE: 'DD/MM/YYYY',
  DATEFORMAT: 'yyyy-MM-dd',
  DEFAULTSTATUS: 'Planeado',
  DELIVERYDATE: 'deliveryDate',
  DESTINATION: 'destination',
  DIALOG_MULTIPLE_ORDERS: '90vh',
  DIALOG_ORDERS_IN_ROUTE_ICON_PATH: '../../../assets/warningSign.svg',
  DIALOG_PANEL_CLASS: 'dialog-success-detail',
  DIALOGWIDTH: '1000px',
  DISPLAYCOLUMNS: ['select', 'status', 'Identifier', 'account', 'origin', 'Destination', 'Delivery Date'],
  DISPLAYCOLUMNSASSIGNEDORDERS: ['select', 'stop', 'status', 'identifier', 'account', 'origin', 'destinationId', 'destination', 'date', 'warehouse'],
  DISPLAYCOLUMNSROUTE: [
    'select',
    'status',
    'Identifier',
    'account',
    'routingStatus',
    'origin',
    'Destination',
    'Delivery Date',
    'temperature'
  ],
  DISPLAYCOLUMNSASSIGNEDORDERSROUTE: [
    'select', 'stop', 'status', 'identifier', 'account', 'routingStatus', 'origin', 'destinationId', 'destination', 'date', 'warehouse', 'temperature'
  ],
  EMPTY_STRING: '',
  ENTER_KEY: 13,
  ENTRY_EXIT_WAY_FORM: 'entryExitWay',
  FIRST_ELEMENT: 0,
  FOLIO_GUIDE_PARAM: 'folioGuides',
  FOLIO: 'folio',
  GREEN: 'rgb(94,172,82)',
  GUIDES_COLUMN: 'guides',
  ICON: 'info',
  ID: '_id',
  IDENTIFIER: 'identifier',
  INTERNALREFERENCE: 'internalReference',
  INTERNATIONAL: 'Internacional',
  JOIN_SYMBOL: ', ',
  KEY_PRESHIPMENT: 'preshipment',
  LOADTYPE: 'Entarimado',
  MAX_CAPACITY_PERCETN: 100,
  MUNICIPALITY: 'municipality',
  NAME: 'name',
  NATIONAL: 'Nacional',
  NOINFO: 'Sin Información',
  ORANGE: 'rgb(250,173,26)',
  PARAM_ROUTE_NAME: 'routeName',
  PLANNEDSTATUS: 'Planeado',
  PORTAGE: 'portage',
  PORTAGEWAREHOUSE: 'portageWarehouse',
  PREFIX: 'prefix',
  PX: 'px',
  RED: 'rgb(185,32,28)',
  REJECTED: 'Rechazada',
  SCANNED_STATUS_COMPLETE: 'Completa',
  SCANNED_STATUS_INCOMPLETE: 'Incompleta',
  SEARCH_CRITERIA: 'searchCriteria',
  SERVICE_TYPE: 'serviceType',
  SETTLEMENT: 'settlement',
  SHIPMENT_CLASS_FORM: 'shipmentClass',
  STATE: 'state',
  STATUS_PRESHIPMENTABLE: 'En Transito',
  STRING_ORDER_COUNT: '<orderCount>',
  STRING_TRIP_TYPE: '<tripType>',
  SUBFOLIO_INDEX: -5,
  TAB_KEY: 9,
  TEMPERATURE: 'temperature',
  TEPSUFFIX: 'TEP',
  TRIP_COLLECTION: 'Recolección',
  TRIP_CONSOLIDATED: 'Consolidado',
  TRIP_COURIER: 'Paquetera',
  TRIP_CUSTOMER_PICKUP: 'Cliente recoge',
  TRIPDIRECT: 'Directo',
  TRIPDISTRIBUTION: 'Reparto',
  TRIPPORTAGE: 'Porteo',
  TRIPPORTAGEDISTRIBUTION: 'Porteo con reparto',
  TRIP_TYPE_POSITION: 2,
  TRUCKCONTAINER: 'truckContainer',
  TRUCKCONTAINERWIDTH: '100px',
  UNASSIGNED: 'Sin Asignar',
  UNDELIVERED: 'No Entregada',
  VALID_TENANTS: ['6037d9cc4fe6993def62da45', '5f877f16b6c21a48cf2c49ff'],
  VEHICLE: 'vehicle',
  VIEW_ORDERS_ROUTE: '/order/view-orders',
  VOLUME_FLOAT_POINT: 4,
  VOLUME: 'Volumen',
  WAREHOUSE: 'warehouse',
  WAREHOUSENAME: 'warehouseName',
  WARNING_CAPACITY_PERCETN: 100,
  WEIGHT_ESP: 'Peso',
  WEIGHT_FLOAT_POINT: 4,
  WEIGHT: 'weight',
  ZERO_VALUE: 0,
};

export const SHIPMENT_ENTRY_EXIT_WAY_TYPES = [
  {
    desc: 'Entrada de mercancía',
    label: 'merchandiseEntry',
    value: 'Entrada',
  },
  {
    desc: 'Salida de mercancía',
    label: 'merchandiseExit',
    value: 'Salida',
  },
];

export const SHIPMENT_MEDISTIK_SERVICE_TYPES = [
  {
    symbol: 'A',
    description: 'Mensajería Express',
    label: 'expressMessaging',
  },
  {
    symbol: 'B',
    description: 'Mensajería Terrestre',
    label: 'messagingByEarth',
  },
  {
    symbol: 'C',
    description: 'Distribuidor Foráneo',
    label: 'foreignDistributor',
  },
  {
    symbol: 'D',
    description: 'Distribuidor Local',
    label: 'localDistributor',
  },
  {
    symbol: 'E',
    description: 'Distribuidor con Cita Foráneo',
    label: 'distributorWithForeignAppointment',
  },
  {
    symbol: 'F',
    description: 'Personalizado Seco',
    label: 'customDry',
  },
  {
    symbol: 'G',
    description: 'Personalizado Refrigerado',
    label: 'customRefrigerated',
  },
  {
    symbol: 'H',
    description: 'Ruta Local Seca',
    label: 'dryLocalRoute',
  },
  {
    symbol: 'I',
    description: 'Ruta Local Refrigerada',
    label: 'localRefrigeratedRoute',
  },
  {
    symbol: 'J',
    description: 'Ruta Local Seca antes de 13:00',
    label: 'dryLocalRouteBefore13',
  },
  {
    symbol: 'K',
    description: 'Ruta Local Refrigerada antes de 13:00',
    label: 'localRefrigeratedRouteBefore13',
  },
  {
    symbol: 'L',
    description: 'Ruta definida (Consolidado por calendario)',
    label: 'definedRouteConsolidatedByCalendar',
  },
  { symbol: 'M', description: 'Servicio Dedicado', label: 'dedicatedService' },
  { symbol: 'N', description: 'Cliente Recoge', label: 'customerPickUp' },
  { symbol: 'O', description: 'Consolidado Botox', label: 'consolidatedBotox' },
  { symbol: 'P', description: 'Promocionales', label: 'promotional' },
  {
    symbol: 'Q',
    description: 'Servicio Interalmacenes (Clientes)',
    label: 'interwarehouseClientsService',
  },
  {
    symbol: 'R',
    description: 'Personalizado con Custodia',
    label: 'personalizedWithCustody',
  },
  {
    symbol: 'T',
    description: 'Personalizado Sánchez-Allergan',
    label: 'personalizedSanchezAllergan',
  },
  { symbol: 'U', description: 'Material Peligroso', label: 'dangerousMaterial' },
  {
    symbol: 'V',
    description: 'Novo Ruta Local Refrigerada',
    label: 'novoRefrigeratedLocalRoute',
  },
  { symbol: 'W', description: 'Muestras Médicas', label: 'medicalSamples' },
  {
    symbol: '0',
    description: 'Terrestre Refrigerado',
    label: 'refrigeratedByEarth',
  },
  {
    symbol: '1',
    description: 'Terrestre Consolidado',
    label: 'consolidatedByEarth',
  },
  {
    symbol: '2',
    description: 'Mensajería + Porteo',
    label: 'messengerServicePortage',
  },
  {
    symbol: '3',
    description: 'Mensajería',
    label: 'messengerService',
  },
  {
    symbol: '4',
    description: 'Porteo Local',
    label: 'localPortage',
  },
];

export const SHIPMENT_ROUTING_STATUS = [
  { desc: 'Pendiente de envío a ruteo', value: 'Hold', label: 'hold' },
  { desc: 'En ruteador sin planear', value: 'OnRouter', label: 'onRouter' },
  { desc: 'Planeado en ruteador', value: 'Planned', label: 'planned' },
  { desc: 'Con embarque generado', value: 'Created', label: 'created' },
  { desc: SHIPMENT_CREATION_CONSTANTS.NOINFO, value: SHIPMENT_CREATION_CONSTANTS.NOINFO, label: 'noInformation' }
];

export const SHIPMENT_SPECIAL_REQUIREMENTS = [
  { value: 'Cubre boca', label: 'faceMask' },
  { value: 'Guantes', label: 'gloves' },
  { value: 'Lentes protectores', label: 'protectiveGlasses' },
  { value: 'Botas', label: 'boots' },
  { value: 'Sin barba', label: 'withoutBeard' },
  { value: 'Ópalo', label: 'opal' },
  { value: 'Casco', label: 'helmet' },
  { value: 'Chaleco', label: 'vest' },
  { value: 'Overol', label: 'overalls' },
  { value: 'Recurso Confiable', label: 'trustedResource' },
];

export const SHIPMENT_TEMPERATURE = [
  {
    value: 'Ambiente controlado acondicionado',
    label: 'controlledEnvironmentWithConditioning',
  },
  {
    value: 'Ambiente controlado sin acondicionar',
    label: 'controlledEnvironmentWithoutConditioning',
  },
  { value: 'Congelado acondicionado',
    label: 'frozenWithConditioning'
  },
  { value: 'Congelado sin acondicionar',
    label: 'frozenWithoutConditioning'
  },
  { value: 'Refrigerado acondicionado',
    label: 'refrigeratedWithConditioning'
  },
  {
    value: 'Refrigerado sin acondicionar',
    label: 'refrigeratedWithoutConditioning',
  },
  {
    value: 'Ambiente sin acondicionar',
    label: 'environmentWithoutConditioning',
  },
  {
    value: 'Ultracongelado acondicionado',
    label: 'deepfrozenWithConditioning',
  },
  {
    value: 'Ultracongelado sin acondicionar',
    label: 'deepfrozenWithoutConditioning',
  },
];

export const SHIPMENT_TRIP_TYPES = [
  { value: 'Recolección', label: 'collection' },
  { value: 'Consolidado', label: 'consolidated' },
  { value: 'Paquetera', label: 'courier' },
  { value: 'Cliente recoge', label: 'customerPickUp' },
  { value: 'Directo', label: 'direct' },
  { value: 'Reparto', label: 'distribution' },
  { value: 'Porteo', label: 'portage' },
  { value: 'Porteo con reparto', label: 'portageWithDistribution' },
];

export const SHIPMENT_SERVICE_TYPE = [
  { value: 'Normal / Genérico', label: 'normalOrGeneric'},
  { value: 'LTL Spot', label: 'LTLSpot'},
  { value: 'LTL Dedicado', label: 'LTLDedicated'},
  { value: 'FTL Spot', label: 'FTLSpot'},
  { value: 'FTL Dedicado', label: 'FTLDedicated'},
  { value: 'Paquetería', label: 'courier'},
  { value: 'Interbodega', label: 'interwarehouse'},
  { value: 'Logística inversa', label: 'reverseLogistics'},
]

export const SHIPMENT_STATUS = [
  { value: 'Asignado', label: 'assigned' },
  { value: 'Confirmado', label: 'confirmed' },
  { value: 'Costeado', label: 'costed' },
  { value: 'Eliminado', label: 'deleted' },
  { value: 'Entregado', label: 'delivered' },
  { value: 'Evidenciado', label: 'evidenced' },
  { value: 'En Sabana', label: 'inBillingSheet' },
  { value: 'En Transito', label: 'inTransit' },
  { value: 'Planeado', label: 'planned' },
  { value: 'Liberado', label: 'released' },
  { value: 'Enviado', label: 'sent' },
];
