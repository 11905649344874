import { Component, Input, OnInit } from '@angular/core';

import { DateToolsService } from '../../../services/utils/date-tools.service';
import { LOAD_PLAN_PROPERTIES } from '../../dialog/dialog-load-plan/dialog-load-plan-constants';
import { LoadPlanContentByStopAndOrder, LoadPlanContentByOrder, OrdersApi, ShipmentRowExtended } from '../../../interfaces';
import { LoadPlanService } from '../../../services/load-plan/load-plan.service';
import { ShipmentService } from '../../../services/shipment/shipment.service';

@Component({
  selector: 'app-load-plan-landscape-version',
  styleUrls: ['./load-plan-landscape-version.component.scss', '../../../app.component.scss'],
  templateUrl: './load-plan-landscape-version.component.html'
})

/**
 * @description Component to display load plan in landscape version.
 */
export class LoadPlanLandscapeVersionComponent implements OnInit {
  @Input() public fullOrdersData: Array<OrdersApi>;
  @Input() public logo: string;
  @Input() public ordersData: Array<LoadPlanContentByStopAndOrder>;
  @Input() public ordersProductsData: Array<LoadPlanContentByOrder>;
  @Input() public shipmentData: ShipmentRowExtended;
  @Input() public shouldDisplayProductsDetails: boolean;

  public boxesIcon: string;
  public currentDate: Date;
  public ordersIcon: string;
  public palletsIcon: string;
  public piecesIcon: string;
  public shouldHideRequirementsSection: boolean;
  public stopsIcon: string;

  /**
   * @description Constains intances from services or providers used in this component.
   * @param {DateToolsService} dateToolsService - Service with methods related to dates formats.
   * @param {LoadPlanService} loadPlanService - Service with main methods related with display info from contained load plan.
   * @param {ShipmentService} shipmentService - Service with call to methods related to display or format shipments info.
   */
  constructor(
    public dateToolsService: DateToolsService,
    public loadPlanService: LoadPlanService,
    public shipmentService: ShipmentService
  ) {
    this.ordersIcon = LOAD_PLAN_PROPERTIES.ordersIcon;
    this.piecesIcon = LOAD_PLAN_PROPERTIES.piecesIcon;
    this.boxesIcon = LOAD_PLAN_PROPERTIES.boxesIcon;
    this.palletsIcon = LOAD_PLAN_PROPERTIES.palletsIcon;
    this.stopsIcon = LOAD_PLAN_PROPERTIES.stopsIcon;
    this.currentDate = new Date();
  }

  /**
   * @description Angular lifecycle hook for component init.
   */
  public ngOnInit(): void {
    this.shouldHideRequirementsSection = this.loadPlanService.shouldHideRequirementsSection(this.shipmentData);
  }
}
