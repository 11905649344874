export const GenericRegexp = {
  ALPHANUMERIC_ACCENTS: /^(?!.*\s{2})[a-zA-ZÀ-ÿ0-9 ]+$/,
  ALPHANUMERIC_ACCENTS_HYPHENS: /^(?!.*\s{2})[a-zA-ZÀ-ÿ0-9\-_]+$/,
  ALPHANUMERIC_ACCENTS_HYPHENS_DOTS_COMMA_SEMICOLON_PARENTHESES_AMPERSAND: /^(?!.*\s{2})[a-zA-ZÀ-ÿ0-9\-_/ .,;:()\s.&]+$/,
  ALPHANUMERIC_ACCENTS_HYPHENS_DOTS_PARENTHESES_AMPERSAND: /^(?!.*\s{2})[a-zA-ZÀ-ÿ0-9\- .()\s.&]+$/,
  ALPHANUMERIC_ACCENTS_MIDDLEDASH_DOTS_PARENTHESES: /^(?!.*\s{2})[a-zA-ZÀ-ÿ0-9\- .()\s.]+$/,
  ALPHANUMERIC_HYPHENS: /^(?!.*\s{2})[a-zA-Z0-9\-_]+$/,
  ALPHANUMERIC_HYPHENS_DOTS_PARENTHESES: /^(?!.*\s{2})[a-zA-Z0-9\-_ .()\s.]+$/,
  ALPHANUMERIC_HYPHENS_DOTS_PARENTHESES_SLASH: /^(?!.*\s{2})[a-zA-Z0-9\-_ .()\s.\/ñÑáéíóúüÁÉÍÓÚÜ]+$/u,
  ALPHANUMERIC_HYPHENS_QUOTE_SPACE_PATTERN:
    /^[\p{L}\d](?!.*--)(?!.*'')(?!.*\s\s)(?!.*-\s)(?!.*\s-)(?!.*'\s)(?!.*\s')(?!.*-')(?!.*'-)[\p{L}\d\s'-]{1,48}[\p{L}\d]$/u,
  ALPHANUMERIC_ONLY: /[^a-zA-Z0-9]/g,
  ALPHANUMERIC_ORDER_REFFERENCE: /^(?!.*\s{2})[a-zA-ZÀ-ÿ0-9\-_/ .#,;:()\s.&']+$/,
  CAPITAL_AND_LOWERCASE_LETTER_NUMBER_AND_SPECIAL_CHARACTER: /^(?=.*[\W_])(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[^\s]{8,20}$/,
  DATE_DDMMYYYY: /^(0[1-9]|[1-2]\d|3[01])(\/|-)(0[1-9]|1[012])\2(\d{4})$/,
  DELETE_HTML_TAGS: /<[^>]*>?/gm,
  DELIMITER_SLASH_HYPHEN: /[-/]/,
  DIACRITIC_REMOVE: /\p{Diacritic}/gu,
  EMAIL: /^(?=.{5,50}$)[a-z0-9](?!.*[._-]{2})[a-z0-9._-]*[a-z0-9]@[a-z0-9]+[a-z0-9.-]+(\.[a-z0-9])*\.([a-z0-9]+)$/u,
  FORMAT_ISO_DATE: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/,
  FORMAT_ISO_DATE_WITHOUT_SECONDS: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,
  LETTERS_ACCENTS_MIDDLEDASH: /^(?!.*\s{2})[a-zA-ZÀ-ÿ- ]+$/,
  NUMBERS_STRING: /\d+/g,
  NUMERICAL_NUMBER: /\d+$/,
  HAS_ACCENTED_LETTERS: /[áéíóúÁÉÍÓÚ]/,
  HAS_BLANKS: /\s/,
  LETTER: /[a-z]/i,
  LETTERS_DOTS_WHITE_SPACES: /^(?!.*\s{2})[a-zA-ZÀ-ÿ.\s]+$/,
  LOWERCASE_LETTERS_NUMBERS_HYPENS_DOTS_AT_SIMBOL:
  /^[a-z\d](?!.*@.*@)(?!.*--)(?!.*__)(?!.*\.\.)(?!.*-\.)(?!.*\.-)(?!.*_\.)(?!.*\._)(?!.*@\.)(?!.*\.@)(?!.*-_)(?!.*_-)[a-z\d_@.-]{1,48}[a-z\d]$/u,
  MOBILE_PATTERN: /^[0-9]*$/,
  MULTIPLE_SEARCH_SPLIT: /[\,\n\r\t\f\v]/,
  MULTIPLE_SPLIT_STRING: /;|,|\n/,
  NON_BLANKS_START_STRING: /^\s*\S+(?: \S+)*\s*$/,
  NON_DECIMAL_ALLOWED: /^[^.]*$/,
  NON_EMPTY_STRING: /^(?!\s*$).+/,
  NON_POINT_ALLOWED: /\./g,
  NUMBER: /\d+/,
  NUMBERS_AND_LETTERS: /^[A-Za-z0-9]*$/,
  NUMBER_WITH_COMMAS: /\B(?=(\d{3})+(?!\d))/g,
  NUMBER_WITH_THREE_DECIMALS_POINTS: /^\d*(?:[.,]\d{1,3})?$/,
  NUMBER_WITH_TWO_DECIMALS_POINTS: /^\d*(?:[.,]\d{1,2})?$/,
  ONLY_ALPHANUMERIC: /^(?!.*\s{2})[a-zA-Z0-9 ]+$/,
  ONLY_ALPHANUMERIC_HYPHEN_WITHOUT_SPACE: /^(?!.*--)[a-zA-Z0-9\-]+$/,
  ONLY_NUMBERS: /[0-9\b]/,
  ONLY_ONE_DECIMAL: /^\d*\.?\d{0,1}$/,
  ONLY_POSITIVE_NUMBERS: /[0-9]/,
  ONLY_THREE_DECIMAL: /^\d*\.?\d{0,3}$/,
  ONLY_TWO_DECIMAL: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
  PATTERN_RFC: /([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])/,
  PHONE_REGEXP: /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/,
  REG_COMMA: /,/g,
  REG_SPACE: / /g,
  REG_SLASH_MIDDLE_DASH: /[\/-]/,
  REMOVE_SEMI_COLONS: /:/g,
  SEPARATE_20_CHARACTERS: /.{1,20}/g,
  SEVEN_DIGITS_INTERGERS_POSITIVES: /^(0|[1-9]\d{0,6})$/,
  THOUSANDS_SEPARATOR: /\B(?=(\d{3})+(?!\d))/g,
  VALIDATE_TRAILER_PLATES: /^(?!.* {2})[A-Z0-9 -]*$/,
  XLS_EMPTY_COLUMN: /__EMPTY*/
};
