export const LOAD_PLAN_PROPERTIES = {
  availableRowsForFirstPageInLandscapeVersion: 6,
  availableRowsForFirstPageInPortraitVersion: 9,
  availableRowsForPagesInLandscapeVersion: 13,
  availableRowsForPagesInPortraitVersion: 17,
  boxesIcon: '../../../assets/icons/new-load-plan-icons/boxes.svg',
  defaultShipperLogo: '../../../assets/icons/solutions/tepLogo2.jpg',
  fiveRowsFull: 5,
  fourRowsFull: 4,
  lastCharactersToRemove: -2,
  loadPlanLandscapeModalHeight: '95%',
  loadPlanLandscapeModalwidth: '100%',
  loadPlanPortraitModalWidth: '62%',
  maxCharactersForAccountsSection: 60,
  maxCharactersForOrderRows: 20,
  maxDecimals: 2,
  maxMerchandiseValueAllowed: 10000,
  maxValueForMillions: 999999999,
  maxValueForThousands: 999999,
  millionDivider: 1000000,
  minsAditionalCharactersForFiveRows: 570,
  minsAditionalCharactersForFourRows: 387,
  minsAditionalCharactersForSixRows: 762,
  minsAditionalCharactersForThreeRows: 199,
  minsSpecialRequirementsForFiveRows: 7,
  minsSpecialRequirementsForFourRows: 5,
  minsSpecialRequirementsForSixRows: 9,
  minsSpecialRequirementsForThreeRows: 3,
  ordersIcon: '../../../assets/icons/new-load-plan-icons/orders.svg',
  palletsIcon: '../../../assets/icons/new-load-plan-icons/pallets.svg',
  piecesIcon: '../../../assets/icons/new-load-plan-icons/pieces.svg',
  rowsOccupiedByRequirementsSection: 2,
  rowsRequiredForInternalReferenceField: 2,
  sixRowsFull: 6,
  stopsIcon: '../../../assets/icons/new-load-plan-icons/stops.svg',
  symbolForMillions: 'M',
  symbolForThousands: 'K',
  thousandDivider: 1000,
  threeRowsFull: 3,
  twoRowsFull: 2
};
