import { Injectable } from '@angular/core';

import { CHARGE_TYPES } from '../../constants/charge-type.constants';
import { ChargeTypes } from '../../enums/charge-types';
import { SHIPMENT_SERVICE_TYPE } from '../../constants/shipment.constants';
import { ShipmentPropertiesLabels } from '../../interfaces/shipment';

@Injectable()
export class ShipmenDataTranslateService {
  constructor(
  ) {}

  /**
   * @description receives value from shipment load type and returns label translated
   * @param {string} loadTypevalue load type value
   * @param {ShipmentPropertiesLabels} shipmentPropertiesLabels Object with some shipment properties labels
   * @returns {string} label found for load type
   */
  public setLoadTypeLabel(loadTypevalue: string, shipmentPropertiesLabels: ShipmentPropertiesLabels): string {
    const mixLoadType = 2;
    if (loadTypevalue && shipmentPropertiesLabels) {
      switch (loadTypevalue.toLowerCase()) {
        case CHARGE_TYPES[0].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentLoadTypes.bulk;
        }
        case CHARGE_TYPES[mixLoadType].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentLoadTypes.mixed;
        }
        default: {
          return shipmentPropertiesLabels.shipmentLoadTypes.pallets;
        }
      }
    } else {
      return shipmentPropertiesLabels.shipmentLoadTypes.pallets;
    }
  }

  /**
   * @description receives value from shipment service and returns label translated
   * @param {string} serviceTypeValue service type value to found label
   * @param {ShipmentPropertiesLabels} shipmentPropertiesLabels Object with some shipment properties labels
   * @returns {string} label found for service type
   */
  public setServiceTypeLabel(serviceTypeValue: string, shipmentPropertiesLabels: ShipmentPropertiesLabels): string {
    if (serviceTypeValue && shipmentPropertiesLabels) {
      switch (serviceTypeValue.toLowerCase()) {
        case SHIPMENT_SERVICE_TYPE[1].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentServiceTypes.LTLSpot;
        }
        case SHIPMENT_SERVICE_TYPE[2].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentServiceTypes.LTLDedicated;
        }
        case SHIPMENT_SERVICE_TYPE[3].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentServiceTypes.FTLSpot;
        }
        case SHIPMENT_SERVICE_TYPE[4].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentServiceTypes.FTLDedicated;
        }
        case SHIPMENT_SERVICE_TYPE[5].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentServiceTypes.courier;
        }
        case SHIPMENT_SERVICE_TYPE[6].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentServiceTypes.interwarehouse;
        }
        case SHIPMENT_SERVICE_TYPE[7].value.toLowerCase(): {
          return shipmentPropertiesLabels.shipmentServiceTypes.reverseLogistics;
        }
        default: {
          return shipmentPropertiesLabels.shipmentServiceTypes.normalOrGeneric;
        }
      }
    } else {
      return shipmentPropertiesLabels.shipmentServiceTypes.normalOrGeneric;
    }
  }

  /**
   * @description receives value from shipment charge type and returns label translated
   * @param {string} chargeTypevalue charge type value to found label
   * @param {ShipmentPropertiesLabels} shipmentPropertiesLabels Object with some shipment properties labels
   * @returns {string} label found for charge type
   */
  public setTypeOfChargeLabel(chargeTypevalue: string, shipmentPropertiesLabels: ShipmentPropertiesLabels, returnDefaultValue?: boolean): string {
    if (chargeTypevalue && shipmentPropertiesLabels) {
      if (chargeTypevalue === ChargeTypes.unitName) {
        return shipmentPropertiesLabels.shipmentTypeOfCharges.unit;
      } else if (chargeTypevalue === ChargeTypes.deliveryName) {
        return shipmentPropertiesLabels.shipmentTypeOfCharges.delivery;
      } else if (returnDefaultValue) {
        return shipmentPropertiesLabels.shipmentTypeOfCharges.delivery;
      }
    } else if (returnDefaultValue) {
      return shipmentPropertiesLabels.shipmentTypeOfCharges.delivery;
    }
  }
}
