import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';
import { GenericRegexp } from 'src/app/regexp/generic.regexp';

const KEY_CONFIRM_PASSWORD = 'confirmPassword';
const KEY_PASSWORD = 'password';

@Injectable()
export class FormsService {
  public noWhitespaceValidator (control: UntypedFormControl): object {
    const controlStr: string = (control.value || '').trim();
    const isWhiteSpace = controlStr.length === 0;
    const isValid = !isWhiteSpace;

    return isValid ? null : { whitespace: true };
  }

  /**
   * @description Custom validator that checks there's no special characters in a string
   * @param control The Form Control that will be validate
   * @return Return a symbols ValidationError if there's any special characters or return null
   */
  public symbolsValidator(control: UntypedFormControl): ValidationErrors | null {
    if (!/^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>¿?,./-]*$/.test(control.value)) {
      return { symbols: true };
    }
    return null;
  }

  /**
   * @description Custom Validator that helps to verify if two strings are the same
   * It is meant to be used in password forms
   * @param control the form group that should contains at least two fields for password and confirmPassword
   * @return Return a incorrect and notMatch ValidationError if there is something wrong or return null
   */
  public passwordValidator (control: UntypedFormGroup): ValidationErrors | null {
    const password = control.get(KEY_PASSWORD);
    const confirm = control.get(KEY_CONFIRM_PASSWORD);

    if (password && confirm && password.value !== confirm.value) {
      control.controls[KEY_CONFIRM_PASSWORD].setErrors({ incorrect: true });
    } else {
      control.controls[KEY_CONFIRM_PASSWORD].setErrors(null);
    }

    return password && confirm && password.value !== confirm.value ? { notMatch : true } : null;
  }

  /**
   * @description Custom Validator that checks if there is a space between the string of a form control
   * @param control the Form Control that will be validate
   * @return Return a whitespace ValidationError if there is a withespace or return null
   */
  public inbetweenSpaceValidator (control: UntypedFormControl): ValidationErrors | null {
    const controlString: string = (control.value || '');

    return controlString.indexOf(' ') >= 0  ? { whitespace: true } : null;
  }

  /**
   * @description Custom validator that checks if there is a space at the beginning of the string
   * @param control the Form Control that will be validate
   * @return Return a whitespace ValidationError if there is a withespace or return null
   */
  public beginSpaceValidator (control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) { return null; }

    const controlString: string = (control.value || '');
    return controlString.indexOf(' ') === 0 ? { whitespace: true } : null;
  }

  /**
   * @description Custom validator that checks if there is a space at the end of the string
   * @param control the Form Control that will be validate
   * @return Return a whitespace ValidationError if there is a withespace or return null
   */
  public endSpaceValidator (control: UntypedFormControl): ValidationErrors | null {
    if (!control.value) { return null; }

    const controlString: string = (control.value || '');
    return controlString.lastIndexOf(' ') >= controlString.length - 1 ? { whitespace: true } : null;
  }

  /**
   * @description Custom validator that checks if there is a letter with accent letter of the string
   * @param control The Form Control that will be validated
   * @return Returns an accentedLetter ValidationError if there is any accented letter else returns null
   */
  public accentedLettervalidator(control: UntypedFormControl): ValidationErrors | null {
    if  (GenericRegexp. HAS_ACCENTED_LETTERS.test(control.value)) {
      return { accentedLetter: true };
    }
    return null;
  }

  /**
   * @description Check if the field value is empty or not
   * @param {AbstractControl} control The form control to validate
   * @returns {boolean} If is valid return true, else return false
   */
  public isControlEmpty(control: AbstractControl): boolean {
    return !control ||
      control.value === null ||
      (control.value.toString() ?? AppConstants.EMPTY_STRING).trim() === AppConstants.EMPTY_STRING;
  }
}
