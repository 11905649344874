export enum BillingSheetProperties {
  onGenerateDialogSize = '640px'
}

export const BILLING_SHEET_CONSTANTS = {
  ACCOUNT_KEY: 'accounts',
  ACCOUNT: 'Cuenta',
  CARRIER_KEY: 'carrier',
  CHARGE_NAME_KEY: 'chargeName',
  CONFIRM: 'Confirm',
  COST_REVIEW_MODULE: 'revisión de cargos',
  CURRENTDATEFORMAT: 'yyyyMMdd',
  DATEFORMAT: 'day',
  DATE_ON_ROUTE_KEY: 'dateOnRoute',
  DELIVERY_CHARGE: 'Reparto',
  DELIVERY_WITHHOLDING: 'Retención Total Reparto',
  DISPLAYED_CHARGES_COMMENTS_COLUMNS: [
    'chargeName',
    'chargeAmount',
    'chargeAccount',
    'chargeComments'
  ],
  DRIVER: 'driver',
  FOUR_DECIMALS: 4,
  FREIGHTS: 'Fletes',
  FREIGHTSWITHHOLDING: 'Retención Flete',
  FREIGHTSWITHHOLDING_KEY: 'freightsWithHolding',
  FREIGTH: 'Flete',
  INCOME_TAX_PERCENT: 0.0125,
  INCOME_TAX_WITHHOLDING: 'Retención de ISR',
  INCOMETAXWITHHOLDING_KEY: 'incomeTaxWithholding',
  INROUTEDATE: 'Fecha en Ruta',
  MANEUVERSWITHHOLDING_KEY: 'maneuversWithHolding',
  MANEUVER: 'Maniobra',
  MANEUVERS: 'Maniobras',
  MANEUVERSWITHHOLDING: 'Retención Maniobras',
  MAX_LENGTH_COLUMN_TEXT: 17,
  ONBILLINGSHEETSTATUS: 'En Sabana',
  PAGE_SIZE: 5,
  PAGE_SIZE_OPTIONS: [5, 10, 15],
  PREFIX: 'SF',
  REJECTION_CHARGE: 'Rechazo',
  REJECTION_WITHHOLDING: 'Retención Total Rechazo',
  RETURN_CHARGE: 'Devolución',
  RETURN_WITHHOLDING: 'Retención Total Devolución',
  REVIEW_PAYMENT_SUPPLIER_MODULE: 'revisión de pago a proveedores',
  SHIPMENT: 'Embarque',
  SHIPMENT_ID_KEY: 'shipmentId',
  SIMPLIFIED_TRUST_REGIME_CODE: '626',
  SUBTOTAL_KEY: 'subtotal',
  SUBTOTAL: 'Subtotal',
  SUPPLIER_KEY: 'supplier',
  SYMBOL_CURRENCY: '$',
  TIMEOUT: 500,
  TOTAL_RETENTION_KEY: 'totalRetention',
  TOTAL: 'Total',
  TOTAL_KEY: 'total',
  TWO_DECIMALS: 2,
  VAT_KEY: 'vat',
  VAT: 'IVA',
  VATPERCENT: 0.16,
  WITHHOLDINGS: 'Retención Total'
};

export const CLASS_KEYS = { negativeCharge: 'negative-charge' };

export const ADDITIONAL_CHARGE_VALUES = { defaultId: 9999 };
