import { Injectable } from '@angular/core';
import { sumBy, isEqual } from 'lodash';

import { AppConstants } from '../constants/app-constants.constants';
import { FormGroupValuesDynamic } from  '../interfaces/form-group-dynamic'
import { GenericRegexp } from '../regexp/generic.regexp';
import { VariableType } from '../constants/variable-type';

@Injectable()
export class UtilsService {
  constructor() { }

  /**
   * @description Evaluates the values. if they are valid and has the same value.
   * The properties valid is type string, number, boolean and arrays of string or numbers.
   * This not support objects complex
   * @param {string | boolean | number | object} value to validate
   * @param {string | boolean | number | object} valueCompare to compare
   * @returns {boolean} value true or false
   */
  public equalsTo(
    value: string | boolean | number | object,
    valueCompare: string | boolean | number | object
  ): boolean {

    if (isEqual(value, valueCompare)) {
      return true;
    }

    if (
      this.isNullOrUndefined(value) &&
      this.isNullOrUndefined(valueCompare)
    ) {
      return true;
    }

    if (
      this.isString(value) &&
      this.isString(valueCompare) &&
      value === valueCompare
    ) {
      return true;
    }

    if (
      this.isNumber(value) &&
      this.isNumber(valueCompare) &&
      value === valueCompare
    ) {
      return true;
    }

    if (
      this.isBoolean(value) &&
      this.isBoolean(valueCompare) &&
      value === valueCompare
    ) {
      return true;
    }

    if (
      this.isArray(value) &&
      this.isArray(valueCompare) &&
      this.compareArrays(value as Array<object>, valueCompare as Array<object>)
    ) {
      return true;
    }

    return false;
  }

  /**
   * @description Validate if the the value is type 'string' and is empty, True it is.
   * this only work with type string
   * @param {string | object} value to validate
   * @returns {boolean} value true or false
   */
  public isStringEmpty(value: string | boolean | number | object): boolean {
    if (
      !this.isNullOrUndefined(value) &&
      this.isString(value) &&
      value.toString().trim() === AppConstants.EMPTY_STRING
    ) {
      return true;
    }

    return false;
  }

  /**
   * @description Validate if the the value is type 'string', True it is
   * @param {string | boolean | number | object} value to validate
   * @returns {boolean} value true or false
   */
  public isString(value: string | boolean | number | object): boolean {
    return typeof value === VariableType.String;
  }

  /**
   * @description Validate if the the value is type 'number', True it is
   * @param {string | boolean | number | object} value to validate
   * @returns {boolean} value true or false
   */
  public isNumber(value: string | boolean | number | object): boolean {
    return typeof value === VariableType.Number;
  }

  /**
   * @description Validate if the the value is type 'boolean', True it is
   * @param {string | boolean | number | object} value to validate
   * @returns {boolean} value true or false
   */
  public isBoolean(value: string | boolean | number | object): boolean {
    return typeof value === VariableType.Boolean;
  }

  /**
   * @description Validate if the the value is type 'undefined' or 'null', True it is
   * @param {string | boolean | number | object} value to validate
   * @returns {boolean} value true or false
   */
  public isNullOrUndefined(value: string | boolean | number | object): boolean {
    return value === undefined || value === null;
  }

  /**
   * @description Validate if the the value is type 'Array or []' and not empty, True it is
   * @param {string | boolean | number | object | Array<object>} value to validate
   * @returns {boolean} value true or false
   */
  public isArrayAndNotEmpty(value: string | boolean | number | object | Array<object>): boolean {
    return this.isArray(value) && Object.values(value).length > AppConstants.ZERO;
  }

  /**
   * @description Validate if the the value is type 'Array or []'
   * @param {string | boolean | number | object | Array<object>} value to validate
   * @returns {boolean} value true or false
   */
  public isArray(value: string | boolean | number | object | Array<object>): boolean {
    return !this.isNullOrUndefined(value) && Array.isArray(value);
  }

  /**
   * @description Verify if arrayOne and arrayTwo are the same and with same values.
   * This only works for arrays of type string or number
   * @param {Array<object>} arrayOne to validate
   * @param {Array<object>} arrayTwo to compare
   * @returns {boolean} value true or false
   */
  public compareArrays(arrayOne: Array<object>, arrayTwo:Array<object>): boolean {
    return arrayOne.length === arrayTwo.length
      && arrayOne.every((element, index) => element === arrayTwo[index]);
  }

  /**
   * @description Order array elements alphabetically 'asc'.
   * @param {Array<object>} dataSource - Array to be sorted.
   * @param {string} attributeToSortBy - Attribute to sort by.
   * @param {boolean} setTrueValueAsPriority - Set to true to prioritize objects with the 'true' value.
   */
  public orderByAsc(dataSource: Array<object>, attributeToSortBy: string,
    setTrueValueAsPriority: boolean = false): void {
    const one = 1;
    dataSource.sort((valueA, valueB) => {
      const elementA = valueA[attributeToSortBy].toUpperCase().trim();
      const elementB = valueB[attributeToSortBy].toUpperCase().trim();

      if (setTrueValueAsPriority) {
        const resultA = Object.values(valueA).find(item => typeof item === VariableType.Boolean.valueOf());
        const resultB = Object.values(valueB).find(item => typeof item === VariableType.Boolean.valueOf());

        if (resultA) {
          return -one;
        }

        if (resultB) {
          return one;
        }
      }

      return (elementA < elementB) ? -one : (elementA > elementB) ? one : AppConstants.ZERO;
    });
  }

  /**
   * @description compares the initial values with those that currently exist in the form and determines
   * if there are differences. the properties valid is type string, number, boolean and arrays of string or numbers,
   * this not support objects complex.
   * @param {FormGroupValuesDynamic} initialValues before changes by user
   * @param {FormGroupValuesDynamic} values values that currently exist in the form
   * @returns {boolean} value true or false
   */
  public hasChangesFormSimple(initialValues: FormGroupValuesDynamic, values: FormGroupValuesDynamic): boolean {
    let hasChanges =  false;
    Object.keys(values).forEach((key) => {
      const value = values[key];

      if (this.isStringEmpty(value)) {
        values[key] = null;
      } else if (value instanceof Date) {
        values[key] = value.toISOString();
      } else {
        values[key] = value;
      }
    });

    hasChanges =
      Object.keys(initialValues)
      .some(key => !this.equalsTo(values[key], initialValues[key]));

    return hasChanges;
  }

  /**
   * @description Convert the value to new value of type number, if the convertion fail
   * this return undefined
   * @param {string} value to convert
   * @returns {number | undefined} new value
   */
  public castNumber(value: string): number | undefined {
    const newValue = parseFloat(value);
    return isNaN(newValue) ? undefined : newValue;
  }

  /**
   * @description Searches for a specific property on an object and returns its value if found, or undefined if not found
   * @param {object} item as datasource to find
   * @param {string} propertyName to find
   * @returns {Array<object>} new array
   */
  public findProperty(item: object, propertyName: string): Array<object> {
    const elements = [];
    for (let key in item) {
      if (key === propertyName) {
        elements.push(item[key]);
      } else if (typeof item[key] === 'object') {
        elements.push(...this.findProperty(item[key], propertyName));
      }
    }
  
    return elements;
  }

  /**
   * @description Combines all the elements of a multidimensional array into a single flat array
   * @param {object} value to combine
   * @returns {Array<object>} new array
   */
  public flattenArray(values: object): Array<object> {
    return Object.values(values).reduce((result: Array<object>, current: Array<object>) => result.concat(current), []);
  }

  /**
   * @description Converts the value specified in the parameter to its boolean equivalent.
   * @param {boolean | number | string} value - To convert.
   * @returns {boolean} New value.
   */
  public toBoolean(value: boolean | number | string): boolean {
    if (typeof value === VariableType.Boolean.valueOf()) {
      return value as boolean;
    }
    if (typeof value === VariableType.Number.valueOf()) {
      return value !== 0;
    }
    if (typeof value === VariableType.String.valueOf()) {
      const lowerCaseValue = (value as string).toLowerCase();

      return lowerCaseValue === 'true' || lowerCaseValue === '1';
    }

    return false;
  }

  /**
   * @description Get the sum from data source with the specific property name.
   * @param {Array<object>} values - Data source to find the property.
   * @param {string} propertyName - Property name type number to sum.
   * @returns {boolean} New value.
   */
  public sumFromArray(values: Array<object>, propertyName: string): number {
    return sumBy(values, (item) => { return item[propertyName] ?? 0; });
  }

  /**
   * @description Validates if provided string is affirmative value.
   * @param {string} value - String to validate.
   * @returns {boolean} True if is affirmative value.
   */
  public getAffirmativeValue(value: string): boolean {
    return AppConstants.AFFIRMATIVE_VALUES.includes((value).toLowerCase());
  }

  /**
   * @description Formats a number with commas as thousands separators.
   * @param value - The number to formatted.
   * @returns {string} Value with  the new format.
   */
  public formatNumberWithCommas(value: number): string {
    const formattedNumber = new Intl.NumberFormat().format(value ?? 0);

    return formattedNumber;
  }

  /**
   * @description Converts provided value from string to int.
   * @param {any} value - Value to convert.
   * @param {number} base - Represents the mathematical numeral system.
   * @returns {number} Value converted to int.
   */
  public convertValueToInt(value: any, base?: number): number {
    if (!this.isString(value)) {
      value?.toString();
    }

    return parseInt(value, base);
  }

  /**
   * @description Converts provided value from string to float.
   * @param {any} value - Value to convert.
   * @returns {number} Value converted to float.
   */
  public convertValueToFloat(value: any): number {
    if (!this.isString(value)) {
      value?.toString();
    }

    return parseFloat(value);
  }

  /**
   * @description Formats the value into a string with commas as thousand separators.
   * @param {number} value - The cost value to format.
   * @param {boolean} addZeroDecimals - Indicates whether to add additional decimal zeros. Default is false.
   * @returns {string} - The formatted cost value as a string.
   */
  public formatNumberWithThousandsSeparator(value: number, addZeroDecimals?: boolean): string {
    let valueString: string = this.fixNumberToDecimals(value)?.toString();

    if (addZeroDecimals && !valueString.includes(AppConstants.DOT_CHAR)) {
      valueString += AppConstants.DOT_CHAR + AppConstants.ZERO_STRING + AppConstants.ZERO_STRING;
    }
    const minLenght: number = 2;
    const hasOneDecimal: boolean = valueString.includes(AppConstants.DOT_CHAR) && !valueString.includes(',', valueString.indexOf(AppConstants.DOT_CHAR) + 1);

    if (hasOneDecimal) {
      const parts = valueString.split(AppConstants.DOT_CHAR);

      if (parts.length === minLenght) {
        const decimals: string = parts[1].length === 1 ? parts[1] + AppConstants.ZERO_STRING : parts[1];
        const newValueString: string = parts[0].replace(GenericRegexp.THOUSANDS_SEPARATOR, ',') + AppConstants.DOT_CHAR + decimals;

        return newValueString;
      }
    }

    return this.formatNumberWithCommas(this.fixNumberToDecimals(value));
  }

  /**
   * @description Fixes a number to specific decimals, by default is two decimals.
   * @param {number} value - Current number to fix to decimals.
   * @param {number} decimals - The number of decimals to fix.
   * @returns {number} Number fixed to specific decimals.
   */
  public fixNumberToDecimals(value: number, decimals?: number): number {
    const defaultDecimals: number = 2;

    if (!decimals) {
      decimals = defaultDecimals;
    }

    return parseFloat(value.toFixed(decimals));
  }

  /**
   * @description Adds pad start for provided number to return it as string with max digits and character specified.
   * @param {number} number - Number to padd.
   * @param {number} maxDigits - Max length characters from string result.
   * @param {string} character - Character to pad at start for number provided.
   * @returns {string} - Number provided as string with length specified padded with character provided.
   * Example (provide a 2 result as 0002, if number provided is 2 and next params are 4 and '0'.
   */
  public padNumberToSpecificDigits(number: number, maxDigits: number, character: string): string {
    return number.toString().padStart(maxDigits, character);
  }

  /**
   * @description Receives numeric values to format it as time value.
   * @param {number} hours - Value to concat in hours section.
   * @param {number} minutes - Value to concat in minutes section.
   * @returns {string} - Numeric value as string. Example, provide 9 and 1 returns 09:01.
   */
  public formatNumbersAsTime(hours: number, minutes: number): string {
    return this.padNumberToSpecificDigits(hours, AppConstants.MAX_TIME_DIGITS, AppConstants.ZERO_STRING) +
      AppConstants.HOUR_AND_MINUTES_SEPARATOR +
      this.padNumberToSpecificDigits(minutes, AppConstants.MAX_TIME_DIGITS, AppConstants.ZERO_STRING);
  }
}
