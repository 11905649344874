<mat-grid-list cols="1" rowHeight="1:.10">
  <mat-grid-tile>
    <span class="title">
      <p>{{ data.title }}</p>
    </span>
  </mat-grid-tile>
</mat-grid-list>

<app-information-band
  *ngIf="data.shouldDisplayInformationBand"
  class="band-class"
  [iconName]="data.icon"
  [shouldApplyOpacityToMainText]=true
  [mainText]="data.resume">
</app-information-band>

<mat-dialog-content class="mat-dialog-section-class">
  <form [formGroup]="formGroup">
    <mat-grid-list cols="2" rowHeight="1:.25">
      <mat-grid-tile>
        <mat-form-field>
          <input
            class="input--pointer"
            matInput
            [matDatepicker]="firstDate"
            placeholder="{{ data.firstDateSelectorLabel }}"
            readonly
            id="dtds-first-date-selector"
            formControlName="firsDateSelector"
            [max]="currentDate"
            (click)="firstDate.open()">
            <mat-error>
              {{ data.firstDateSelectorLabel }}
            </mat-error>
          <mat-datepicker-toggle matSuffix [for]="firstDate"></mat-datepicker-toggle>
          <mat-datepicker #firstDate></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="fix-height">
          <input
            id="dtds-first-time-selector"
            formControlName="firsTimeSelector"
            (change)="validateDateTime()"
            matInput
            type="time"
            placeholder="{{ data.firstTimeSelectorLabel }}">
            <mat-error>
              {{ 'vehicleAccessControlLabels.invalidDate' | translate }}
            </mat-error>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</mat-dialog-content>

<div class="flex-class justify-content-at-right">
  <div class="container-50-percent">
    <button 
      id="dtds-secondary-button"
      class="secondary-button cancel-button-class"
      mat-button
      mat-dialog-close>
      {{ data.secondaryButtonLabel }}
    </button>
    <button
      id="dtds-primary-button"
      [disabled]="formGroup.invalid"
      class="primary-button continue-button-class"
      (click)="onSave()"
      mat-button>
      {{ data.primaryButtonLabel }}
    </button>
  </div>
</div>
